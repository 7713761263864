import { getInstance } from '@/auth/authWrapper'
import cfg from '../../config'
export async function getPendingConnectors() {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/admin/connector/getPending`
    const options = {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url, options)
    return res.json()
}
export async function getAllYcCompanies() {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/admin/yClients/getAllCompanies`
    const options = {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url, options)
    return res.json()
}
export async function getAllAltegioCompanies() {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/admin/altegio/getAllCompanies`
    const options = {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url, options)
    return res.json()
}
export async function setYcConnectorCompanyId({ projectId, connectorId, companyIds }) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/admin/connector/yClients/setCompanyId`
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ projectId, connectorId, companyIds }),
    }
    const res = await fetch(url, options)
    return res
}
export async function getOwnerReport({ projectId, dateLimits }) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/admin/getOwnerReport`
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ projectId, dateLimits }),
    }
    const res = await fetch(url, options)
    return res.json()
}
export async function getCredential({ connectorId, name }) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/admin/credential?connectorId=${connectorId}&name=${name}`
    const options = {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url, options)
    return res.json()
}
export async function getConnector(connectorId) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/admin/connector?connectorId=${connectorId}`
    const options = {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url, options)
    return res.json()
}
