<template>
    <div class="content-wrapper">
        <Breadcrumbs :items="breadcrumbs"></Breadcrumbs>
        <v-row justify="center" align="center" v-show="loading">
            <v-progress-circular indeterminate color="main"> </v-progress-circular>
        </v-row>
        <div class="content" v-show="!loading">
            <v-data-table
                :headers="headers"
                :items="pendingConnectors"
                :items-per-page="-1"
                hide-default-footer
                class="elevation-0"
                @click:row="resolveConnector"
            >
                >
                <template v-slot:[`item.createdAt`]="{ item }">
                    {{ new Date(item.createdAt).toLocaleString() }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-btn icon @click.stop="deletePendingConnector(item)">
                        <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'
import Select from '@root/src/components/inputs/Select.vue'
import { useAdminStore } from '../../../store/stores/admin'
import { useConfirmStore } from '../../../store/stores/confirm'
import { mapStores } from 'pinia'

export default {
    name: 'AdminPendingConnectors',
    components: { Breadcrumbs, Select },
    data: () => ({
        loading: true,
        dialog: false,
        breadcrumbs: [
            {
                text: 'ГЛАВНАЯ',
                to: '/admin',
            },
        ],
        headers: [
            { text: 'user name', value: 'userName', sortable: false },
            { text: 'user email', value: 'userEmail', sortable: false },
            { text: 'project name', value: 'projectName', sortable: false },
            { text: 'connector type', value: 'connectorType', sortable: false },
            { text: 'connector id', value: 'connectorId', sortable: false },
            { text: 'date', value: 'createdAt' },
            { text: '', value: 'actions' },
        ],
    }),
    computed: {
        ...mapStores(useAdminStore, useConfirmStore),
        pendingConnectors() {
            return this.adminStore.pendingConnectors
        },
    },
    methods: {
        resolveConnector(pendingConnector) {
            this.$router.push(`/admin/pendingConnectors/resolve/${pendingConnector.id}`)
        },
        deletePendingConnector(pendingConnector) {
            this.confirmStore.confirm({
                applyText: 'ОТПРАВИТЬ',
                text: `Вы точно хотите удалить коннектор ${pendingConnector.connectorId}?`,
                onConfirm: async () => {
                    this.adminStore.deletePendingConnector(pendingConnector.id)
                },
            })
        },
    },
    async created() {
        await this.adminStore.fetchPendingConnectors()
        this.loading = false
    },
}
</script>
<style lang="sass" scoped>
::v-deep .v-data-table tr
    cursor: pointer
</style>
