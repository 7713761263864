import { getMaxDate, getMinDate } from '@/utils'
import moment, { Moment } from 'moment'
import * as _ from 'lodash'
import { ref, computed, toRaw, toRefs } from 'vue'
interface useDateMenuParams {
    dateFormat: string
    defaultStartDate: Moment
    defaultEndDate: Moment
}

export const useDateMenu = ({ dateFormat, defaultStartDate, defaultEndDate }: useDateMenuParams) => {
    const VUETIFY_DATE_FORMAT = 'YYYY-MM-DD'
    const opened = ref(false)
    const dates = ref(
        _.compact(_.map([defaultStartDate, defaultEndDate], o => o.format(VUETIFY_DATE_FORMAT)))
    )
    const close = () => {
        opened.value = false
    }
    const open = () => {
        opened.value = true
    }
    const startDate = computed(() => getMinDate(dates.value).format(VUETIFY_DATE_FORMAT))
    const endDate = computed(() => getMaxDate(dates.value).format(VUETIFY_DATE_FORMAT))
    const text = computed(() => {
        const orderedDates = [getMinDate(dates.value), getMaxDate(dates.value)]
        const formatedDates = orderedDates.map(date => moment(date).format(dateFormat))
        return formatedDates.join(' — ')
    })
    return toRaw({ opened, text, close, open, dates, startDate, endDate })
}
