<template>
    <div
        v-if="(isProjectPath && !dataLoading) || visible"
        :class="{
            inlineBlock: inSnackbar,
        }"
    >
        <v-row>
            <span
                v-if="
                    project &&
                    project.lastUpdate &&
                    GCB2Inited &&
                    !hideTimestamp &&
                    !inSnackbar &&
                    showUpdated
                "
                class="date-text mr-4"
            >
                {{ `Обновлено: ${new Date(project.lastUpdate).toLocaleString()} ` }}
            </span>
            <v-tooltip bottom v-if="spinnerStatus !== null">
                <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" v-if="checkRole(project.id, 'project:communicationUpdate')">
                        <div
                            class="updateButton custom-highlight"
                            @click="updateProject"
                            id="reloadBtn"
                            :class="{ white: inSnackbar, isError: color === 'error' }"
                        >
                            <div class="updateButton__before">
                                <v-btn v-if="spinnerStatus === 'idleError' || (color === 'error' && spinnerStatus !== 'loading')" icon small
                                    ><v-icon size="18px" color="error">mdi-sync</v-icon></v-btn
                                >

                                <v-btn v-else-if="spinnerStatus === 'idle'" icon small
                                    ><v-icon size="18px" color="main">mdi-sync</v-icon></v-btn
                                >
                            </div>

                            <v-progress-circular
                                :style="{
                                    margin: '0 auto;',
                                }"
                                indeterminate
                                :color="color === 'error' ? 'error' : 'main'"
                                size="16"
                                width="2"
                                class="progressBar"
                                v-if="spinnerStatus === 'loading'"
                            ></v-progress-circular>

                            <v-btn v-if="spinnerStatus === 'success'" icon small
                                ><v-icon size="18px" color="success">mdi-check-circle</v-icon></v-btn
                            >
                            <v-btn size="18px" v-if="spinnerStatus === 'error'" icon small
                                ><v-icon color="error">mdi-alert-circle</v-icon></v-btn
                            >

                            <div class="updateButton__text">ОБНОВЛЕНИЕ РАСЧЕТОВ</div>
                        </div>
                    </div>
                </template>
                <span class="white--text">{{ tooltipText }}</span>
            </v-tooltip>
        </v-row>
    </div>
</template>

<script>
import fb from '@/plugins/firebase'
import { communicationUpdate } from '@root/src/api/chisai/GCB2'
import { checkRole } from '@/helpers/roles'
const spinnerStatuses = {
    idle: 'idle',
    idleError: 'idleError',
    loading: 'loading',
    success: 'success',
    error: 'error',
}
const POLLING_INTERVAL = 10 // в секундах
const CHANGE_SPIINER_STATUS_TIMEOUT = 3 // в секундах
export default {
    name: 'Loading',
    components: {},
    data: () => ({
        dataLoading: true,
        loadingRef: null,
        prevSpinnerStatus: null,
        spinnerStatus: null,
        loadingStatus: false,
        loadingValue: 0,
        loadingInterval: null,
    }),
    props: {
        isProjectPath: {
            type: Boolean,
            default: false,
        },
        visible: {
            type: Boolean,
            default: false,
        },
        inSnackbar: {
            type: Boolean,
            default: false,
        },
        showUpdated: {
            type: Boolean,
            default: true,
        },
        color: {
            type: String,
            default: 'main',
        },
    },
    computed: {
        project() {
            const projectId = this.$router.currentRoute.params.id
            return this.$store.getters.projectById(projectId)
        },
        excludedStages() {
            return this.project?.settings?.loading?.updateExcludedStages
        },
        startLoadingTime() {
            return this.$store.state.project.startLoadingTime
        },
        tooltipText() {
            let text = null
            //выбираем текст тултипа в зависимости от статуса спиннера
            switch (this.spinnerStatus) {
                case spinnerStatuses.idle:
                    text = 'Обновить'
                    break
                case spinnerStatuses.loading:
                    text = 'Загрузка...'
                    break
                case spinnerStatuses.success:
                    text = 'Процесс обновления завершен!'
                    break
                case spinnerStatuses.error:
                    text = 'Ошибка!'
                    break
                case spinnerStatuses.idleError:
                    text = 'Последняя попытка обновления произошла с ошибкой. \nОбновить еще раз?'
                    break
                default:
                    break
            }
            return text
        },
        hideLoading() {
            return this.project?.settings?.loading?.hideLoading
        },
        hideTimestamp() {
            return this.project?.settings?.loading?.hideTimestamp
        },
        GCB2Inited() {
            return this.project?.initChisai?.GCB2
        },
    },
    watch: {
        //Следим за изменением страницы проекта
        '$route.params.id': {
            immediate: true,
            async handler(oldVal, newVal) {
                this.prevSpinnerStatus = null
                this.spinnerStatus = null
                this.errors = null
                //если проект изменился, ощищаем listener
                if (this.loadingRef) {
                    this.loadingRef.off()
                }
                if (this.timestampsRef) {
                    this.timestampsRef.off()
                }
                const projectId = newVal ? newVal : oldVal
                if (projectId) {
                    //подписываемся на изменение загрузки
                    this.loadingRef = fb.database().ref(`/project/${projectId}/loadingCommunicationUpdate`)
                    this.timestampsRef = fb.database().ref(`/project/${projectId}/timestamps`)
                    this.timestampsRef.on('value', (data) => {
                        const timestamps = data.val()
                        this.$store.commit('updateProject', Object.assign({}, this.project, { timestamps }))
                    })
                    this.loadingRef.on('value', (data) => {
                        this.dataLoading = true
                        const loading = data.val() || { status: false, isError: false }
                        this.setSpinnerStatusAndLastLoadingTime(loading)
                        this.setErrors(loading)
                        this.dataLoading = false
                    })
                }
            },
        },
        spinnerStatus() {
            switch (this.spinnerStatus) {
                case spinnerStatuses.idle:
                    this.$store.commit('setCommunicationUpdate', false)
                    break
                case spinnerStatuses.loading:
                    this.$store.commit('setCommunicationUpdate', true)
                    break
                case spinnerStatuses.success:
                    this.$store.commit('setCommunicationUpdate', false)
                    break
                case spinnerStatuses.error:
                    this.$store.commit('setCommunicationUpdate', false)
                    break
                case spinnerStatuses.idleError:
                    this.$store.commit('setCommunicationUpdate', false)
                    break
                default:
                    this.$store.commit('setCommunicationUpdate', false)
                    break
            }
        }
    },
    methods: {
        checkRole,
        setSpinnerStatusAndLastLoadingTime(loading) {
            //Если у всех коннекторов не завершено подключение, не показываем значек загрузки
            if (!this.project || this.project.connectors.every((connector) => connector.isDraft)) {
                return
            }
            if (!loading) {
                return
            }
            //Не показывать значек загрузки, если в настройках это указано
            if (this.hideLoading) {
                this.spinnerStatus = null
                return
            }
            if (this.prevSpinnerStatus === spinnerStatuses.loading && loading.status === false) {
                //выбираем какой должен быть значек загрузки
                this.spinnerStatus = spinnerStatuses.success
                this.prevSpinnerStatus = spinnerStatuses.success
                setTimeout(() => {
                    this.spinnerStatus = spinnerStatuses.idle
                    this.prevSpinnerStatus = spinnerStatuses.idle
                }, CHANGE_SPIINER_STATUS_TIMEOUT * 1000)
                const projectId = this.$router.currentRoute.params.id
                //Если проект загрузился, грузим обновленный проект чтобы получить дату последней загрузки
                this.$store.dispatch('project/fetchProject', projectId)
            } else if (loading.status === false) {
                this.spinnerStatus = spinnerStatuses.idle
                this.prevSpinnerStatus = spinnerStatuses.idle
            } else if (loading.status === true) {
                this.spinnerStatus = spinnerStatuses.loading
                this.prevSpinnerStatus = spinnerStatuses.loading
            }
        },
        setErrors(loading) {
            if (!loading) {
                return
            }
            this.errors = loading.errors
                ? loading.errors.reduce((acc, cur) => acc + cur.error + '\n', '\n')
                : null
        },
        finishTour() {
            if (this.$tours.btnHighlight) {
                console.log(this.$tours)
                // @ts-ignore
                this.$tours.btnHighlight?.stop()
            }
        },
        async updateProject() {
            this.finishTour()
            this.spinnerStatus = spinnerStatuses.loading
            await communicationUpdate({ projectId: this.project.id })
        },
    },
}
</script>
<style lang="scss" scoped>
.date-text {
    font-size: 13px;
    color: #9da5af;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hidden {
    visibility: hidden;
}

.progressBar {
    margin: 0 5px;
    margin-left: 7px;
}

.inlineBlock {
    display: inline-block;
    margin-left: 12px;
}

.updateButton {
    display: flex;
    position: relative;
    align-items: center;
    height: 28px;
    padding: 8px;
    padding-left: 0px;
    font-size: 10px;
    border: 1px solid #378eff;
    border-radius: 4px;
    cursor: pointer;

    &.isError {
        border: 1px solid #e20029;

        .updateButton__text {
            color: #e20029 !important;
        }
    }

    &__text {
        color: #378eff;
    }

    

    

    &:hover {
        background: #378eff29;
    }

    &:active {
        background: #378eff52;
    }

    &.inSnackbar {
        border: 1px solid #fff;
        display: inline-block;
    }
}
</style>
