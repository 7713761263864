import { ref, shallowRef, Ref, onMounted, onUnmounted } from 'vue'

export interface UseDropZoneReturn {
    files: Ref<File[] | null>
    isOverDropZone: Ref<boolean>
}

export interface UseDropZoneOptions {
    onDrop?: (files: File[] | null, event: Event) => void
    onEnter?: (files: File[] | null, event: Event) => void
    onLeave?: (files: File[] | null, event: Event) => void
    onOver?: (files: File[] | null, event: Event) => void
}

export function useDropZone(
    target: Ref<HTMLElement>,
    options: UseDropZoneOptions | UseDropZoneOptions['onDrop'] = {}
): UseDropZoneReturn {
    const abortController = new AbortController()
    const isOverDropZone = ref(false)
    const files = shallowRef<File[] | null>(null)
    let counter = 0

    const _options = typeof options === 'function' ? { onDrop: options } : options
    const getFiles = (event: Event) => {
        const list = Array.from((event as DragEvent).dataTransfer?.files ?? [])
        return (files.value = list.length === 0 ? null : list)
    }

    onMounted(() => {
        target.value.addEventListener(
            'dragenter',
            (event: Event) => {
                event.preventDefault()
                counter += 1
                isOverDropZone.value = true
                _options.onEnter?.(getFiles(event), event)
            },
            { signal: abortController.signal } as AddEventListenerOptions
        )
        target.value.addEventListener(
            'dragover',
            (event: Event) => {
                event.preventDefault()
                _options.onOver?.(getFiles(event), event)
            },
            { signal: abortController.signal } as AddEventListenerOptions
        )
        target.value.addEventListener(
            'dragleave',
            (event: Event) => {
                event.preventDefault()
                counter -= 1
                if (counter === 0) isOverDropZone.value = false
                _options.onLeave?.(getFiles(event), event)
            },
            { signal: abortController.signal } as AddEventListenerOptions
        )
        target.value.addEventListener(
            'drop',
            (event: Event) => {
                event.preventDefault()
                counter = 0
                isOverDropZone.value = false
                _options.onDrop?.(getFiles(event), event)
            },
            { signal: abortController.signal } as AddEventListenerOptions
        )
    })
    onUnmounted(() => {
        abortController.abort()
    })
    return {
        files,
        isOverDropZone,
    }
}
