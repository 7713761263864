
import { PropType, computed, defineComponent, onBeforeMount, onMounted, ref } from 'vue'
import * as _ from 'lodash'
import useRouter from '../../../../../hooks/useRouter'
import TimeInput from '../../../../inputs/TimeInput.vue'
import CustomActivatorSelect from '../../../../inputs/CustomActivatorSelect.vue'
import BaseInputOutlined from '../../../../inputs/BaseInputOutlined.vue'
import { minutesToMs } from '../../../../../utils'
export default defineComponent({
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    components: { TimeInput, CustomActivatorSelect, BaseInputOutlined },
    setup(props, { emit }) {
        const { route } = useRouter()
        const trigger = computed(() => route.value.query.id)
        const delayList = [
            { id: 0, name: 'Без задержки' },
            { id: minutesToMs(10), name: 'Через 10 минут' },
            { id: minutesToMs(15), name: 'Через 15 минут' },
            { id: minutesToMs(30), name: 'Через 30 минут' },
            { id: minutesToMs(60), name: 'Через час' },
            { id: minutesToMs(90), name: 'Через 1.5 часа' },
            { id: minutesToMs(60 * 2), name: 'Через 2 часа' },
            { id: minutesToMs(60 * 3), name: 'Через 3 часа' },
            { id: minutesToMs(60 * 6), name: 'Через 6 часов' },
            { id: minutesToMs(60 * 12), name: 'Через 12 часов' },
            { id: minutesToMs(60 * 24), name: 'Через 24 часа' },
        ]
        const sendTime = computed({
            get: () => props.value.staticSendDate?.join(':') || '',
            set: v => {
                let newValue: number[] | string[] = v.split(':')
                if (!newValue[0]) newValue = []
                emit('input', Object.assign({}, props.value, { staticSendDate: newValue }))
            },
        })
        const downLimit = computed({
            get: () => props.value.downLimit?.join(':') || '',
            set: v => {
                let newValue: number[] | string[] = v.split(':')
                if (!newValue[0]) newValue = []
                emit('input', Object.assign({}, props.value, { downLimit: newValue }))
            },
        })
        const upLimit = computed({
            get: () => props.value.upLimit?.join(':') || '',
            set: v => {
                let newValue: number[] | string[] = v.split(':')
                if (!newValue[0]) newValue = []
                emit('input', Object.assign({}, props.value, { upLimit: newValue }))
            },
        })
        const clearTimeLimits = () => {
            emit('input', Object.assign({}, props.value, { downLimit: [], upLimit: [] }))
        }
        return {
            trigger,
            sendTime,
            delayList,
            downLimit,
            upLimit,
            clearTimeLimits,
        }
    },
})
