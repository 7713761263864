import config from '@root/config'
import { getAudience } from '@root/src/api/chisai/GCB2'
import { Audience } from '@/types/GCB2'
import { uniq } from 'lodash'

import * as t from '../actionTypes'
//_SHARED - projectId для общих аудиторий
const projectIdIsMatch = (projectId: string, elProjectId: string) =>
    [projectId, '_SHARED'].includes(elProjectId)
export default {
    state: {
        isAudienceEditDialog: false,
        audienceData: [],
    },
    mutations: {
        [t.ADD_AUDIENCE_DATA](state: any, { data, projectId }: { data: Audience[]; projectId: string }) {
            const projectData = data.filter((el: Audience) => el.projectId === projectId)
            const sharedData = data.filter((el: Audience) => el.projectId === '_SHARED')
            if (!state.audienceData.some((el: Audience) => el.projectId === projectId)) {
                state.audienceData = state.audienceData.concat(projectData)
            }
            if (!state.audienceData.some((el: Audience) => el.projectId === '_SHARED')) {
                state.audienceData = state.audienceData.concat(sharedData)
            }
        },
        [t.UPDATE_ONE_AUDIENCE](state: any, audience: Audience) {
            state.audienceData = [
                ...state.audienceData.map((item: Audience) =>
                    item.id !== audience.id ? item : { ...item, ...audience }
                ),
            ]
        },
        [t.ADD_ONE_AUDIENCE](state: any, audience: Audience[]) {
            state.audienceData.push(audience)
        },
        [t.SET_IS_AUDIENCE_EDIT_DIALOG](state: any, value: boolean) {
            state.isAudienceEditDialog = value
        },
        [t.DELETE_ONE_AUDIENCE](state: any, id: number) {
            const indexToDelete = state.audienceData.findIndex((el: Audience) => el.id === id)
            if (indexToDelete > -1) {
                state.audienceData.splice(indexToDelete, 1)
            }
        },
        /*         [t.DELETE_ONE_FILTER_FROM_AUDIENCE_SETTINGS](state: any, { audienceId, filterIndex }: any) {
            const indexToDelete = state.audienceData.findIndex((el: Audience) => el.id === id)
            if (indexToDelete > -1) {
                state.audienceData.splice(indexToDelete, 1)
            }
        }, */
    },
    actions: {
        async [t.FETCH_AND_ADD_AUDIENCE_DATA]({ commit }: any, projectId: string) {
            const data = await getAudience(projectId)
            commit(t.ADD_AUDIENCE_DATA, { data, projectId })
        },
    },

    getters: {
        [t.GET_AUDIENCE_DATA]: (state: any) => (projectId: string) => {
            return state.audienceData.filter((el: Audience) => projectIdIsMatch(projectId, el.projectId))
        },
        [t.GET_AUDIENCE_GROUPS]: (state: any) => (projectId: string) => {
            return uniq(
                (state.audienceData as Audience[])
                    .filter((el: Audience) => projectIdIsMatch(projectId, el.projectId))
                    .map(el => el.group)
            )
        },
        [t.GET_ONE_AUDIENCE]: (state: any) => (id: number) => {
            return state.audienceData.find((el: Audience) => el.id === id)
        },
    },
}
