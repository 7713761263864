import { render, staticRenderFns } from "./ProfSalonResolver.vue?vue&type=template&id=111a6085&scoped=true"
import script from "./ProfSalonResolver.vue?vue&type=script&lang=ts"
export * from "./ProfSalonResolver.vue?vue&type=script&lang=ts"
import style0 from "./ProfSalonResolver.vue?vue&type=style&index=0&id=111a6085&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "111a6085",
  null
  
)

export default component.exports