import cfg from '../../config.js'
import { changeUserName } from '../api/user.js'
import { getInstance } from '../auth/authWrapper'
import { genPartOfId, getError } from '../utils.js'
export default {
    state: {
        isCreateProjectDialog: false,
        isAddUserDialog: false,
        ///
        isLoading: false,
        startLoadingTime: null,
        ///
        isChangeConnectorSettingsDialog: false,
        currentProjectView: 'list',
        connectorsSelected: [],
        currentAddingConnector: null,
        connectorData: JSON.parse(sessionStorage.getItem('connectorData')) || null,
        loggedAddingConnector: null,
        addConectorCredentials: {},
        addConectorSettings: {},
        selectedProjects: [],
        isProjectsLoading: true,
        currentProjectIdToRename: null,
        currentUserIdToRename: null,
        isRenameProjectDialog: false,
        isRenameUserDialog: false,
        currentProjectIdToChangeImage: null,
        isChangeProjectImageDialog: null
    },
    mutations: {
        setIsProjectsLoading(state, value) {
            state.isProjectsLoading = value
        },
        setStartLoadingTime(state, value) {
            state.startLoadingTime = value
        },
        setCurrentProjectIdToRename(state, value) {
            state.currentProjectIdToRename = value
        },
        setCurrentProjectIdToChangeImage(state, value) {
            state.currentProjectIdToChangeImage = value
        },
        setIsChangeProjectImageDialog(state, value) {
            state.isChangeProjectImageDialog = value
        },
        setIsRenameProjectDialog(state, value) {
            state.isRenameProjectDialog = value
        },
        setIsRenameUserDialog(state, value) {
            state.isRenameUserDialog = value
        },
        setCurrentUserIdToRename(state, value) {
            state.currentUserIdToRename = value
        },
        setCurrentProjectView(state, projectView) {
            state.currentProjectView = projectView
            localStorage.setItem('currentProjectView', projectView)
        },
        setAddConectorCredentials(state, credentials) {
            state.addConectorCredentials = credentials
            sessionStorage.setItem('currentAddingConnectorCredentials', JSON.stringify(credentials))
        },
        setAddConectorSettings(state, settingsObj) {
            state.addConectorSettings = settingsObj
            sessionStorage.setItem('currentAddingConnectorSettings', JSON.stringify(settingsObj))
        },
        setLoggedAddingConnector(state, connector) {
            state.loggedAddingConnector = connector
            sessionStorage.setItem('loggedAddingConnector', JSON.stringify(connector))
        },
        setConnectorData(state, value) {
            state.connectorData = value
            sessionStorage.setItem('connectorData', JSON.stringify(value))
        },
        updateCurrentAddingConnector(state, value) {
            state.currentAddingConnector = value
            sessionStorage.setItem('currentAddingConnector', JSON.stringify(value))
        },
        updateIsCreateProjectDialog(state, value) {
            state.isCreateProjectDialog = value
        },
        updateIsAddUserDialog(state, value) {
            state.isAddUserDialog = value
        },
        updateIsLoading(state, value) {
            state.isLoading = value
        },
        updateSelectedProjects(state, value) {
            state.selectedProjects = value
        },
        updateIsChangeConnectorSettingsDialog(state, value) {
            state.isChangeConnectorSettingsDialog = value
        },
    },
    actions: {
        callRenameProjectDialog({ commit }, projectId) {
            commit('setCurrentProjectIdToRename', projectId)
            commit('setIsRenameProjectDialog', true)
        },
        async setProjects({ commit }) {
            commit('setIsProjectsLoading', true)
            const auth = getInstance()
            const token = await auth.getTokenSilently()
            const url = `${cfg.apiEndpoint}/projects/getUserProjects`
            const options = {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }

            const projects = await (await fetch(url, options)).json()
            commit('addProjects', projects, { root: true })
            commit('setIsProjectsLoading', false)
        },
        async fetchProject({ commit }, id) { 
            const auth = getInstance()
            const token = await auth.getTokenSilently()
            const url = `${cfg.apiEndpoint}/projects?projectId=${id}`
            const options = {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }
            const project = await (await fetch(url, options)).json()
            commit('setProject', project, { root: true })
        },
        async createProject({ commit }, {projectName, isOnBoardingActive, billingEnabledStatus}) {
            commit('startLoading', null, { root: true })
            const auth = getInstance()
            const billingEnabled = billingEnabledStatus
            const token = await auth.getTokenSilently()
            const url = `${cfg.apiEndpoint}/projects/createProject`
            const options = {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ projectName, isOnBoardingActive, billingEnabled }),
            }
            const project = await (await fetch(url, options)).json()
            
            commit('setProject', project, { root: true })
            commit('endLoading', null, { root: true })
            return project
        },
        async deleteProject({ commit }, projectId) {
            commit('startLoading', null, { root: true })
            const auth = getInstance()
            const token = await auth.getTokenSilently()
            const url = `${cfg.apiEndpoint}/projects/deleteProject`
            const options = {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ projectId }),
            }
            await fetch(url, options)
            commit('removeProject', projectId, { root: true })
            commit('endLoading', null, { root: true })
        },
        async addConnector({ commit }, connector) {
            commit('startLoading', null, { root: true })
            const auth = getInstance()
            const token = await auth.getTokenSilently()
            const url = `${cfg.apiEndpoint}/projects/addConnector`
            connector.isDraft = true
            const options = {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ connector }),
            }
            const res = await fetch(url, options)
            if (res.status === 200) {
                commit('addConnector', connector, { root: true })
                commit('setLoggedAddingConnector', connector)
                commit('endLoading', null, { root: true })
                return { error: null }
            } else if (res.status === 400) {
                commit('endLoading', null, { root: true })
                return { error: res.json() }
            } else {
                commit('endLoading', null, { root: true })
                return { error: 'internal server error' }
            }
        },
        async deleteConnector({ commit }, connectorId) {
            commit('startLoading', null, { root: true })
            const auth = getInstance()
            const token = await auth.getTokenSilently()
            const url = `${cfg.apiEndpoint}/projects/deleteConnector`
            const options = {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ connectorId }),
            }
            const res = await fetch(url, options)
            if (res.status === 200) {
                commit('removeConnector', connectorId, { root: true })
            }
            commit('endLoading', null, { root: true })
        },
        createConnector({ state }, { projectId, connectorType, domain, settings }) {
            const id = `${projectId}_${connectorType}_${genPartOfId()}`
            return {
                connectorId: id,
                projectId,
                domain,
                connectorType,
                ...settings,
            }
        },
        async assignPermission({ commit }, payload) {
            commit('startLoading', null, { root: true })
            const auth = getInstance()
            const token = await auth.getTokenSilently()
            const url = `${cfg.apiEndpoint}/user/assignPermission`
            const options = {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            }
            const res = await fetch(url, options)
            commit('endLoading', null, { root: true })
        },
        async reAssignPermission({ commit }, { projectId, userIdtoChange, roleId }) {
            commit('startLoading', null, { root: true })
            const auth = getInstance()
            const token = await auth.getTokenSilently()
            const url = `${cfg.apiEndpoint}/user/reAssignPermission`
            const options = {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ projectId, userIdtoChange, roleId }),
            }
            const res = await fetch(url, options)
            if (res.status === 200) {
                commit('changeUserRole', { projectId, userId: userIdtoChange, roleId }, { root: true })
            } else {
                const error = await getError(res)
                commit('setError', error, { root: true })
            }
            commit('endLoading', null, { root: true })
        },
        async unAssignPermission({ commit }, { projectId, userId }) {
            commit('startLoading', null, { root: true })
            const auth = getInstance()
            const token = await auth.getTokenSilently()
            const url = `${cfg.apiEndpoint}/user/unAssignPermission`
            const options = {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ projectId, userId }),
            }
            const res = await fetch(url, options)
            commit('endLoading', null, { root: true })
        },
        async updateConnectorSettings({ commit }, { connectorId, settings }) {
            commit('startLoading', null, { root: true })
            const auth = getInstance()
            const token = await auth.getTokenSilently()
            const url = `${cfg.apiEndpoint}/projects/updateConnectorSettings`
            const options = {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ connectorId, settings }),
            }
            const connector = await (await fetch(url, options)).json()
            commit('updateConnector', connector, { root: true })
            commit('endLoading', null, { root: true })
        },
        async updateProjectSettings({ commit }, { projectId, settings }) {
            commit('startLoading', null, { root: true })
            const auth = getInstance()
            const token = await auth.getTokenSilently()
            const url = `${cfg.apiEndpoint}/projects/updateProjectSettings`
            const options = {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ projectId, settings }),
            }
            const project = await (await fetch(url, options)).json()
            commit('updateProject', project, { root: true })
            commit('endLoading', null, { root: true })
        },
        async renameProject({ commit }, { projectId, name }) {
            commit('startLoading', null, { root: true })
            const auth = getInstance()
            const token = await auth.getTokenSilently()
            const url = `${cfg.apiEndpoint}/projects/rename`
            const options = {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ projectId, name }),
            }
            const project = await (await fetch(url, options)).json()
            commit('updateProject', project, { root: true })
            commit('endLoading', null, { root: true })
        },
        async update({ commit, dispatch }, { projectId, excludedStages }) {
            const auth = getInstance()
            const token = await auth.getTokenSilently()
            const url = `${cfg.apiEndpoint}/data/updateDataLayers`
            const options = {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ projectId, excludedStages: excludedStages }),
            }
            const res = await fetch(url, options)
            if (res.status === 200) {
                //await dispatch('setProjects')
            } else {
                const error = await getError(res)
                if (error.code !== 409) {
                    commit('setError', error, { root: true })
                }
            }
        },
        async updateServiceProvided({ commit }, projectId) {
            console.log('UPDATE')
            const auth = getInstance()
            const token = await auth.getTokenSilently()
            const url = `${cfg.apiEndpoint}/data/updateServiceProvided`
            const options = {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ projectId }),
            }
            const res = fetch(url, options)
        },
        async getLoading({ commit }, projectId) {
            const auth = getInstance()
            const token = await auth.getTokenSilently()
            const url = `${cfg.apiEndpoint}/projects/getLoading?projectId=${projectId}`
            const options = {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }

            const loading = await (await fetch(url, options)).json()
            return loading
        },
        async getChisaiIsInit({ commit }, { projectId, connectorType }) {
            const auth = getInstance()
            const token = await auth.getTokenSilently()
            const url = `${cfg.apiEndpoint}/projects/getChisaiIsInit?projectId=${projectId}&connectorType=${connectorType}`
            const options = {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }
            try {
                const isInit = await (await fetch(url, options)).json()
                return isInit
            } catch (err) {
                const error = await getError(res)
                commit('setError', error, { root: true })
            }
        },
        async changeProjectImage({ commit }, { projectId, file }) {
            const auth = getInstance()
            const token = await auth.getTokenSilently()
            const url = `${cfg.apiEndpoint}/projects/changeProjectImage`
            let formData = new FormData()
            formData.append('projectId', projectId)
            formData.append('file', file, file.name)
            const options = {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            }
            const updatedProject = await (await fetch(url, options)).json()
            commit('updateProject', updatedProject, { root: true })
        },
        async changeUserName({ commit }, { projectId, userId, name }) {
            commit('changeUserName', { projectId, userId, name }, { root: true })
            const res = await changeUserName(projectId, userId, name)
        },
    },
    getters: {},
}
