<template>
    <div>
        <h1>Виджеты</h1>
        <p>
            В разделе <strong>Виджеты</strong> отображены отчеты, которые относятся к вашему бизнесу,
            собираются из ваших информационных источников (см. раздел <strong>Источники</strong>
            ), а также показывают ключевые показатели, необходимую вам аналитику и работу с клиентами.
        </p>
    </div>
</template>

<script>
//hintId  <dashBoards>
export default {
    name: 'DashBoardsHint',
    computed: {},
    components: {},
    data: () => ({}),
    methods: {},
    mounted() {},
}
</script>

<style lang="sass" scoped>
@import '@/vars.sass'
p
    font-size: 14px
</style>
