import { getInstance } from '@/auth/authWrapper'
import { getError } from '@root/src/utils'
import cfg from '../../../config'
import {
    Activation,
    Message,
    MessageVarBody,
    returnabilityFilters,
    SourceFormatKpiSetting,
    TaskListFilters,
    CommunicationTag,
    ActivationMedia,
} from '@/types/GCB2'
import { ApiError, ApiWrapper } from '@/types/main'
import _, { isNil } from 'lodash'
import { http, apiCall } from '../http'
//Audience
export async function getAudience(projectId: string) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/audience`)
    const params: any = { projectId }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { error }
    }
    const data = await res.json()
    return data
}
export async function createAudience({
    projectId,
    name,
    group,
    settings,
}: {
    projectId: string
    name: string
    group: string
    settings: any
}) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/audience`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({
            projectId,
            name,
            group,
            settings,
        }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { error }
    }
    const data = await res.json()
    return data
}
export async function deleteAudience({ projectId, id }: { projectId: string; id: number }) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/audience`)
    const options = {
        method: 'DELETE',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({
            projectId: projectId,
            id: id,
        }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { error }
    }
    const data = await res.json()
    return data
}
export async function updateAudience({
    id,
    projectId,
    name,
    group,
    settings,
}: {
    id: number
    projectId: string
    name?: string
    group?: string
    settings?: any
}) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/audience`)
    const options = {
        method: 'PUT',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({ id, projectId, name, group, settings }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { error }
    }
    const data = await res.json()
    return data
}
//Messages
export async function getMessages(projectId: string) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/message`)
    const params: any = { projectId }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { error }
    }
    const data = await res.json()
    return data
}
export async function getMessage({ id, projectId }: { id: number; projectId: string }) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/message/${id}`)
    const params: any = { projectId }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { error }
    }
    const data = await res.json()
    return data
}
export async function deleteMessage({
    id,
    projectId,
}: {
    id: number
    projectId: string
}): Promise<ApiWrapper<{ id: number } | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/message`)
    const options = {
        method: 'DELETE',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            projectId,
            id,
        }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return data
}
export async function createMessage({
    projectId,
    name,
    group,
}: {
    projectId: string
    name: string
    group: string
}): Promise<ApiWrapper<Message | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/message`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({
            projectId,
            name,
            group,
        }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function updateMessage({
    projectId,
    name,
    group,
    id,
}: {
    id: number
    projectId: string
    name: string
    group: string
}): Promise<ApiWrapper<Message | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/message`)
    const options = {
        method: 'PUT',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({
            id,
            projectId,
            name,
            group,
        }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function createMessagePart({
    projectId,
    textVariant,
    partNumber,
    messageId,
}: {
    projectId: string
    textVariant: string
    partNumber: number
    messageId: number
}): Promise<ApiWrapper<Message | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/messagePart`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({
            projectId,
            textVariant,
            partNumber,
            messageId,
        }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function updateMessagePart({
    id,
    projectId,
    textVariant,
    partNumber,
}: {
    id: number
    projectId: string
    textVariant: string
    partNumber: number
}): Promise<ApiWrapper<Message | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/messagePart`)
    const options = {
        method: 'PUT',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({
            id,
            projectId,
            textVariant,
            partNumber,
        }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function deleteMessagePart({
    projectId,
    id,
}: {
    projectId: string
    id: number
}): Promise<ApiWrapper<{ id: number } | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/messagePart`)
    const options = {
        method: 'DELETE',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({
            projectId,
            id,
        }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
//Activation
export async function getActivations(projectId: string): Promise<ApiWrapper<Activation[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/activation`)
    const params: any = { projectId }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function getActivationsTags(projectId: string): Promise<ApiWrapper<string[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/activation/tags`)
    const params: any = { projectId }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function createActivation({
    projectId,
    name,
    group,
    description,
    messageId,
    audienceId,
    isRunning,
    extra,
    tags,
    mode,
    testModePhone,
}: {
    projectId: string
    name: string
    group: string
    messageId: number
    description: string
    audienceId: number
    isRunning: boolean
    extra: Object
    tags: string[]
    mode?: string
    testModePhone?: string
}): Promise<ApiWrapper<Activation | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/activation`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({
            projectId,
            name,
            group,
            messageId,
            description,
            audienceId,
            isRunning,
            extra,
            tags,
            mode,
            testModePhone,
        }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function updateActivation({
    id,
    projectId,
    name,
    group,
    description,
    messageId,
    audienceId,
    isRunning,
    extra,
    tags,
    mode,
    testModePhone,
}: {
    id: number
    projectId: string
    description?: string
    name?: string
    group?: string
    messageId?: number
    audienceId?: number
    isRunning?: boolean
    extra?: Object
    tags?: string[]
    mode?: string
    testModePhone?: string
}): Promise<ApiWrapper<Activation | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/activation`)
    const options = {
        method: 'PUT',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({
            id,
            projectId,
            name,
            group,
            messageId,
            description,
            audienceId,
            isRunning,
            extra,
            tags,
            mode,
            testModePhone,
        }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function deleteActivation({
    projectId,
    id,
}: {
    projectId: string
    id: number
}): Promise<ApiWrapper<{ id: number; priority: number; isRunning: boolean; deleted: boolean }[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/activation`)
    const options = {
        method: 'DELETE',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({
            projectId,
            id,
        }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function swapActivationPriority({
    projectId,
    id,
    type,
}: {
    projectId: string
    id: number
    type: 'inc' | 'dec'
}): Promise<ApiWrapper<{ id: number; priority: number }[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/activation/swapPriority`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({
            projectId,
            id,
            type,
        }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function swapActivationOrder({
    projectId,
    id,
    type,
}: {
    projectId: string
    id: number
    type: 'inc' | 'dec'
}): Promise<ApiWrapper<{ id: number; order: number }[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/activation/swapOrder`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({
            projectId,
            id,
            type,
        }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function toogleIsRunning({
    projectId,
    id,
    isRunning,
}: {
    projectId: string
    id: number
    isRunning: boolean
}): Promise<
    ApiWrapper<{ id: number; priority: number; isRunning: boolean; isRunningChangedAt: string }[] | null>
> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/activation/toogleIsRunning`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({
            projectId,
            id,
            isRunning,
        }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}

export async function getAdminReportData({
    projectId,
    filters,
}: {
    projectId: string
    filters: any
}): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/getAdminReport`)

    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({ projectId, filters }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function getSummaryAdminReportManualData(projectId: string): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/adminReport/summaryAdminReportManualData`)
    const params: any = { projectId }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
//Tasklist
export async function getManualCommunicationData({
    projectId,
    filters,
}: {
    projectId: string
    filters: TaskListFilters
}): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/taskList/getData`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({
            projectId,
            filters,
        }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function getNeedToContactWidgetData({
    projectId,
    communicationId,
    activationId,
    clientId,
}: {
    projectId: string
    communicationId: number
    activationId: number
    clientId: string
}): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/taskList/client/needToConcatWidgetData`)
    const params: any = { projectId, communicationId, activationId, clientId }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function getClientVisitsHistoryData({
    projectId,
    clientId,
}: {
    projectId: string
    clientId: string
}): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/taskList/client/visitsHistory`)
    const params: any = { projectId, clientId }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()

    return { data, error: null }
}

export async function getWhatsappBasisSubsystemStatus(projectId): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/taskList/WhatsappBasisSubsystemStatus`)
    const params: any = { projectId }

    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url.toString(), options)

    if (res.status >= 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()

    return { data, error: null }
}

export async function getClientCommentHistory({
    projectId,
    clientId,
}: {
    projectId: string
    clientId: string
}): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/taskList/client/commentHistory`)
    const params: any = { projectId, clientId }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()

    return { data, error: null }
}
export async function taskListAction({
    projectId,
    actionType,
    communicationId,
    statusText,
    actionData,
    socketId,
}: {
    projectId: string
    actionType: string
    communicationId?: string
    statusText?: string
    actionData: any
    socketId?: string
}): Promise<ApiWrapper<any[] | null>> {
    const url = `GCB2/taskList/action`
    const res = apiCall<any>(
        () =>
            http.post(url.toString(), {
                json: { actionType, projectId, communicationId, statusText, data: actionData, socketId },
            }),
        { retryCount: 6, retryInterval: 1500 }
    )
    return res
}
export async function setChangedActivationNotification({
    projectId,
    active,
}: {
    projectId: string
    active: boolean
}): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/activation/setChangedNotification`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({
            projectId,
            active,
        }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function setChangedModaNotification({
    projectId,
    active,
}: {
    projectId: string
    active: boolean
}): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/moda/setChangedModaNotification`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({
            projectId,
            active,
        }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function getClientsByQuery({
    projectId,
    query,
}: {
    projectId: string
    query: string
}): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/client/search`)
    const params: any = { projectId, q: query }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function getActivationFilterValues({
    projectId,
}: {
    projectId: string
}): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/taskList/filters/activations`)
    const params: any = { projectId }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function getVisitCountFilterValues({
    projectId,
}: {
    projectId: string
}): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/taskList/filters/attendanceRange`)
    const params: any = { projectId }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function getMaxClientsCount({
    projectId,
}: {
    projectId: string
}): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/client/count`)
    const params: any = { projectId }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function getOverviewCardStat({
    projectId,
}: {
    projectId: string
}): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/overview/cardStat`)
    const params: any = { projectId }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function getModa({
    projectId,
    clientId,
}: {
    projectId: string
    clientId: string
}): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/taskList/moda`)
    const params: any = { projectId, clientId }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function saveCustomModa({
    projectId,
    scenario,
    customModa,
    clientId,
    type,
}: {
    projectId: string
    scenario: string
    customModa: number
    clientId: string
    type: 'personal' | 'standart'
}): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/taskList/customModa`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({
            projectId,
            scenario,
            customModa,
            clientId,
            type,
        }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function getReturnabilityData({
    projectId,
    filters,
}: {
    projectId: string
    filters: returnabilityFilters
}): Promise<ApiWrapper<any | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/returnability/getReturnabiltyData`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({
            projectId,
            filters,
        }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function init({ projectId }: { projectId: string }): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    let url = new URL(`${cfg.updaterApiEndpoint}/GCB2/init`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({
            projectId,
        }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}

export async function getMarketingReportData({
    projectId,
    type,
    filters,
}: {
    projectId: string
    type: 'staff' | 'category' | 'acquisition'
    filters: any
}): Promise<ApiWrapper<any | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/getMarketingReport`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({ projectId, filters, type }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function getAdminReportPseudoActivationReport({
    projectId,
    filters,
    includeFileLink,
}: {
    projectId: string
    filters: any
    includeFileLink?: boolean
}) {
    const url = `GCB2/adminReportPseudoActivation/getData`
    const res = apiCall<any>(() =>
        http.post(url.toString(), { json: { projectId, filters, includeFileLink } })
    )
    return res
}
export async function getSimpleReportsData({ projectId, filters }: { projectId: string; filters: any }) {
    const url = `GCB2/getSimpleReports`
    const res = apiCall<any>(() => http.post(url.toString(), { json: { projectId, filters } }))
    return res
}
export async function getChurnReportData({ projectId, filters }: { projectId: string; filters: any }) {
    const url = `GCB2/getChurnReport`
    const res = apiCall<any>(() => http.post(url.toString(), { json: { projectId, filters } }))
    return res
}
export async function getDoctorReportData({ projectId }: { projectId: string }) {
    const url = `GCB2/doctorReport?projectId=${projectId}`
    const res = apiCall<any>(() => http.get(url.toString()))
    return res
}
export async function getNewStaffReportData({ projectId, filters }: { projectId: string; filters: any }) {
    const url = `GCB2/getNewStaffReport`
    const res = apiCall<any>(() => http.post(url.toString(), { json: { projectId, filters } }))
    return res
}
export async function getFourHandsReportData({
    projectId,
    filters,
    refresh,
}: {
    projectId: string
    filters: any
    refresh?: boolean
}) {
    const url = `GCB2/getFourhandsReport`

    const res = apiCall<any>(() =>
        http.post(url.toString(), {
            json: { projectId, filters: refresh ? { refreshOnly: true, ...filters } : filters },
        })
    )
    return res
}
export async function getPersonaReportData({
    projectId,
    filters,
    refresh,
}: {
    projectId: string
    filters: any
    refresh?: boolean
}) {
    const url = `GCB2/persona/report/getData`

    const res = apiCall<any>(() =>
        http.post(url.toString(), {
            json: { projectId, filters: refresh ? { refreshOnly: true, ...filters } : filters },
        })
    )
    return res
}
export async function getSameDayRecReportData({ projectId, filters }: { projectId: string; filters: any }) {
    const url = `GCB2/getSameDayRecReport`
    const res = apiCall<any>(() => http.post(url.toString(), { json: { projectId, filters } }))
    return res
}
export async function getProductList({
    projectId,
}: {
    projectId: string
}): Promise<ApiWrapper<{ id: string; name: string }[]>> {
    const url = `GCB2/list/product`
    const res = apiCall<any>(() => http.get(url.toString(), { searchParams: { projectId } }))
    return res
}
export async function communicationUpdate({
    projectId,
}: {
    projectId: string
}): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/data/communicationUpdate`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({ projectId }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function getKpiSchemeIdFilterValues({
    projectId,
}: {
    projectId: string
}): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/kpiReport/kpiSchemeIdFilterValues`)
    const options = {
        method: 'GET',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
    }
    const params: any = { projectId }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function getKpiReportData({
    projectId,
    filters,
}: {
    projectId: string
    filters: any
}): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/kpiReport/getData`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({ projectId, filters }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function getKpiReportFilters({
    projectId,
    filtersDate,
}: {
    projectId: string
    filtersDate: object
}): Promise<ApiWrapper<any | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/kpiReport/getFilters`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({ projectId, filtersDate }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function getKpiSettings({
    projectId,
    full,
    id,
}: {
    projectId: string
    full?: boolean
    id?: number
}): Promise<ApiWrapper<SourceFormatKpiSetting[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/kpiReport/settings`)
    const options = {
        method: 'GET',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
    }
    const params: any = _.omitBy({ projectId, full, id }, _.isUndefined)
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function kpiEditSetting({
    id,
    projectId,
    settings,
}: {
    id: number
    projectId: string
    settings: { name?: 'string'; description?: string; is_default?: boolean; scheme?: any }
}): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/kpiReport/settings/edit`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({ id, projectId, settings }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function kpiCreateSetting({
    id,
    projectId,
    settings,
}: {
    id: number
    projectId: string
    settings: { name?: 'string'; description?: string; is_default?: boolean; scheme?: any }
}): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/kpiReport/settings/create`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({ id, projectId, settings }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function kpiDeleteSetting({
    id,
    projectId,
}: {
    id: number
    projectId: string
}): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/kpiReport/settings/delete`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({ id, projectId }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function kpiCopySetting({
    id,
    projectId,
}: {
    id: number
    projectId: string
}): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/kpiReport/settings/copy`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({ id, projectId }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function kpiSettingsGetServiceCategories({
    projectId,
}: {
    projectId: string
}): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/kpiReport/serviceCategories`)
    const options = {
        method: 'GET',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
    }
    const params: any = _.omitBy({ projectId }, _.isUndefined)
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function kpiSettingsSaveServiceCategories({
    projectId,
    categories,
}: {
    projectId: string
    categories: any[]
}): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/kpiReport/serviceCategories`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({ projectId, categories }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status > 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function kpiSettingsDeleteServiceCategory({
    projectId,
    categoryId,
}: {
    projectId: string
    categoryId: string
}): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/kpiReport/serviceCategories`)
    const options = {
        method: 'DELETE',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
    }
    const params: any = _.omitBy({ projectId, categoryId }, _.isUndefined)
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function kpiSettingsGetActivations({
    projectId,
}: {
    projectId: string
}): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/kpiReport/activations`)
    const options = {
        method: 'GET',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
    }
    const params: any = _.omitBy({ projectId }, _.isUndefined)
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function getBanServiceList({
    projectId,
}: {
    projectId: string
}): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/banServiceList`)
    const options = {
        method: 'GET',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
    }
    const params: any = { projectId }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function changeBanServiceList({
    projectId,
    diff,
}: {
    projectId: string
    diff: { added: { id: string }[]; deleted: { id: string }[] }
}): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/banServiceList`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({ diff, projectId, userId: auth.user.sub }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function changeBanClientList({
    projectId,
    telephonesList,
}: {
    projectId: string
    telephonesList: string[]
}): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/services/banClients`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({ telephonesList, projectId, userId: auth.user.sub }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        console.log(error)
        throw new Error(error)
    }
    const data = await res.json()
    return { data, error: null }
}
export async function getServiceList({
    projectId,
}: {
    projectId: string
}): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/list/service`)
    const options = {
        method: 'GET',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
    }
    const params: any = { projectId }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function getServiceByCategoryList({
    projectId,
}: {
    projectId: string
}): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/list/serviceByCategory`)
    const options = {
        method: 'GET',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
    }
    const params: any = { projectId }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function getServiceCategoryList({
    projectId,
}: {
    projectId: string
}): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/list/serviceCategories`)
    const options = {
        method: 'GET',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
    }
    const params: any = { projectId }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function getActivationList({
    projectId,
}: {
    projectId: string
}): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/list/activation`)
    const options = {
        method: 'GET',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
    }
    const params: any = { projectId }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function getStaffList({ projectId }: { projectId: string }): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/list/staff`)
    const options = {
        method: 'GET',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
    }
    const params: any = { projectId }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function getClientList({
    projectId,
}: {
    projectId: string
}): Promise<ApiWrapper<{ id: string; name: string }[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/list/client`)
    const options = {
        method: 'GET',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
    }
    const params: any = { projectId }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function getMessageVarsValues({ projectId, varIds }: { projectId: string; varIds: string[] }) {
    const url = `GCB2/getMessageVarsValues`
    const res = apiCall<MessageVarBody[]>(() => http.post(url.toString(), { json: { projectId, varIds } }))
    return res
}
export async function updateDefaultMessageVarsValues({
    projectId,
    messageVars,
}: {
    projectId: string
    messageVars: MessageVarBody[]
}) {
    const url = `GCB2/updateDefaultMessageVarsValues`
    const res = apiCall<MessageVarBody[]>(() =>
        http.post(url.toString(), { json: { projectId, messageVars } })
    )
    return res
}
export async function updateMessageVarExeption({
    projectId,
    clientId,
    varId,
    value,
    confirmed,
}: {
    projectId: string
    varId: string
    clientId: string
    value: string
    confirmed?: boolean
}): Promise<ApiWrapper<any>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/messageVarExeption`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify(_.omitBy({ projectId, clientId, varId, value, confirmed }, isNil)),
    }

    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: [], error }
    }
    const data = await res.json()
    return { data, error: null }
}

export async function deleteMessageVarExeption({
    projectId,
    varId,
    clientId,
}: {
    projectId: string
    varId: string
    clientId: string
}): Promise<ApiWrapper<MessageVarBody[]>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/messageVarExeption`)
    const options = {
        method: 'DELETE',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
    }
    const params: any = { projectId, varId, clientId }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: [], error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function nmSendCallReport({
    projectId,
    email,
    reportType,
}: {
    projectId: string
    email: string
    reportType: string
}): Promise<ApiWrapper<any>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/GCB2/sendNmReport`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({ projectId, email, reportType }),
    }

    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}

export async function getCommunicationTags(projectId: string) {
    const url = `GCB2/communicationTag`
    const res = apiCall<CommunicationTag[]>(() => http.get(url.toString(), { searchParams: { projectId } }))
    return res
}
export async function getActivation({ projectId, id }: { projectId: string; id: number }) {
    const url = `GCB2/activation/one`
    const res = apiCall<Activation>(() => http.get(url.toString(), { searchParams: { projectId, id } }))
    return res
}
export async function setActivationMedia({
    id,
    projectId,
    mimeType,
    src,
    originalName,
}: {
    id: number
    projectId: string
    mimeType: string
    src: string
    originalName: string
}) {
    const url = `GCB2/activation/media`
    const res = apiCall<ActivationMedia>(() =>
        http.post(url.toString(), { json: { id, projectId, mimeType, src, originalName } })
    )
    return res
}

export async function getActivationMedia({ id, projectId }: { id: number; projectId: string }) {
    const url = `GCB2/activation/media`
    const res = apiCall<ActivationMedia>(() => http.get(url.toString(), { searchParams: { projectId, id } }))
    return res
}

export async function delActivationMedia({ id, projectId }: { id: number; projectId: string }) {
    const url = `GCB2/activation/media`
    const res = apiCall<ActivationMedia>(() =>
        http.delete(url.toString(), { searchParams: { projectId, id } })
    )
    return res
}

export async function getFirstActivation(projectId: string) {
    const url = `GCB2/activation/first`
    const res = apiCall<Activation>(() => http.get(url.toString(), { searchParams: { projectId } }))
    return res
}
export async function saveCommunicationTags({
    projectId,
    tags,
}: {
    projectId: string
    tags: CommunicationTag[]
}) {
    const url = `GCB2/communicationTag`
    const res = apiCall<CommunicationTag[]>(() => http.post(url.toString(), { json: { projectId, tags } }))
    return res
}
export async function getMessageCollection() {
    const url = `GCB2/messageCollection`
    const res = apiCall<any>(() => http.get(url.toString()))
    return res
}
