
import { defineComponent } from 'vue'

export default defineComponent({
    data() {
        return {
            mainLogo: require('@/assets/Logo_Chat.svg'),
            isHovered: false,
        }
    },
    computed: {
        project(): any {
            const projectId = this.$router.currentRoute.params.id
            return this.$store.getters.projectById(projectId)
        },
        waConnector(this: { project? }) {
            return this.project?.connectors.find(con => con.connectorType === 'whatsappBasis')
        },
        chatRout(this: { project? }) {
            return `/chat/${this.project?.id}`
        },
    },
})
