
import { computed, defineComponent, ref } from 'vue'
import { useProject } from '../hooks/useProject'
import { useHoliday } from '../hooks/useHoliday'
export default defineComponent({
    components: {},
    setup(props, context) {
        const { project } = useProject()
        const { holiday } = useHoliday()

        const mainLogo = computed(() => {
            if (holiday.value === 'NEW_YEAR') return require('@/assets/Logo_new_year.svg')
            if (holiday.value === 'VALENTINES_DAY') return require('@/assets/Logo_valentines_day.svg')
            if (holiday.value === '23_FEBRUARY') return require('@/assets/Logo_23_february.png')
            if (holiday.value === '8_MARCH') return require('@/assets/Logo_8_march.svg')
            return require('@/assets/Logo_light.svg')
        })

        /*        const mainLogo = computed(() =>
            props.isNewYear ? require('@/assets/Logo_new_year.svg') : require('@/assets/Logo_light.svg')
        ) */
        const partnerLogo = computed(() => {
            const projectPartneredWith = project.value?.settings?.partneredWith
            if (!projectPartneredWith) return null
            if (projectPartneredWith === 'loreal') return require('@/assets/img/partner-logo/loreal.svg')
            if (projectPartneredWith === 'matrix') return require('@/assets/img/partner-logo/matrix.png')
        })
        return { mainLogo, partnerLogo, project }
    },
})
