
import { PropType, computed, defineComponent, watch, ref, onBeforeMount, onMounted } from 'vue'
import * as _ from 'lodash'
import useRouter from '../../../../../hooks/useRouter'
import { useProject } from '../../../../../hooks/useProject'
import { CONNECTOR_TYPES } from '../../../../../vars/general'
import api from '../../../../../api/api'
import CustomActivatorSelect from '../../../../inputs/CustomActivatorSelect.vue'
import BaseInputOutlined from '../../../../inputs/BaseInputOutlined.vue'
import TextInputGroup from '../../../../ui/TextInputGroup.vue'
export default defineComponent({
    props: {
        value: {
            type: Object,
            required: true,
        },
        app: {
            type: Object,
            required: true,
        },
    },
    components: { TextInputGroup, CustomActivatorSelect, BaseInputOutlined },
    setup(props, { emit }) {
        const { route } = useRouter()
        const trigger = computed(() => route.value.query.id)
        const activeTab = ref(0)

        const tabs = [
            {
                tabName: 'Подтверждение',
                key: '1',
            },
            {
                tabName: 'Отмена',
                key: '2',
            },
        ]
        const activeTabKey = computed(() => tabs[activeTab.value]?.key)

        const reactions = computed(() => {
            return props.value.answersList?.[activeTabKey.value] || []
        })
        const updateReactions = (val: string[]) => {
            const answersList = Object.assign({}, props.value.answersList, { [activeTabKey.value]: val })
            emit('input', Object.assign({}, props.value, { answersList }))
        }

        return {
            trigger,
            tabs,
            activeTab,
            reactions,
            updateReactions,
        }
    },
})
