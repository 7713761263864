import store from '../store'

export function checkRole(projectId: string, permissionId: string) {
    const project = store.getters.projectById(projectId)

    if (!project) {
        return false
    }
    const rolesSettings = project.rolesSettings
    const permissionRolesSettings = rolesSettings[permissionId]
    if (!permissionRolesSettings) {
        throw new Error(`permission ${permissionId} doesn't exist `)
    }

    if (project.billingEnabled) {
        return permissionRolesSettings.isActive && permissionRolesSettings.access[project.myRole]
    }
    return permissionRolesSettings.access[project.myRole]
}
export function checkModuleIsActive(projectId: string, permissionId: string) {
    const project = store.getters.projectById(projectId)
    const rolesSettings = project.rolesSettings
    const permissionRolesSettings = rolesSettings[permissionId]
    if (!permissionRolesSettings) {
        throw new Error(`permission ${permissionId} doesn't exist `)
    }
    if (project.billingEnabled) {
        return permissionRolesSettings.isActive
    }
    return true
}
