const Marketplace = () => import('@/modules/marketplace/views/Marketplace.vue')
const Integration = () => import('@/modules/marketplace/views/Integration.vue')
const Billing = () => import('@/modules/marketplace/views/Billing.vue')
export default [
    {
        path: '/project/:id/marketplace',
        name: 'marketplace',
        component: Marketplace,
        unprotected: false,
    },
    {
        path: '/project/:id/marketplace/integration/:integrationId',
        name: 'marketplaceIntegration',
        component: Integration,
        unprotected: false,
    },
    {
        path: '/project/:id/billing',
        name: 'Billing',
        component: Billing,
        unprotected: false,
    },
]
