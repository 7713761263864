import { ApiWrapper } from '../types/main'
import { ScheduledReport, ScheduledReportTemplate, ScheduledReportData } from '../types/scheduledReport'
import { apiCall, http } from './http'
interface UploadFileParams {
    file: File
    projectId: string
    activationId?: number
}
export async function uploadMedia({ projectId, file }: UploadFileParams) {
    const url = `file/media`
    const formData = new FormData()
    formData.append('projectId', projectId)
    formData.append('file', file, file.name)
    const res = apiCall<{ url: string }>(() => http.post(url.toString(), { body: formData }))
    return res
}
export async function uploadExcel({ projectId, file, activationId }: UploadFileParams) {
    const url = `excel/upload`
    const formData = new FormData()
    formData.append('projectId', projectId)
    formData.append('file', file, file.name)
    formData.append('activationId', activationId.toString())
    const res = apiCall<any>(() => http.post(url.toString(), { body: formData }))
    return res
}
