import { apiCall, http } from '../http'
interface LoginParams {
    userToken: string
    partnerToken: string
    connectorId: string
}
export async function login({ userToken, partnerToken, connectorId }: LoginParams) {
    const url = `arnicaV2/login`
    const res = apiCall<any>(() =>
        http.post(url.toString(), { json: { userToken, partnerToken, connectorId } })
    )
    return res
}
