import config from '@root/config'
import audience from './audience/audience'
import activation from './activation/activation'
import message from './message/message'
import adminReport from './analytics/adminReport'
import taskList from './taskList/taskList'
import { SideBarSection } from '@/types/GCB2'
import * as t from './actionTypes'
import { sleep } from '../../../utils'
import { init } from '../../../api/chisai/GCB2'
export default {
    state: {
        activeSection: null,
        highlitedRowAfterAction: null,
    },
    mutations: {
        [t.SET_HIGHLIGHTED_ROW_AFTER_ACTION](state: any, value: number) {
            state.highlitedRowAfterAction = value
        },
    },
    actions: {
        async [t.HIGHLIGHT_ROW_AFTER_ACTION](
            { state, commit }: any,
            row: { id: string; type: string; entity: string }
        ) {
            commit(t.SET_HIGHLIGHTED_ROW_AFTER_ACTION, row)
            await sleep(3000)
            commit(t.SET_HIGHLIGHTED_ROW_AFTER_ACTION, null)
        },
        async [t.INIT]({ state, commit, dispatch }: any, projectId: string) {
            const res = await init({ projectId })
            if (res.error) {
                dispatch('callNotify', 'Произошла ошибка', { root: true })
                return
            }
            await dispatch('project/fetchProject', projectId, { root: true })
        },
    },
    modules: {
        audience: { namespaced: false, ...audience },
        message: { namespaced: false, ...message },
        activation: { namespaced: false, ...activation },
        adminReport: { namespaced: false, ...adminReport },
        taskList: { namespaced: false, ...taskList },
    },
}
