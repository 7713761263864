<template>
    <div class="breadcrumbs-wrapper" :class="{ 'bar-is-opened': alertStackBarIsOpened }">
        <div
            class="breadcrumbs"
            :class="{
                'bar-is-opened': notificationStore.BarIsActive,
                'breadcrumbs-fixed': trasitionEnd,
            }"
        >
            <div class="breadcrumbs-content">
                <div v-for="(item, i) in items" :key="i">
                    <router-link
                        class="breadcrumbs-item ml-3"
                        :to="item.to"
                        :class="{ disabled: item.disabled }"
                    >
                        <img
                            class="navigation"
                            width="24px"
                            height="24px"
                            src="@/assets/icons/west-24px.svg"
                        />
                        <span class="ml-3">{{ item.text }}</span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useNotificationStore } from '@/store/stores/notification'
import * as _ from 'lodash'

export default {
    name: 'Breadcrumbs',
    props: {
        items: Array,
    },
    components: {},
    computed: {
        ...mapStores(useNotificationStore),
        alertStackBarIsOpened() {
            return this.$store.state.alertStackBarIsOpened
        },
    },
    mounted() {
        const title = this.items
            .map((item, i) => (i === 0 ? 'Data Heroes' : _.toUpper(item.text)))
            .join(' — ')
        document.title = title
    },
    destroyed() {
        document.title = 'Data Heroes'
    },

    data: () => ({ trasitionEnd: true }),
}
</script>

<style lang="sass" scoped>
@import '@/vars.sass'
.breadcrumbs-wrapper
    width: 100%
    flex-grow: 1
    top: $navbar-height
    display: flex !important
    justify-content: center
    position: fixed !important
    height: 80px
    background-color: white
    z-index: 11
    text-overflow: clip
    &.sidebar-is-open
        width: calc( 100% - #{$gcb2-sidebar-width} )
    &.bar-is-opened
        margin-top: $notification-height
.breadcrumbs
    width: 100%
    text-transform: uppercase
    height: 80px
    background-color: white
    display: flex !important
    justify-content: center
    align-items: center
    padding-left: 0 !important
    padding-right: 0 !important
    &.bar-is-opened
        margin-top: $notification-height
.breadcrumbs-content
    text-transform: uppercase
    height: 80px
    background-color: white
    align-items: center
    padding-left: 0 !important
    padding-right: 0 !important
    display: flex
    &:not(.sidebar-is-open)
        width: 1016px
        max-width: 1016px
    &.sidebar-is-open
        max-width: 1016px
        width: calc( 100% - #{$gcb2-sidebar-width} )
    &.bar-is-opened
        margin-top: $notification-height
.breadcrumbs-item
    height: 24px
    display: flex
    justify-content: center
    align-items: center
::v-deep.breadcrumbs a
    color: black !important
    font-size: 14px
    text-decoration: none
.breadcrumbs-fixed
    top: $navbar-height
    display: flex !important
    position: fixed !important
    z-index: 11
.disabled
    opacity: 0.4
    cursor: default
    pointer-events: none
    -webkit-touch-callout: none
    -webkit-user-select: none
    -khtml-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
</style>
