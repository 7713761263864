// @ts-nocheck
import Vue from 'vue'
import Vuex from 'vuex'
import { getInstance } from '../auth/authWrapper'
import cfg from '../../config'
//modules

import project from './project.js'
import GCB2 from './chisai/GCB2/GCB2'
import { CONNECTOR_TYPES, GCB_EXTERNAL_CONNECTOR_TYPES, TELEPHONY_CONNECTOR_TYPES } from '../vars/general'
import { RootState } from './types'
import _ from 'lodash'
Vue.use(Vuex)
const store = {
    strict: cfg.buildType === 'PRODUCTION' ? false : true,
    state: {
        alertStackBarIsOpened: null,
        isProjectsLoading: true,
        isAuthDialog: false,
        authDialogStartSection: 'login',
        loadingCount: 0,
        error: null,
        isHintSidebar: false,
        sidebarHintId: null,
        loading: false,
        isCommunicationUpdate: false,
        notifyText: '',
        isNotifyActive: false,
        serviceTableName: 'service_service_s',
        roles: [
            { name: 'Наблюдатель', id: 'observer', power: 1 },
            { name: 'Редактор', id: 'admin', power: 2 },
            { name: 'Владелец', id: 'owner', power: 3 },
        ],
        rolesObject: {
            observer: { name: 'Наблюдатель', id: 'observer', power: 1 },
            admin: { name: 'Редактор', id: 'admin', power: 2 },
            owner: { name: 'Владелец', id: 'owner', power: 3 },
        },
        //TODO: перенести в connectorList
        connectorsAvatars: [
            { type: 'alfa', color: '#5dbf08', symbol: 'AL', image: null, name: 'Альфа CRM' },
            { type: 'planfact', color: '#0244bf', symbol: 'PF', image: null, name: 'ПланФакт' },
            { type: 'zenmoney', color: '#ed131e', symbol: 'ZM', image: null, name: 'Дзен-мани' },
            {
                type: CONNECTOR_TYPES.altegio,
                color: 'rgb(255,197,73)',
                symbol: 'YC',
                image: null,
                name: 'Altegio',
            },
            {
                type: CONNECTOR_TYPES.pbxOnline,
                color: 'rgb(255,197,73)',
                symbol: 'pbx',
                image: null,
                name: 'pbxOnline',
            },
            { type: 'yClients', color: 'rgb(255,197,73)', symbol: 'YC', image: null, name: 'YCLIENTS' },
            {
                type: 'universeSoft',
                color: 'rgb(255,197,73)',
                symbol: 'UC',
                image: null,
                name: 'Universe soft',
            },
            {
                type: CONNECTOR_TYPES.profSalon,
                color: 'rgb(255,197,73)',
                symbol: 'PF',
                image: null,
                name: 'ПрофСалон',
            },
            {
                type: CONNECTOR_TYPES.sycret,
                color: 'rgb(255,197,73)',
                symbol: 'SY',
                image: null,
                name: 'Sycret',
            },
            {
                type: CONNECTOR_TYPES.sonline,
                color: 'rgb(255,197,73)',
                symbol: 'SO',
                image: null,
                name: 'Sonline',
            },
            {
                type: CONNECTOR_TYPES.arnica,
                color: 'rgb(255,197,73)',
                symbol: 'AR',
                image: null,
                name: 'Arnica',
            },
            {
                type: CONNECTOR_TYPES.arnicaV2,
                color: 'rgb(255,197,73)',
                symbol: 'AR',
                image: null,
                name: 'Arnica v2',
            },
            {
                type: CONNECTOR_TYPES.moiSklad,
                color: 'rgb(255,197,73)',
                symbol: 'MS',
                image: null,
                name: 'Мой склад (файловая интеграция)',
            },
            {
                type: CONNECTOR_TYPES.helix1C,
                color: 'rgb(255,197,73)',
                symbol: '1C',
                image: null,
                name: 'Helix 1C',
            },
            {
                type: CONNECTOR_TYPES.lenaLenina,
                color: 'rgb(255,197,73)',
                symbol: 'LL',
                image: null,
                name: 'Студия Лены Лениной',
            },
            {
                type: CONNECTOR_TYPES.mango,
                color: 'rgb(255,197,73)',
                symbol: 'ma',
                image: null,
                name: 'Mango',
            },
            {
                type: CONNECTOR_TYPES.chat2desk,
                color: 'rgb(255,197,73)',
                symbol: 'c2d',
                image: null,
                name: 'Chat 2 desk',
            },
            {
                type: CONNECTOR_TYPES.whatsapp,
                color: '#45c554',
                symbol: 'wa',
                image: null,
                name: 'WhatsApp бот от Data Heroes',
            },
            {
                type: CONNECTOR_TYPES.whatsappBasis,
                color: '#45c554',
                symbol: 'wa',
                image: null,
                name: 'WhatsApp',
            },
            {
                type: CONNECTOR_TYPES.profSalonBat,
                color: 'rgb(255,197,73)',
                symbol: 'PF',
                image: null,
                name: 'ПрофСалон',
            },
            {
                type: CONNECTOR_TYPES.profSalonBatV2,
                color: 'rgb(255,197,73)',
                symbol: 'PF',
                image: null,
                name: 'ПрофСалон v2',
            },

            {
                type: CONNECTOR_TYPES.dikidi,
                color: 'rgb(255,197,73)',
                symbol: 'DD',
                image: null,
                name: 'Dikidi',
            },
            {
                type: CONNECTOR_TYPES.archimed,
                color: '#fe2500',
                symbol: 'AR',
                image: null,
                name: 'Archimed',
            },
            {
                type: CONNECTOR_TYPES.ruslan1C,
                color: '#fe2500',
                symbol: '1C',
                image: null,
                name: '1С: Медицина',
            },
            {
                type: CONNECTOR_TYPES.telephonyMegaphone,
                color: '#00b856',
                symbol: 'MG',
                image: null,
                name: 'Телефония Мегафон',
            },
            {
                type: CONNECTOR_TYPES.telephonyProstyiZvonki,
                color: '#3a98d5',
                symbol: 'PZ',
                image: null,
                name: 'Простые звонки',
            },
            {
                type: CONNECTOR_TYPES.telephonyBeeline,
                color: '#f7c201',
                symbol: 'BE',
                image: null,
                name: 'Телефония Билайн',
            },
            {
                type: CONNECTOR_TYPES.telephonyMango,
                color: '#f7c201',
                symbol: 'MA',
                image: null,
                name: 'Телефония Mango',
            },
            {
                type: CONNECTOR_TYPES.amoCrm,
                color: '#3a98d5',
                symbol: 'AM',
                image: null,
                name: 'amoCRM',
            },
            {
                type: CONNECTOR_TYPES.amoCrmFull,
                color: '#3a98d5',
                symbol: 'AM',
                image: null,
                name: 'amoCRM (полная интеграция)',
            },
            {
                type: CONNECTOR_TYPES.dentalPro,
                color: '#3a98d5',
                symbol: 'DP',
                image: null,
                name: 'DentalPRO',
            },
            {
                type: CONNECTOR_TYPES.vpn1C,
                color: '#d91920',
                symbol: '1С',
                image: null,
                name: '1С',
            },
            {
                type: CONNECTOR_TYPES.cloud1C,
                color: '#d91920',
                symbol: '1С',
                image: null,
                name: '1C Онлайн',
            },
            {
                type: CONNECTOR_TYPES.klientix,
                color: '#d91920',
                symbol: 'KL',
                image: null,
                name: 'Клиентикс',
            },
            {
                type: CONNECTOR_TYPES.sipuni,
                color: '#d91920',
                symbol: 'SI',
                image: null,
                name: 'Sipuni',
            },
            {
                type: CONNECTOR_TYPES.klatch,
                color: '#d91920',
                symbol: 'KL',
                image: null,
                name: 'Клатч',
            },
            {
                type: CONNECTOR_TYPES.prostoSMS,
                color: '#1ab9fc',
                symbol: 'PS',
                image: null,
                name: 'prostoSMS',
            },
        ],
        connectorsSettingsImportance: [
            { type: 'alfa', settings: { group: 'deep', division: 'deep', currency: 'minor' } },
            { type: 'planfact', settings: { pfCompaniesId: 'minor' } },
            { type: 'zenmoney', settings: {} },
            { type: 'yClients', settings: { group: 'deep', division: 'deep', companyIds: 'minor' } },
        ],
        projects: [],
        connectorsList: [
            { type: CONNECTOR_TYPES.alfa, name: 'Альфа CRM', maxCount: 1 },
            { type: CONNECTOR_TYPES.planfact, name: 'ПланФакт', maxCount: 1 },
            { type: CONNECTOR_TYPES.zenmoney, name: 'Дзен-мани', maxCount: 1 },
            {
                type: CONNECTOR_TYPES.yClients,
                name: 'YCLIENTS',
                maxCount: 1,
                notIncludes: GCB_EXTERNAL_CONNECTOR_TYPES.filter(el => el !== CONNECTOR_TYPES.lenaLenina),
            },
            {
                type: CONNECTOR_TYPES.universeSoft,
                name: 'Universe soft',
                maxCount: 1,
                notIncludes: GCB_EXTERNAL_CONNECTOR_TYPES,
            },
            {
                type: CONNECTOR_TYPES.profSalon,
                name: 'ПрофСалон',
                maxCount: 1,
                notIncludes: GCB_EXTERNAL_CONNECTOR_TYPES,
                showCondition: () => false,
            },
            {
                type: CONNECTOR_TYPES.sycret,
                name: 'Sycret',
                maxCount: 1,
                notIncludes: GCB_EXTERNAL_CONNECTOR_TYPES,
            },
            {
                type: CONNECTOR_TYPES.sonline,
                name: 'Sonline',
                maxCount: 1,
                notIncludes: GCB_EXTERNAL_CONNECTOR_TYPES,
            },
            {
                type: CONNECTOR_TYPES.arnica,
                name: 'Арника',
                maxCount: 1,
                notIncludes: GCB_EXTERNAL_CONNECTOR_TYPES,
            },
            {
                type: CONNECTOR_TYPES.arnicaV2,
                name: 'Арника v2',
                maxCount: 1,
                notIncludes: GCB_EXTERNAL_CONNECTOR_TYPES,
            },
            {
                type: CONNECTOR_TYPES.moiSklad,
                name: 'Мой склад (файловая интеграция)',
                maxCount: 1,
                notIncludes: GCB_EXTERNAL_CONNECTOR_TYPES,
            },
            {
                type: CONNECTOR_TYPES.helix1C,
                name: 'Helix 1C',
                maxCount: 1,
                notIncludes: GCB_EXTERNAL_CONNECTOR_TYPES,
            },
            {
                type: CONNECTOR_TYPES.lenaLenina,
                name: 'Студия Лены Лениной',
                maxCount: 1,
                notIncludes: GCB_EXTERNAL_CONNECTOR_TYPES,
            },
            {
                type: CONNECTOR_TYPES.mango,
                name: 'Mango',
                maxCount: 1,
                notIncludes: GCB_EXTERNAL_CONNECTOR_TYPES,
            },
            {
                type: CONNECTOR_TYPES.chat2desk,
                name: 'Chat 2 desk',
                maxCount: 1,
                notIncludes: GCB_EXTERNAL_CONNECTOR_TYPES,
            },
            {
                type: CONNECTOR_TYPES.altegio,
                name: 'Altegio',
                maxCount: 1,
                notIncludes: GCB_EXTERNAL_CONNECTOR_TYPES,
            },
            {
                type: CONNECTOR_TYPES.profSalonBat,
                name: 'ПрофСалон',
                maxCount: 1,
                notIncludes: GCB_EXTERNAL_CONNECTOR_TYPES,
            },
            {
                type: CONNECTOR_TYPES.profSalonBatV2,
                name: 'ПрофСалон v2',
                maxCount: 1,
                notIncludes: GCB_EXTERNAL_CONNECTOR_TYPES,
            },
            {
                type: CONNECTOR_TYPES.dikidi,
                name: 'Dikidi',
                maxCount: 1,
                notIncludes: GCB_EXTERNAL_CONNECTOR_TYPES,
            },
            {
                type: CONNECTOR_TYPES.archimed,
                name: 'Archimed',
                maxCount: 1,
                notIncludes: GCB_EXTERNAL_CONNECTOR_TYPES,
            },
            {
                type: CONNECTOR_TYPES.ruslan1C,
                name: '1С: Медицина',
                maxCount: 1,
                notIncludes: GCB_EXTERNAL_CONNECTOR_TYPES,
            },
            {
                type: CONNECTOR_TYPES.whatsapp,
                name: 'WhatsApp бот от Data Heroes',
                maxCount: 1,
                notIncludes: [],
            },
            {
                type: CONNECTOR_TYPES.telephonyMegaphone,
                name: 'Телефония Мегафон',
                maxCount: 1,
                notIncludes: TELEPHONY_CONNECTOR_TYPES,
            },
            {
                type: CONNECTOR_TYPES.telephonyBeeline,
                name: 'Телефония Билайн',
                maxCount: 1,
                notIncludes: TELEPHONY_CONNECTOR_TYPES,
            },
            {
                type: CONNECTOR_TYPES.pbxOnline,
                name: 'Телефония PBX',
                maxCount: 1,
                notIncludes: TELEPHONY_CONNECTOR_TYPES,
            },
            {
                type: CONNECTOR_TYPES.telephonyMango,
                name: 'Телефония Mango',
                maxCount: 1,
                notIncludes: TELEPHONY_CONNECTOR_TYPES,
            },
            {
                type: CONNECTOR_TYPES.telephonyProstyiZvonki,
                name: 'Простые звонки',
                maxCount: 1,
                notIncludes: TELEPHONY_CONNECTOR_TYPES,
            },
            {
                type: CONNECTOR_TYPES.sipuni,
                name: 'Sipuni',
                maxCount: 1,
                notIncludes: TELEPHONY_CONNECTOR_TYPES,
            },
            {
                type: CONNECTOR_TYPES.amoCrm,
                name: 'amoCRM',
                maxCount: 1,
            },
            {
                type: CONNECTOR_TYPES.amoCrmFull,
                name: 'amoCRM (Полная интеграция)',
                maxCount: 1,
                notIncludes: GCB_EXTERNAL_CONNECTOR_TYPES,
            },

            {
                type: CONNECTOR_TYPES.dentalPro,
                name: 'DentalPRO',
                maxCount: 1,
                notIncludes: GCB_EXTERNAL_CONNECTOR_TYPES,
            },
            {
                type: CONNECTOR_TYPES.vpn1C,
                name: '1С',
                maxCount: 1,
                notIncludes: GCB_EXTERNAL_CONNECTOR_TYPES,
            },
            {
                type: CONNECTOR_TYPES.cloud1C,
                name: '1C Онлайн',
                maxCount: 1,
                notIncludes: GCB_EXTERNAL_CONNECTOR_TYPES,
            },
            {
                type: CONNECTOR_TYPES.klientix,
                name: 'Клиентикс',
                maxCount: 1,
                notIncludes: GCB_EXTERNAL_CONNECTOR_TYPES,
            },
            {
                type: CONNECTOR_TYPES.klatch,
                name: 'Клатч',
                maxCount: 1,
                notIncludes: GCB_EXTERNAL_CONNECTOR_TYPES,
            },
            {
                type: CONNECTOR_TYPES.whatsappBasis,
                name: 'WhatsApp',
                maxCount: 1,
                notIncludes: [],
            },
            {
                type: CONNECTOR_TYPES.prostoSMS,
                name: 'ProstoSMS',
                maxCount: 1,
                notIncludes: [],
            },
        ].filter(con =>
            cfg.disabledConnectorsTypes ? !cfg.disabledConnectorsTypes.includes(con.type) : true
        ), // на беке лимиты менять в vars.js connectorsLimits
        currencies: [],
        defaultCurency: 'RUB',
    },
    mutations: {
        setAlertStackBarIsOpened(state, value) {
            state.alertStackBarIsOpened = value
        },
        setError(state, error) {
            state.error = error
        },
        setIsProjectsLoading(state, value) {
            state.isProjectsLoading = value
        },
        setIsHintSidebar(state, value) {
            state.isHintSidebar = value
        },
        setSidebarHintId(state, value) {
            state.sidebarHintId = value
        },
        startLoading(state) {
            state.loadingCount++
            state.loading = true
        },
        setIsAuthDialog(state, value) {
            state.isAuthDialog = value
        },
        setAuthDialogStartSection(state, value) {
            state.authDialogStartSection = value
        },
        setCurrencies(state, currencies) {
            state.currencies = currencies
        },
        setIsNotifyActive(state, value) {
            state.isNotifyActive = value
        },
        setNotifyText(state, value) {
            state.notifyText = value
        },
        endLoading(state) {
            state.loadingCount--
            if (state.loadingCount <= 0) {
                state.loading = false
            }
        },
        addProject(state, project) {
            if (!project.connectors) {
                project.connectors = []
            }
            state.projects.push(project)
        },
        setProject(state, project) {
            if (!project.connectors) {
                project.connectors = []
            }
            const index = state.projects.findIndex(oldProj => oldProj.id === project.id)
            if (index > -1) {
                state.projects.splice(index, 1, project)
            } else {
                state.projects.push(project)
            }
        },
        addProjects(state, projects) {
            projects.forEach(proj => {
                if (!proj.connectors) {
                    proj.connectors = []
                }
            })
            state.projects = projects
        },
        removeProject(state, projectId) {
            const index = state.projects.findIndex(proj => proj.id === projectId)
            if (index > -1) {
                state.projects.splice(index, 1)
            }
        },
        changeTimezone(state, {timezone, projectId}){
            const project = state.projects.find(proj => proj.id === projectId)
            project.timezone = timezone            
        },
        addConnector(state, connector) {
            const project = state.projects.find(proj => proj.id === connector.projectId)
            project.connectors.push(connector)
        },
        updateConnector(state, connector) {
            const project = state.projects.find(proj => proj.id === connector.projectId)
            const connectors = project.connectors
            let currentConnectorIndex = project.connectors.findIndex(
                con => con.connectorId === connector.connectorId
            )
            connectors.splice(currentConnectorIndex, 1)
            connectors.push(connector)
        },
        updateProject(state, project) {
            const newProjects = [...state.projects]
            const index = newProjects.findIndex(proj => proj.id === project.id)
            newProjects[index] = project
            state.projects = newProjects
        },
        updateConnectorFields(state, { connectorId, fields }) {
            const projectId = connectorId.split('_')[0]
            const project = state.projects.find(proj => proj.id === projectId)
            const connectors = project.connectors
            let currentConnector = connectors.find(con => con.connectorId === connectorId)
            currentConnector = Object.assign(currentConnector, fields)
            let currentConnectorIndex = project.connectors.findIndex(con => con.connectorId === connectorId)
            connectors.splice(currentConnectorIndex, 1)
            connectors.push(currentConnector)
        },
        removeConnector(state, connectorId) {
            const projectId = connectorId.split('_')[0]
            const project = state.projects.find(proj => proj.id === projectId)
            const index = project.connectors.findIndex(con => con.connectorId === connectorId)
            project.connectors.splice(index, 1)
        },
        removeUser(state, { userId, projectId }) {
            const project = state.projects.find(project => project.id === projectId)
            const index = project.users.findIndex(user => user.id === userId)
            project.users.splice(index, 1)
        },
        changeUserRole(state, { userId, projectId, roleId }) {
            const projectCopy = { ...state.projects.find(project => project.id === projectId) }
            const projectIndex = state.projects.findIndex(project => project.id === projectId)
            const role = state.roles.find(role => role.id === roleId)
            const userIndex = projectCopy.users.findIndex(user => user.id === userId)
            projectCopy.users[userIndex].role = role
            state.projects.splice(projectIndex, 1)
            state.projects.push(projectCopy)
        },
        changeUserName(state, { userId, projectId, name }) {
            const projectCopy = { ...state.projects.find(project => project.id === projectId) }
            const projectIndex = state.projects.findIndex(project => project.id === projectId)
            const userIndex = projectCopy.users.findIndex(user => user.id === userId)
            projectCopy.users[userIndex].name = name
            if (projectCopy.owner.id === userId) {
                projectCopy.owner.name = name
            }
            state.projects.splice(projectIndex, 1)
            state.projects.push(projectCopy)
        },
        setCommunicationUpdate(state, value) {
            state.isCommunicationUpdate = value
        }
    },
    getters: {
        error(state) {
            return state.error
        },
        connectorAvatarByType: state => type => {
            return state.connectorsAvatars.find(avatar => avatar.type === type)
        },
        projectsIds: state => {
            return state.projects.map(project => project.id)
        },
        projectsNames: state => {
            return state.projects.map(project => project.projectName)
        },
        projectById: state => id => {
            return state.projects.find(project => project.id == id)
        },
        username: state => (projectId, userId) => {
            return state.projects
                .find(project => project.id === projectId)
                .users.find(user => user.id === userId).name
        },
        timezone: state => (projectId) => {
            console.log(state.projects.find(project => project.id === projectId))
            return state.projects.find(project => project.id === projectId).timezone
        },
        currenciesCodes: state => {
            return state.currencies.map(currency => currency.shortTitle)
        },
        connectorById: state => id => {
            let returnedItem = null
            for (const project of state.projects) {
                try {
                    if (!project.connectors) continue

                    for (const connector of project.connectors) {
                        if (connector.connectorId !== id) continue

                        returnedItem = connector
                        break
                    }
                } catch (e) {
                    console.error(e)
                }
            }

            return returnedItem
        },
        connectorByType: state => ({ projectId, connectorType }) => {
            return state.projects
                .find(el => el.id === projectId)
                ?.connectors?.find(el => el.connectorType === connectorType)
        },
        connectorSettingsImportanceByType: state => type => {
            return state.connectorsSettingsImportance.find(con => con.type === type)
        },
        myUser() {
            const auth = getInstance()
            return auth.user
        },
        connectorsHashMapWithKeysByConnectorsType: state => {
            const hashMap = {}
            state.connectorsList.forEach(el => {
                hashMap[el.type] = el
            })

            return hashMap
        },
        isCommunicationUpdate(state) {
            return state.isCommunicationUpdate
        }
    },
    actions: {
        async setCurrencies({ commit }) {
            const auth = getInstance()
            const token = await auth.getTokenSilently()
            const url = `${cfg.apiEndpoint}/projects/getCurrencies`
            const options = {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }
            const currencies = await (await fetch(url, options)).json()
            commit('setCurrencies', currencies)
        },
        callNotify({ commit }, text) {
            commit('setNotifyText', text)
            commit('setIsNotifyActive', true)
        },
    },
    modules: {
        project: {
            namespaced: true,
            ...project,
        },

        GCB2: {
            namespaced: true,
            ...GCB2,
        },
    },
}
export default new Vuex.Store(store) as Store<RootState>
