var render = function render(){var _vm=this,_c=_vm._self._c;return ((_vm.isProjectPath && !_vm.dataLoading) || _vm.visible)?_c('div',{class:{
        inlineBlock: _vm.inSnackbar,
    }},[_c('v-row',[(
                _vm.project &&
                _vm.project.lastUpdate &&
                _vm.GCB2Inited &&
                !_vm.hideTimestamp &&
                !_vm.inSnackbar &&
                _vm.showUpdated
            )?_c('span',{staticClass:"date-text mr-4"},[_vm._v(" "+_vm._s(`Обновлено: ${new Date(_vm.project.lastUpdate).toLocaleString()} `)+" ")]):_vm._e(),(_vm.spinnerStatus !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.checkRole(_vm.project.id, 'project:communicationUpdate'))?_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"updateButton custom-highlight",class:{ white: _vm.inSnackbar, isError: _vm.color === 'error' },attrs:{"id":"reloadBtn"},on:{"click":_vm.updateProject}},[_c('div',{staticClass:"updateButton__before"},[(_vm.spinnerStatus === 'idleError' || (_vm.color === 'error' && _vm.spinnerStatus !== 'loading'))?_c('v-btn',{attrs:{"icon":"","small":""}},[_c('v-icon',{attrs:{"size":"18px","color":"error"}},[_vm._v("mdi-sync")])],1):(_vm.spinnerStatus === 'idle')?_c('v-btn',{attrs:{"icon":"","small":""}},[_c('v-icon',{attrs:{"size":"18px","color":"main"}},[_vm._v("mdi-sync")])],1):_vm._e()],1),(_vm.spinnerStatus === 'loading')?_c('v-progress-circular',{staticClass:"progressBar",style:({
                                margin: '0 auto;',
                            }),attrs:{"indeterminate":"","color":_vm.color === 'error' ? 'error' : 'main',"size":"16","width":"2"}}):_vm._e(),(_vm.spinnerStatus === 'success')?_c('v-btn',{attrs:{"icon":"","small":""}},[_c('v-icon',{attrs:{"size":"18px","color":"success"}},[_vm._v("mdi-check-circle")])],1):_vm._e(),(_vm.spinnerStatus === 'error')?_c('v-btn',{attrs:{"size":"18px","icon":"","small":""}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-alert-circle")])],1):_vm._e(),_c('div',{staticClass:"updateButton__text"},[_vm._v("ОБНОВЛЕНИЕ РАСЧЕТОВ")])],1)]):_vm._e()]}}],null,false,1509474908)},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.tooltipText))])]):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }