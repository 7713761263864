import { getInstance } from '@/auth/authWrapper'
import cfg from '@root/config'
import { ApiWrapper, Connector } from '../../types/main'
import { getError } from '../../utils'
import { apiCall, http } from '../http'
interface SendMessageParams {
    from: string
    to: string
    text: string
    communicationId: number
    projectId: string
    connectorId: string
}

export async function getQrCode({ projectId, connectorId }: { projectId: string; connectorId: string }) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/whatsapp/signUp`
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            projectId,
            connectorId,
            userId: auth.user.sub,
        }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}

export async function sendMessage(params: SendMessageParams): Promise<ApiWrapper<any | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/whatsapp/sendMessage`
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(params),
    }

    const res = await fetch(url.toString(), options)
    const error = await getError(res)
    const data = null
    return { data, error: error }
}

export async function logout(connectorId: string): Promise<ApiWrapper<any>> {
    const url = `whatsapp/control/logout`
    const res = apiCall<any>(() => http.delete(url.toString(), { searchParams: { connectorId } }))
    return res
}
export async function queueState(connectorId: string): Promise<ApiWrapper<any>> {
    const url = `whatsapp/control/jobs-in-queue`
    const res = apiCall<any>(() => http.get(url.toString(), { searchParams: { connectorId } }))
    return res
}
