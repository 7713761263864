import { getInstance } from '@/auth/authWrapper'
import cfg from '@root/config'
import { ApiWrapper, Connector } from '../../types/main'
import { getError } from '../../utils'

export async function login(connector: Connector, credentials: { token: string; endpoint: string }) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/ipTelephony/megaphone/login`
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            connector,
            credentials,
        }),
    }
    const res = await fetch(url, options)
    if (res.status === 200) {
        return true
    } else {
        return false
    }
}

export async function getUsersList(
    connector: Connector
): Promise<ApiWrapper<{ dhUsers: any[]; megaphoneUsers: any[] } | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/ipTelephony/megaphone/getDhAndMegaphoneUsers`
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            connector,
        }),
    }
    const res = await fetch(url.toString(), options)
    const error = await getError(res)
    const data = await res.json()
    return { data, error: error }
}
export async function makeCall({
    connectorId,
    phone,
    userId,
    userVirtualATC,
    communicationId,
}: {
    connectorId: string
    phone: string
    userId?: string
    userVirtualATC?: string
    communicationId?: string
}): Promise<ApiWrapper<any | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/ipTelephony/megaphone/makeCall`
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            connectorId,
            phone,
            userId: userId ?? auth.user.sub,
            userVirtualATC,
            communicationId,
        }),
    }
    const res = await fetch(url.toString(), options)
    const error = await getError(res)
    const data = await res.json()
    return { data, error: error }
}
export async function updateCallHistory({
    connectorId,
}: {
    connectorId: string
}): Promise<ApiWrapper<any | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/ipTelephony/megaphone/updateHistory`
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            connectorId,
        }),
    }
    const res = await fetch(url.toString(), options)
    const error = await getError(res)
    const data = await res.json()
    return { data, error: error }
}
