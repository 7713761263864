<template>
    <v-text-field
        v-bind="Object.assign(_props, $attrs)"
        v-on="$listeners"
        :style="`width: ${width}`"
        :type="type"
        :prepend-inner-icon="prependInnerIcon"
        :color="color"
        :filled="filled"
        :label="label"
        :value="value"
        :hide-details="hideDetails"
        :rules="rules"
        @input="update"
        :hint="hint"
        :persistent-hint="persistentHint"
    >
        <!--Прокидываем слоты из компонента обертки-->
        <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />
        <template v-slot:message="{ message }">
            <v-row class="ml-1" justify="space-between">
                <span>{{ message }}</span>

                <v-tooltip bottom color="#000000DE" max-width="190px" v-if="tooltipText">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon small class="hint-icon" v-bind="attrs" v-on="on">mdi-help-circle </v-icon>
                    </template>
                    <span class="white--text"> {{ tooltipText }}</span>
                </v-tooltip>
            </v-row>
        </template>
    </v-text-field>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'TextField',
    data: () => ({}),
    //hint - короткая подсказка
    //tooltipText - расширенный техт подсказки
    props: {
        value: {
            type: null,
            default: undefined,
        },
        type: {
            type: String,
            default: undefined,
        },
        width: {
            type: Number,
            default: undefined,
        },
        prependInnerIcon: {
            type: String,
            default: undefined,
        },
        color: {
            type: String,
            default: 'main',
        },
        filled: {
            type: Boolean,
            default: true,
        },
        label: {
            type: String,
            default: undefined,
        },
        rules: {
            type: null, //any
            default: undefined,
        },
        hint: {
            type: String,
            default: undefined,
        },
        persistentHint: {
            type: Boolean,
            default: undefined,
        },
        tooltipText: {
            type: String,
            default: undefined,
        },
        hideDetails: {
            type: Boolean,
            default: false,
        },
    },
    components: {},
    methods: {
        update(newValue) {
            this.$emit('input', newValue)
        },
    },
}
</script>
<style lang='sass' scoped>
@import '@/vars.sass'
.hint-icon
    color: $hint-icon-color
    opacity: 0.38
.hint-icon:hover
    color: black
    cursor: pointer
    opacity: 1
</style>