import { defineStore } from 'pinia'

import _ from 'lodash'
import { PendingConnector } from '../../types/main'
import { deletePendingConnector, getAllPendingConnectors } from '../../api/pendingConnectors'

export const useAdminStore = defineStore('admin', {
    state: () => ({
        pendingConnectors: [] as Array<PendingConnector>,
    }),
    actions: {
        setPendingConnectors(pendingConnectors: Array<PendingConnector>) {
            this.pendingConnectors = pendingConnectors
        },
        setOnePendingConnector(pendingConnector: PendingConnector) {
            const existsAtIndex = this.pendingConnectors.findIndex(el => el.id === pendingConnector.id)
            if (existsAtIndex !== -1) {
                this.pendingConnectors[existsAtIndex] = pendingConnector
            } else {
                this.pendingConnectors.push(pendingConnector)
            }
            this.pendingConnectors = [...this.pendingConnectors]
        },
        setPendingConnectorStage({ id, stage }: any) {
            const pendingConnectorToChange = this.pendingConnectors.find(el => el.id === id)
            if (!pendingConnectorToChange) {
                return
            }
            pendingConnectorToChange.stage = stage
            this.pendingConnectors = [
                ...this.pendingConnectors.filter(el => el.id !== pendingConnectorToChange.id),
                pendingConnectorToChange as PendingConnector,
            ]
        },
        async deletePendingConnector(id: number): Promise<void> {
            const { data, error } = await deletePendingConnector(id)
            if (!error) {
                this.pendingConnectors = this.pendingConnectors.filter(el => el.id !== id)
            }
        },
        async fetchPendingConnectors() {
            const pendingConnectors: Array<PendingConnector> = await getAllPendingConnectors()
            this.setPendingConnectors(pendingConnectors)
        },
    },
    getters: {
        getPendingConnectorById() {
            return (id: number): PendingConnector | undefined => {
                return this.pendingConnectors.find(el => el.id === id)
            }
        },
    },
})
