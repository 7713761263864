import Vue from 'vue'
import { Integrations } from '@sentry/tracing'
import { Integration, Event, EventHint, SamplingContext, Options } from '@sentry/types'
import config from '../../config'

const EVENT_RATE_LIMIT = config.buildType === 'PRODUCTION' ? 0.05 : -1
const TRACES_SAMPLE_RATE = config.buildType === 'PRODUCTION' ? 0.005 : -1

const passRateLimit = (rate: number) => Math.random() < rate
export default {
    Vue,
    dsn: 'https://69e01064dda34cfabfd87ad60f761fc3@o522194.ingest.sentry.io/5633317',
    integrations: [new Integrations.BrowserTracing()],

    beforeSend: (event: Event, hint: EventHint) => {
        console.error(hint.originalException || hint.syntheticException)
        if (event?.exception?.values?.some(err => err.type === 'ImportantError')) {
            return event
        }

        return passRateLimit(EVENT_RATE_LIMIT) ? event : null
    },
    tracesSampleRate: TRACES_SAMPLE_RATE,
} as Options
