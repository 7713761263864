import Vue, { getCurrentInstance, reactive, toRefs, watch } from 'vue'
import { Route } from 'vue-router'
type State = {
    route: Route
}

const useRouter = (_vm?: Vue) => {
    const vm = _vm ?? getCurrentInstance()!.proxy
    const state = reactive<State>({
        route: vm.$route,
    })

    watch(
        () => vm.$route,
        r => {
            state.route = r as any
        }
    )

    return { ...toRefs(state), router: vm.$router }
}

export default useRouter
