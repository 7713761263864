
import { computed, ComputedRef, defineComponent, reactive, ref, watch } from 'vue'
import { useNotificationStore } from '@/store/stores/notification'
import _ from 'lodash'

export default defineComponent({
    props: {
        readedNotifications: {
            type: Array,
            required: true 
        },
        default: () => [],
    },
    setup(props, context) {
        const notificationStore = useNotificationStore()
        const currentNotificationIndex = ref(0)
        const maxNoticationIndex = computed(() =>
            props.readedNotifications!.length
                ? props.readedNotifications!.length - 1
                : 0
        )
        const increaseCurrentNotificationIndex = () => {
            if (currentNotificationIndex.value === maxNoticationIndex.value) return
            currentNotificationIndex.value++
        }
        const decreaseCurrentNotificationIndex = () => {
            if (currentNotificationIndex.value === 0) return
            currentNotificationIndex.value--
        }
        const unread = () => {
            notificationStore.unReadreadNotification(currentNotification.value?.id)
        }
        const currentNotification = computed<any>(
            () => props.readedNotifications?.[currentNotificationIndex.value]
        )
        const text = computed(() =>
            currentNotification.value ? currentNotification.value?.text.replace(/<\/?.+?>/gi, '') : ''
        )
        return {
            notificationStore,
            currentNotification,
            currentNotificationIndex,
            increaseCurrentNotificationIndex,
            decreaseCurrentNotificationIndex,
            unread,
            text,
        }
    },
})
