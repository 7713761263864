import { ApiWrapper } from '../types/main'
import {
    CurrentTariff,
    IntegrationInstallPayload,
    MarketplaceInstallResult,
    MarketplaceIntegration,
    MarketplaceIntegrationFeedback, TariffData,
} from '../types/marketplace'
import { apiCall, http } from './http'

export interface SolvePaymentParams {
    projectId: string
    userId: string
    marketplaceIntegrationId: string
    subscriptionPeriod: string
}
export interface CreateFeedbackParams {
    projectId: string
    integrationId: string
    rating: number
    comment: string
}
export interface InstallIntegrationParams {
    projectId: string
    integrationId: string
    installPayload?: IntegrationInstallPayload
    subscriptionPeriod?: string
    paymentPayloadId?: number
}
export interface GetIntegrationActivationListParams {
    projectId: string
    integrationId: string
}
export interface GetAutomailSettings {
    projectId: string
    integrationId: string
}
export interface UnsubscribeIntegrationParams {
    projectId: string
    marketplaceIntegrationId: string
}
export interface UnsubscribeIntegrationParams {
    projectId: string
    marketplaceIntegrationId: string
}

export async function getAll(projectId: string): Promise<ApiWrapper<MarketplaceIntegration[]>> {
    const url = `marketplace/integration/all`
    const res = apiCall<any>(() => http.get(url.toString(), { searchParams: { projectId } }))
    return res
}
export async function getFeedback(integrationId: string) {
    const url = `marketplace/integration/feedback`
    const res = apiCall<MarketplaceIntegrationFeedback[]>(() =>
        http.get(url.toString(), { searchParams: { integrationId } })
    )
    return res
}
export async function createFeedback(params: CreateFeedbackParams) {
    const url = `marketplace/integration/feedback`
    const res = apiCall<MarketplaceIntegrationFeedback>(() => http.post(url.toString(), { json: params }))
    return res
}
export async function installIntegration(params: InstallIntegrationParams) {
    const url = `marketplace/integration/install`
    const res = apiCall<MarketplaceInstallResult>(() =>
        http.post(url.toString(), {
            json: {
                paymentPayloadId: params.paymentPayloadId,
                projectId: params.projectId,
                integrationId: params.integrationId,
                subscriptionPeriod: params.subscriptionPeriod,
                installPayload: params.installPayload,
            },
        })
    )
    return res
}
export async function uninstallIntegration(params: InstallIntegrationParams) {
    const url = `marketplace/integration/uninstall`
    const res = apiCall<MarketplaceIntegrationFeedback>(() => http.post(url.toString(), { json: params }))
    return res
}
export async function getIntegrationActivationList(params: GetIntegrationActivationListParams) {
    const url = `marketplace/integration/activationList`
    const res = apiCall<any[]>(() =>
        http.get(url.toString(), {
            searchParams: { integrationId: params.integrationId, projectId: params.projectId },
        })
    )
    return res
}
export async function getAutomailSettings(params: GetAutomailSettings) {
    const url = `marketplace/integration/automailSettings`
    const res = apiCall<any[]>(() =>
        http.get(url.toString(), {
            searchParams: { integrationId: params.integrationId, projectId: params.projectId },
        })
    )
    return res
}
export async function getCurrentTariff(projectId: string) {
    const url = `marketplace/billing/currentTariff`
    const res = apiCall<{ currentTariff: CurrentTariff; isTrialActivatedBefore: boolean }>(() =>
        http.get(url.toString(), {
            searchParams: { projectId },
        })
    )
    return res
}
export async function getAllTariffs(projectId: string) {
    const url = `marketplace/billing/tariffs`
    const res = apiCall<TariffData>(() =>
        http.get(url.toString(), {
            searchParams: { projectId },
        })
    )
    return res
}
export async function solvePayment(params: SolvePaymentParams) {
    const url = `marketplace/billing/solvePayment`
    const res = apiCall<MarketplaceIntegration[]>(() =>
        http.get(url.toString(), {
            searchParams: {
                projectId: params.projectId,
                userId: params.userId,
                marketplaceIntegrationId: params.marketplaceIntegrationId,
                subscriptionPeriod: params.subscriptionPeriod,
            },
        })
    )
    return res
}
export async function unsubscribeIntegration(params: UnsubscribeIntegrationParams) {
    const url = `marketplace/billing/unsubscribeIntegration`
    const res = apiCall<any>(() => http.post(url.toString(), { json: params }))
    return res
}

export async function activatePromocode(params: {projectId: string, promocode: string}) {
    const url = `marketplace/billing/activatePromocode`
    const res = apiCall<any>(() => http.post(url.toString(), { json: params }))
    return res
}
