import Vue from 'vue'
import { getCurrentInstance } from 'vue'
import _ from 'lodash'
import moment from 'moment'

export function randomAvatar() {
    function generateRandomLetter() {
        const alphabet = 'abcdefghijklmnopqrstuvwxyz'
        return alphabet[Math.floor(Math.random() * alphabet.length)]
    }
    return `https://eu.ui-avatars.com/api/?background=random&name=${generateRandomLetter()}+${generateRandomLetter()}`
}
export function genPartOfId() {
    return Math.random()
        .toString(36)
        .substr(2, 3)
}
export async function getError(res) {
    let error = null
    const _res = res.clone()
    try {
        if (_res.status > 300) {
            const parsedRes = await _res.json()
            error = parsedRes.error || parsedRes.message || `${_res.status} ${_res.statusText}`
        }
    } catch (err) {
        error = `${_res.status} ${_res.statusText}`
    }
    return error
}
export function objectEquals(x, y) {
    if (x === y) return true
    // if both x and y are null or undefined and exactly the same

    if (!(x instanceof Object) || !(y instanceof Object)) return false
    // if they are not strictly equal, they both need to be Objects

    if (x.constructor !== y.constructor) return false
    // they must have the exact same prototype chain, the closest we can do is
    // test there constructor.

    for (var p in x) {
        if (!x.hasOwnProperty(p)) continue
        // other properties were tested using x.constructor === y.constructor

        if (!y.hasOwnProperty(p)) return false
        // allows to compare x[ p ] and y[ p ] when set to undefined

        if (x[p] === y[p]) continue
        // if they have the same strict value or identity then they are equal

        if (typeof x[p] !== 'object') return false
        // Numbers, Strings, Functions, Booleans must be strictly equal

        if (!objectEquals(x[p], y[p])) return false
        // Objects and Arrays must be tested recursively
    }

    for (p in y) if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false
    // allows x[ p ] to be set to undefined

    return true
}
export function checkAccess(projectId, requiredRoleId) {
    const userId = this.$store.getters.myUser.sub
    const projects = this.$store.state.projects
    if (projects.length === 0) {
        return false
    }
    const project = projects.find(proj => proj.id === projectId)
    if (!project) {
        return false
    }
    const roleIdInProject = project.myRole
    const rolesObj = this.$store.state.rolesObject
    if (rolesObj[roleIdInProject].power >= rolesObj[requiredRoleId].power) {
        return true
    } else {
        return false
    }
}
export function groupBy(xs, key) {
    return xs.reduce(function(rv, x) {
        ;(rv[x[key]] = rv[x[key]] || []).push(x)
        return rv
    }, {})
}
export function capitalize(s) {
    return s[0].toUpperCase() + s.slice(1)
}
export function openInNewTab(url) {
    const win = window.open(url, '_blank')
    win.focus()
}

export function openInCurrentTab(url) {
    window.open(url, '_self')
}
export function defineFavicon() {
    const matcher = window.matchMedia('(prefers-color-scheme: dark)')
    matcher.addListener(onUpdate)
    const lightSchemeIcon = document.querySelector('#light-scheme-icon')
    const darkSchemeIcon = document.querySelector('#dark-scheme-icon')
    onUpdate()
    function onUpdate() {
        if (matcher.matches) {
            if (lightSchemeIcon) lightSchemeIcon.remove()
            document.head.append(darkSchemeIcon)
        } else {
            document.head.append(lightSchemeIcon)
            if (darkSchemeIcon) darkSchemeIcon.remove()
        }
    }
}
export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
}
/**
 * @example
 * declOfNum(1, ['минута', 'минуты', 'минут']); // вернёт — минута
 * declOfNum(2, ['минута', 'минуты', 'минут']); // вернёт — минуты
 * declOfNum(5, ['минута', 'минуты', 'минут']); // вернёт — минут
 */
export function declOfNum(number, words) {
    return words[
        number % 100 > 4 && number % 100 < 20 ? 2 : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? number % 10 : 5]
    ]
}
export function maskPhone(phone) {
    if (phone[0] === '+') {
        return (
            phone.substr(0, 2) +
            ' ' +
            phone.substr(2, 3) +
            ' ' +
            phone.substr(5, 3) +
            '-' +
            phone.substr(8, 2) +
            '-' +
            phone.substr(10, 2)
        )
    }
    if (phone[0] === '7') {
        return (
            '+' +
            phone.substr(0, 1) +
            ' ' +
            phone.substr(1, 3) +
            ' ' +
            phone.substr(4, 3) +
            '-' +
            phone.substr(7, 2) +
            '-' +
            phone.substr(9, 2)
        )
    }
    if (phone[0] === '8') {
        return (
            '+7' +
            ' ' +
            phone.substr(1, 3) +
            ' ' +
            phone.substr(4, 3) +
            '-' +
            phone.substr(7, 2) +
            '-' +
            phone.substr(9, 2)
        )
    }
}

export function maskPhonePlain(phone) {
    if (phone[0] === '+') {
        return (
            phone.slice(0, 2) +
            phone.slice(2, 5) +
            phone.slice(5, 8) +
            phone.slice(8, 10) +
            phone.slice(10)
        )
    }
    if (phone[0] === '7') {
        return (
            '+' +
            phone.slice(0, 1) +
            phone.slice(1, 4) +
            phone.slice(4, 7) +
            phone.slice(7, 9) +
            phone.slice(9)
        )
    }
    if (phone[0] === '8') {
        return '+7' + phone.slice(1, 4) + phone.slice(4, 7) + phone.slice(7, 9) + phone.slice(9)
    }
}
export function getRandomInt(min, max) {
    return min + Math.floor(Math.random() * (max - min + 1))
}
export function replaceElementAtIndex(array, index, element) {
    const arrayCopy = [...array]
    arrayCopy[index] = element
    return arrayCopy
}
export function reloadPageOnIdle(idleMinutes) {
    let idleTime = 0
    const idleInterval = setInterval(timerIncrement, 60000) // 1 minute
    document.addEventListener('mousemove', () => {
        idleTime = 0
    })
    document.addEventListener('keypress', () => {
        idleTime = 0
    })
    function timerIncrement() {
        idleTime = idleTime + 1
        if (idleTime >= idleMinutes) {
            window.location.reload()
        }
    }
}
export function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
}
/**
 * Приводит число к формату "1 000 000"
 */
export function numberWithSpaces(x) {
    return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : null
}
/**
 * Используется для числовой сортировки строк в формате "1 000 000"
 */
export function numSort(a, b) {
    const aNum = a ? Number(a.toString().replace(/[^0-9.]/g, '')) : 0
    const bNum = b ? Number(b.toString().replace(/[^0-9.]/g, '')) : 0
    return aNum - bNum
}
export function floatNumSort(a, b) {
    const aNum = a
        ? Number(
              a
                  .toString()
                  .replace(/[^0-9.,]/g, '')
                  .replace(/[,]/g, '.')
          )
        : 0
    const bNum = b
        ? Number(
              b
                  .toString()
                  .replace(/[^0-9.,]/g, '')
                  .replace(/[,]/g, '.')
          )
        : 0
    return aNum - bNum
}
export function alphabetSort(a, b) {
    return a.localeCompare(b, 'ru')
}
export function isNumber(value) {
    return typeof value === 'number' && isFinite(value)
}
export function isNumeric(str) {
    if (typeof str != 'string') return false
    return !isNaN(str) && !isNaN(parseFloat(str))
}
export function isBlank(value) {
    return (_.isEmpty(value) && !_.isNumber(value) && !_.isBoolean(value)) || _.isNaN(value)
}
export function toStringOrNull(val) {
    return _.isNil(val) ? null : String(val)
}

export function checkInputIsNumber(event) {
    if (!/\d/.test(event.key) && event.key !== '.' && event.key !== '-') return event.preventDefault()
}
export function checkInputIsIntNumber(event) {
    if (!/\d/.test(event.key)) return event.preventDefault()
}
export function checkInputIsIntNumberWithMinus(event) {
    if (!/\d/.test(event.key) && event.key !== '-') return event.preventDefault()
}
export function mod(n, m) {
    return ((n % m) + m) % m
}
export function getMinDate(dates) {
    return moment.min(dates.map(d => moment(d)))
}
export function getMaxDate(dates) {
    return moment.max(dates.map(d => moment(d)))
}
export function retry(fn, maxRetries, interval) {
    return fn().catch(async err => {
        if (maxRetries <= 0) {
            throw err
        }
        await sleep(interval)
        return retry(fn, maxRetries - 1, interval)
    })
}
export const toPercent = val => {
    const result =
        val === '' || val === null
            ? ''
            : +parseFloat(Number(parseFloat(val).toString()) * 100).toFixed(2) + '%'
    return result
}
export const fromPercent = val => {
    const result = val === '' ? '' : parseFloat(parseFloat(val.replace('%', '')) / 100).toString()
    return result
}
export const mapFilterToObjectFormat = (filter, list, options) => {
    const selectedSet = new Set(filter)

    return list.reduce((acc, v) => {
        return Object.assign(acc, {
            [v.id]: {
                option: v.id,
                selected: selectedSet.has(v.id),
            },
        })
    }, {})
}
export const mapFilterFromObjectFormat = filterInObjectFormat => {
    const selectedIds = []
    for (const id in filterInObjectFormat) {
        if (filterInObjectFormat[id].selected) selectedIds.push(id)
    }
    return selectedIds
}
export const getFilterListFromObjectFormat = filterInObjectFormat => {
    const filterList = []
    for (const id in filterInObjectFormat) {
        filterList.push({ id, name: filterInObjectFormat[id].option })
    }
    return filterList
}
export const minutesToMs = minutes => minutes * 60 * 1000
export const downloadFile = (url, name) => {
    const link = document.createElement('a')
    link.href = url
    link.download = name
    link.click()
}

export const camelize = (obj) => {
    if (Array.isArray(obj)) {
        return obj.map(item => camelize(item));
    } else if (obj !== null && obj.constructor === Object) {
        return Object.keys(obj).reduce((acc, key) => {
            const camelCaseKey = key.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
            acc[camelCaseKey] = camelize(obj[key]);
            return acc;
        }, {});
    }
    return obj;
}
