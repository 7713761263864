import { ApiWrapper } from '../types/main'
import { ScheduledReport, ScheduledReportTemplate, ScheduledReportData } from '../types/scheduledReport'
import { apiCall, http } from './http'

export async function getOneById({
    id,
    projectId,
}: {
    id: number
    projectId: string
}): Promise<ApiWrapper<ScheduledReport>> {
    const url = `scheduledReport?projectId=${projectId}&id=${id}`
    const res = apiCall<any>(() => http.get(url.toString()))
    return res
}
export async function getManyByProject(projectId: string): Promise<ApiWrapper<ScheduledReport[]>> {
    const url = `scheduledReport/manyByProject?projectId=${projectId}`
    const res = apiCall<any>(() => http.get(url.toString()))
    return res
}
export async function getTemplates(projectId: string): Promise<ApiWrapper<ScheduledReportTemplate[]>> {
    const url = `scheduledReport/template/manyByProject?projectId=${projectId}`
    const res = apiCall<any>(() => http.get(url.toString()))
    return res
}
export async function remove({
    id,
    projectId,
}: {
    id: number
    projectId: string
}): Promise<ApiWrapper<any | null>> {
    const url = `scheduledReport?projectId=${projectId}&id=${id}`
    const res = apiCall<any>(() => http.delete(url.toString()))
    return res
}
export async function create(data: ScheduledReportData): Promise<ApiWrapper<any | null>> {
    const url = `scheduledReport`
    const res = apiCall<any>(() => http.post(url.toString(), { json: data }))
    return res
}
export async function update(id: string, data: ScheduledReportData): Promise<ApiWrapper<any | null>> {
    const url = `scheduledReport`
    const res = apiCall<any>(() => http.put(url.toString(), { json: { id, ...data } }))
    return res
}
export async function getWaChats(): Promise<ApiWrapper<{ id: string; name: string }[] | null>> {
    const url = `scheduledReport/waChats`
    const res = apiCall<any>(() => http.get(url.toString()))
    return res
}
export async function getTgChats(): Promise<ApiWrapper<{ id: string; name: string; type: string }[] | null>> {
    const url = `scheduledReport/tgChats`
    const res = apiCall<any>(() => http.get(url.toString()))
    return res
}
