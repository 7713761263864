<template>
    <div class="content-wrapper">
        <div class="content">
            <v-row class="ma-0">
                <router-link class="mx-3 my-2" to="/admin/pendingConnectors"
                    >Подключение коннекторов</router-link
                >
            </v-row>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AdminHome',
    components: {},
    methods: {},
    created() {},
}
</script>
<style scoped>
</style>
