
import { computed, defineComponent, getCurrentInstance, ref, watch } from 'vue'
import Breadcrumbs from '../../../components/Breadcrumbs.vue'
import Dashboard from './Dashboard.vue'
import SettingsList from './SettingsList.vue'
import useRouter from '../../../hooks/useRouter'
export default defineComponent({
    components: { Breadcrumbs },
    props: {},
    setup(props, { emit }) {
        const root = getCurrentInstance()!.proxy
        const { route, router } = useRouter(root)
        const project = computed(() => root.$store.getters.projectById(route.value.params.id))
        const activeTab = ref()
        const breadcrumbs = computed(() => [
            {
                text: 'ВАШИ ПРОЕКТЫ',
                to: '/projects',
            },
            {
                text: project.value.name,
                to: `/project/${project.value.id}`,
            },
            {
                text: 'Отчет по среднему чеку',
                to: '',
                disabled: true,
            },
        ])
        const tabs = computed(() => [
            {
                tabName: 'РАСЧЁТ',
                sectionName: 'dashboard',
                component: Dashboard,
            },
            {
                tabName: 'СХЕМЫ РАСЧЁТА',
                sectionName: 'settings',
                component: SettingsList,
            },
        ])
        watch(activeTab, val => {
            const sectionName = tabs.value[val].sectionName
            router.push({
                path: route.value.path,
                query: { ...route.value.query, ...{ section: sectionName } } as any,
            })
        })
        const onCreated = () => {
            if (route.value.query.section) {
                activeTab.value = tabs.value.findIndex(el => el.sectionName === route.value.query.section)
            }
        }
        onCreated()
        return { breadcrumbs, tabs, activeTab }
    },
})
