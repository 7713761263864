<template>
    <div>
        <h1>Настройки источника «yClients»</h1>
        <p>Настройки источника могут изменять пользователи с правами «Владелец» и «Редактор».</p>
        <h2>Филиалы</h2>
        <p>
            Если в yClients у вас несколько филиалов, то вы можете выбрать один или несколько филиалов для
            загрузки данных.
        </p>
        <p>
            <strong>Важно!</strong> Изменение этих настроек приведёт к удалению информации о взаимодействии с
            клиентами. Крайне не рекомендуется изменять эти настройки, если вы уже пользуетесь отчетами в
            Системе.
        </p>
    </div>
</template>

<script>
//hintId  <project>
export default {
    name: 'YcSettingsHint',
    computed: {},
    components: {},
    data: () => ({}),
    methods: {},
    mounted() {},
}
</script>



<style lang="sass" scoped>
@import '@/vars.sass'
p
    font-size: 14px
</style>
