
import { computed, defineComponent, getCurrentInstance, onMounted, onUnmounted, reactive, ref, watch } from 'vue'
import { useNotificationStore } from '@/store/stores/notification'
import HighSeverityNotificationDialog from './notification/HighSeverityNotificationDialog.vue'
import DangeourBIllingTariffDialog from './notification/DangeourBIllingTariffDialog.vue'
import NotificationBar from './notification/NotificationBar.vue'
import websocket from '../websockets'
export default defineComponent({
    components: { HighSeverityNotificationDialog, NotificationBar, DangeourBIllingTariffDialog },
    setup(props, context) {
        const notificationsLoading = ref(true)
        const notificationStore = useNotificationStore()
        const root = getCurrentInstance()!.proxy
        notificationStore.fetchNotifications().then(_ => {
            notificationsLoading.value = false
        })

        const fetchNotificationInterval = ref<any>()
        const readedNotifications = computed(() => {
            const notificationsList = notificationStore.notificationsList
            const dangerousNotifications = notificationsList.filter(
                el => el.severity.includes('dangerous') && el.readed && root.$store.getters.projectById(el.projectId)
            )
            const highNotifications = notificationsList.filter(
                el => el.severity.includes('high') && el.readed
            )

            return dangerousNotifications.concat(highNotifications)
        })
        const unreadedHighSeverityNotifications = computed(() => {
            return notificationStore.highSeverityNotifications.filter(el => !el.readed)
        })
        const unreadedDangeourNotifications = computed(() => {
            return notificationStore.dangeourSeverityNotifications.filter(el => !el.readed && el?.projectId)
        })
        const dialogIsActive = computed(() => {
            return unreadedHighSeverityNotifications.value.length
        })

        onMounted(() => {
            websocket.socket.on('NOTIFICATION_CREATED', payload => {
                notificationStore.addNotification(payload)
            })
            websocket.socket.on('NOTIFICATION_UPDATED', payload => {
                notificationStore.updateNotification(payload)
            })
            websocket.socket.on('NOTIFICATION_DELETED', payload => {
                notificationStore.deleteNotification(payload)
            })
            fetchNotificationInterval.value = setInterval(
                async () => {
                    notificationsLoading.value = true
                    notificationStore.fetchNotifications().then(_ => {
                        notificationsLoading.value = false
                    })
                },
                window.location.href.includes('dataheroes.pro') ? 1000 * 60 * 60 : 10000
            )
        })
        onUnmounted(() => {
            websocket.socket.off('NOTIFICATION_CREATED')
            websocket.socket.off('NOTIFICATION_UPDATED')
            websocket.socket.off('NOTIFICATION_DELETED')
            clearInterval(fetchNotificationInterval.value)
        })

        return {
            unreadedDangeourNotifications,
            notificationsLoading,
            notificationStore,
            readedNotifications,
            unreadedHighSeverityNotifications,
            dialogIsActive,
        }
    },
})
