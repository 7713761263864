
import { computed, defineComponent, ref, reactive, onMounted, getCurrentInstance } from 'vue'
import moment from 'moment'
import BaseInputOutlined from '@/components/inputs/BaseInputOutlined.vue'
import { useDateMenu } from '@/hooks/useDateMenu'
import api from '../../../api/api'
import { useProject } from '../../../hooks/useProject'
import { AvgCheckReportSetting } from '../../../types/avgCheckReport'
import CustomActivatorSelect from '../../../components/inputs/CustomActivatorSelect.vue'
import SpinnerLoader from '../../../components/SpinnerLoader.vue'

import * as _ from 'lodash'
import { toPercent } from '../../../utils'
const HEADER_WIDTH = '120px'
export default defineComponent({
    components: { BaseInputOutlined, CustomActivatorSelect, SpinnerLoader },
    props: {},
    setup(props, { emit }) {
        const root = getCurrentInstance()!.proxy
        const loading = ref(false)
        const settingsSchemas = ref<AvgCheckReportSetting[]>([])
        const reportData = ref<any[]>([])
        const selectedSettingSchema = ref<string>()
        const { project } = useProject()
        const dateMenu = reactive(
            useDateMenu({
                dateFormat: 'DD.MM.YYYY',
                defaultStartDate: moment().subtract(1, 'year'),
                defaultEndDate: moment(),
            })
        )
        const initData = async () => {
            loading.value = true
            const { data, error } = await api.avgCheckReport.getReportData({
                projectId: project.value.id,
                filters: {
                    dateFrom: dateMenu.startDate,
                    dateTo: dateMenu.endDate,
                    schemaId: selectedSettingSchema.value!,
                },
            })
            if (error) {
                root.$store.dispatch('callNotify', 'Ошибка при загрузке данных')
            }
            reportData.value = data ?? []
            loading.value = false
        }

        const getPerformanceIcon = (performance: 'low' | 'medium' | 'high') => {
            if (performance === 'high') return '$greenGradientCheckmark'
            if (performance === 'medium') return '$yellowGradientWarning'
            if (performance === 'low') return '$redGradientLightning'
        }
        const formatNumber = (num: number) =>
            new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(num)
        const tableData = computed(() => {
            const d = reportData.value
            const headers = [
                { text: 'Показатель', value: 'horizontalHeader', sortable: false, width: HEADER_WIDTH },
            ].concat(
                d.map((el: any) => ({
                    text: el.staffName,
                    value: `staff-${el.id}`,
                    sortable: false,
                    width: HEADER_WIDTH,
                }))
            )
            const items: any[] = []
            const topStaticHeaders = [
                { name: 'Записи', key: 'recordsCount' },
                { name: 'Средний чек', key: 'avgTotal' },
                { name: 'Средний чек (осн. услуги)', key: 'avgBasic' },
                { name: 'Надбавка (доп. и прем. услуги)', key: 'avgExtra' },
            ]
            for (const header of topStaticHeaders) {
                items.push({
                    horizontalHeader: header.name,
                    ...d.reduce(
                        (acc, v) =>
                            Object.assign({}, acc, {
                                [`staff-${v.id}`]: { type: 'static', ...v[header.key] } ?? 0,
                            }),
                        {}
                    ),
                })
            }
            const services = _.uniqBy(
                d.flatMap(el => el.services),
                'id'
            )
            for (const service of services) {
                items.push({
                    horizontalHeader: service.title,
                    ...d.reduce(
                        (acc, v) =>
                            Object.assign(acc, {
                                [`staff-${v.id}`]: {
                                    type: 'service',
                                    ...v.services.find((el: any) => el.id === service.id),
                                },
                            }),
                        {}
                    ),
                })
            }
            const bottomStaticHeaders = [
                { name: 'ЗП доп', key: 'priceExtra' },
                { name: 'ЗП товары', key: 'priceProduct' },
                { name: 'ЗП доп + товары', key: 'priceExtraProduct' },
            ]
            for (const header of bottomStaticHeaders) {
                items.push({
                    horizontalHeader: header.name,
                    ...d.reduce(
                        (acc, v) =>
                            Object.assign({}, acc, {
                                [`staff-${v.id}`]: { type: 'static', ...v[header.key] } ?? 0,
                            }),
                        {}
                    ),
                })
            }
            console.log('items', items)
            return { headers, items }
        })

        onMounted(async () => {
            loading.value = true
            settingsSchemas.value = await api.avgCheckReport
                .getSettings(project.value.id, false)
                .then(res => res.data)
            selectedSettingSchema.value = settingsSchemas.value.find(el => el.isDefault)?.id
            await initData()
            loading.value = false
        })
        return {
            dateMenu,
            selectedSettingSchema,
            settingsSchemas,
            loading,
            initData,
            tableData,
            toPercent,
            formatNumber,
            getPerformanceIcon,
        }
    },
})
