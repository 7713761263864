import { io, Socket } from 'socket.io-client'
import config from '../config'
const url = config.wsUrl
const websocket = {
    socket: {} as Socket,
    connect: function(token: string) {
        const socket = io(url, {
            transports: ['websocket'],
            auth: { token },
        })
        this.socket = socket
    },
}
export default websocket
