<template>
    <div>
        <h1>Получение ключа разработчика</h1>
        <p>Для получения <strong>Ключа разработчика</strong>:</p>
        <ol>
            <li>
                <p>
                    Откройте в вашем yClients раздел «Обзор» — «Сводка». Запомните
                    <strong>номер филиала</strong> из раздела «Идентификатор филиала (ID)».
                </p>
            </li>
            <li>
                <p>
                    Напишите письмо на адрес
                    <a href="mailto:support@yclients.com" target="_blank" class="url">support@yclients.com</a>
                    со следующим текстом:
                </p>
                <blockquote>
                    <p>Просьба предоставить ключ доступа для <strong>&lt;номер филиала&gt;</strong></p>
                    <p>Контактное лицо: info@dataheroes.pro, +7 985 151-57-26, Андрей</p>
                    <p>
                        Для обмена данными с программами для автоматизации и учета (формирование аналитики).
                    </p>
                </blockquote>
                <p>
                    <strong><u>Важно!</u></strong> Письмо должно быть с той почты, на которую зарегистрирован
                    ваш yClients
                </p>
            </li>
            <li>
                <p>
                    В ответном письме будет указан <strong>Ключ разработчика</strong>. Служба технической
                    поддержки может отвечать в течение трех суток, но обычно отвечают быстрее.
                </p>
            </li>
        </ol>
    </div>
</template>

<script>
//hintId  <project>
export default {
    name: 'YcAuthKeyHint',
    computed: {},
    components: {},
    data: () => ({}),
    methods: {},
    mounted() {},
}
</script>



<style lang="sass" scoped>
@import '@/vars.sass'
p
    font-size: 14px
</style>
