<template>
    <v-select
        v-on="$listeners"
        v-bind="Object.assign(_props, $attrs)"
        :items="items"
        :item-text="itemText"
        :item-value="itemValue"
        :multiple="multiple"
        :return-object="returnObject"
        :placeholder="placeholder"
        :filled="filled"
        :color="color"
        :hint="hint"
        :persistent-hint="persistentHint"
        :label="label"
        :rules="rules"
        :value="value"
        @input="update"
    >
        <template slot="selection" slot-scope="data">
            <span v-if="data.index === 0 && (typeof itemText === 'string' || !itemText)">
                <span v-if="itemValue">{{
                    selectionMaxLength > String(data.item[itemText]).length
                        ? String(data.item[itemText])
                        : `${String(data.item[itemText]).slice(0, selectionMaxLength)}...`
                }}</span>
                <span v-else>{{
                    selectionMaxLength > String(data.item).length
                        ? String(data.item)
                        : `${String(data.item).slice(0, selectionMaxLength)}...`
                }}</span>
            </span>
            <span v-if="data.index === 0 && typeof itemText === 'function' && displayedValue">
                <span v-if="itemValue">{{
                    selectionMaxLength > data.item[displayedValue].length
                        ? data.item[displayedValue]
                        : `${data.item[displayedValue].slice(0, selectionMaxLength)}...`
                }}</span>
                <span v-else>{{
                    selectionMaxLength > data.item.length
                        ? data.item
                        : `${data.item.slice(0, selectionMaxLength)}...`
                }}</span>
            </span>
            <span v-if="data.index === 1" class="grey--text caption"> (+{{ value.length - 1 }} ) </span>
        </template>
        <template v-slot:message="{ message }">
            <v-row class="ml-1" justify="space-between">
                <span>{{ message }}</span>
                <v-tooltip bottom color="#000000DE" max-width="190px" v-if="tooltipText">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon small class="hint-icon" v-bind="attrs" v-on="on">mdi-help-circle </v-icon>
                    </template>
                    <span class="white--text"> {{ tooltipText }}</span>
                </v-tooltip>
            </v-row>
        </template>
    </v-select>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'Select',
    data: () => ({}),
    //hint - короткая подсказка
    //tooltipText - расширенный техт подсказки
    props: {
        value: {
            type: null,
            default: undefined,
        },
        selectionMaxLength: {
            type: Number,
            default: 11,
        },
        itemText: {
            type: [String, Function],
            default: undefined,
        },
        //Необходимое свойство, если itemText - функция,
        displayedValue: {
            type: String,
            default: undefined,
        },
        itemValue: {
            type: String,
            default: undefined,
        },
        multiple: {
            type: Boolean,
            default: undefined,
        },
        returnObject: {
            type: Boolean,
            default: undefined,
        },
        placeholder: {
            type: String,
            default: undefined,
        },
        filled: {
            type: Boolean,
            default: true,
        },
        color: {
            type: String,
            default: 'main',
        },
        hint: {
            type: String,
            default: undefined,
        },
        persistentHint: {
            type: Boolean,
            default: undefined,
        },
        label: {
            type: String,
            default: undefined,
        },
        rules: {
            type: null, //any
            default: undefined,
        },
        items: {
            type: Array,
            default: () => [],
        },
        tooltipText: {
            type: String,
            default: undefined,
        },
    },
    components: {},
    methods: {
        update(newValue) {
            this.$emit('input', newValue)
        },
    },
    created() {},
    mounted() {},
}
</script>
<style lang="sass" scoped>
@import '@/vars.sass'
.hint-icon
    color: $hint-icon-color
    opacity: 0.38
.hint-icon:hover
    color: black
    cursor: pointer
    opacity: 1
::v-deep .v-input__slot
    padding-left: 8px !important
    padding-right: 8px !important
::v-deep .v-input__prepend-inner
    padding: 0 8px 8px 0 !important
::v-deep .bold-placeholder input::placeholder
    color: $text-color !important
    letter-spacing: 1.25px
    font-weight: 450
    font-size: 14px
::v-deep input
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
::v-deep fieldset
    border-color: $light-gray-2
::v-deep .v-list-item__action:first-child
    margin-right: 12px !important
</style>
