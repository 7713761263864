import * as t from '@/store/chisai/GCB2/actionTypes'
import { getAdminReportData, getSummaryAdminReportManualData } from '@/api/chisai/GCB2'

export default {
    state: {
        adminReportData: [],
        summaryAdminReportManualData: [],
    },
    mutations: {
        [t.ADD_ADMIN_REPORT_DATA](state: any, { data, projectId }: { data: any[]; projectId: string }) {
            const updatedData = state.adminReportData
                .filter((el: any) => el.projectId !== projectId)
                .concat(data)
            state.adminReportData = updatedData
        },
        [t.ADD_SUMMARY_ADMIN_REPORT_MANUAL_DATA](
            state: any,
            { data, projectId }: { data: any[]; projectId: string }
        ) {
            const updatedData = state.summaryAdminReportManualData
                .filter((el: any) => el.projectId !== projectId)
                .concat(data)
            state.summaryAdminReportManualData = updatedData
        },
    },
    actions: {
        async [t.FETCH_AND_ADD_SUMMARY_ADMIN_REPORT_MANUAL_DATA](
            { commit, dispatch }: any,
            projectId: string
        ) {
            const response = await getSummaryAdminReportManualData(projectId)
            if (response.error) {
                dispatch('callNotify', 'При загрузке данных произошла ошибка ', { root: true })
            }
            const data = response.data
            commit(t.ADD_SUMMARY_ADMIN_REPORT_MANUAL_DATA, { data, projectId })
        },
        async [t.FETCH_AND_ADD_ADMIN_REPORT_DATA]({ commit, dispatch }: any, projectId: string) {
            const response = await getAdminReportData({ projectId, filters: {} })
            if (response.error) {
                dispatch('callNotify', 'При загрузке данных произошла ошибка ', { root: true })
            }
            const data = response.data
            commit(t.ADD_ADMIN_REPORT_DATA, { data, projectId })
        },
    },

    getters: {
        [t.GET_ADMIN_REPORT_DATA]: (state: any) => (projectId: string) => {
            return state.adminReportData.filter((el: any) => el.projectId === projectId)
        },
        [t.GET_SUMMARY_ADMIN_REPORT_MANUAL_DATA]: (state: any) => (projectId: string) => {
            return state.summaryAdminReportManualData.filter((el: any) => el.projectId === projectId)
        },
    },
}
