
import { computed, ComputedRef, defineComponent, getCurrentInstance, reactive, ref, watch } from 'vue'
import { useNotificationStore } from '@/store/stores/notification'
import useRouter from '@/hooks/useRouter'
import _ from 'lodash'
import { useProject } from '@root/src/hooks/useProject'
import project from '@root/src/store/project'
export default defineComponent({
    props: {
        unreadedDageourNotifications: Array,
    },
    setup(props, context) {
        const { project } = useProject()
        const root = getCurrentInstance()!.proxy
        const currentNotification = computed(() => _.first(props.unreadedDageourNotifications))
        const redirectBilling = () => {
            const url = `/project/${currentNotification.value['projectId']}/billing?section=tariffs`
            useRouter(root).router.push(url)
        }
        const notificationStore = useNotificationStore()
        const isDialog: ComputedRef<boolean> = computed(() =>
            Boolean(props.unreadedDageourNotifications?.length)
        )
        
        const projectName = computed(()=>{
            const name = root.$store.getters.projectById(currentNotification.value['projectId'])
            return name.name
        })

        return {
            notificationStore,
            isDialog,
            currentNotification,
            redirectBilling,
            project,
            projectName
        }
    },
})
