import * as gcb2 from './chisai/GCB2'
import * as project from './projects'
import * as networkFixer from './networkFixer'
import * as clientForm from './clientForm'
import * as scheduledReport from './scheduledReport'
import * as yClients from './connectors/yClients'
import * as arnicaV2 from './connectors/arnicaV2'
import * as avgCheckReport from './avgCheckReport'
import * as pyrus from './pyrus'
import * as massender from './massender'
import * as marketplace from './marketplace'
import * as telephonyBeeline from './connectors/telephonyBeeline'
import * as telephonyMango from './connectors/telephonyMango'
import * as telephonyMegaphone from './connectors/telephonyMegaphone'
import * as pbxOnline from './connectors/pbxOnline'
import * as whatsapp from './connectors/whatsapp'
import * as file from './file'
import * as knowledgeBase from './knowledgeBase'
import { createInstanceTg, inputCodeTg, sendCodeTg, unAuthInstance } from './networkFixer'

const api = {
    gcb2,
    project,
    networkFixer,
    clientForm,
    scheduledReport,
    avgCheckReport,
    connectors: { yClients, arnicaV2, telephonyBeeline, telephonyMango, telephonyMegaphone, whatsapp, telephonyPbx:pbxOnline },
    pyrus,
    marketplace,
    file,
    massender,
    knowledgeBase,
    unAuthInstance,
    createInstanceTg,
    sendCodeTg,
    inputCodeTg,
}
export default api
