import * as t from '@/store/chisai/GCB2/actionTypes'
import _ from 'lodash'
import api from '../../../../api/api'
import {
    getClientVisitsHistoryData,
    getManualCommunicationData,
    getNeedToContactWidgetData,
    taskListAction,
} from '../../../../api/chisai/GCB2'
import { ManualCommunication, TaskListFilters } from '../../../../types/GCB2'
import { declOfNum, sleep } from '../../../../utils'
import { TASKLIST_CHANGE_COLOR_TIME, TASKLIST_STATUSES } from '../../../../vars/GCB2'
import { OUT_OF_SYNC_ERROR_TEXT, NETWORK_ERROR_TEXT } from '../../../../vars/general'
import { useGcb2Store } from '../../../stores/gcb2/gcb2'
import { useModalStore } from '../../../stores/modal'
import { getCommunicatonIdsToUpdateColor, mapTaskListRow } from './helpers'
export default {
    state: {
        manualCommunicationData: [] as ManualCommunication[],
        manualCommunicationDataCurrentItems: [] as ManualCommunication[],
        needToContactWidgetData: [] as any[],
        clientVisitsHistoryData: [] as any[],
        loadingActionsCommunicationIds: [] as number[],
        disabledCallCommunicationIds: [] as number[],
        taskListFilters: {} as TaskListFilters,
        messageErrors : [] as any[]
    },
    mutations: {
        [t.APPEND_MESSAGE_ERRORS](state : any, messages : any[]) {
            console.log(messages)
            state.messageErrors = state.messageErrors.concat(messages)
        },
        [t.CLEAR_MANUAL_COMMUNICATION_DATA](state: any) {
            state.manualCommunicationData = []
        },
        [t.ADD_DISABLED_CALL_COMMUNNICATION_IDS](state: any, communicationIds: number[]) {
            const updatedData = state.disabledCallCommunicationIds.concat(communicationIds)
            state.disabledCallCommunicationIds = updatedData
        },
        [t.REMOVE_DISABLED_CALL_COMMUNNICATION_IDS](state: any, communicationIds: number[]) {
            const updatedData = state.disabledCallCommunicationIds.filter(
                (el: number) => !communicationIds.includes(el)
            )
            state.disabledCallCommunicationIds = updatedData
        },
        [t.ADD_LOADING_COMMUNNICATION_IDS](state: any, communicationIds: number[]) {
            const updatedData = state.loadingActionsCommunicationIds.concat(communicationIds)
            state.loadingActionsCommunicationIds = updatedData
        },
        [t.REMOVE_LOADING_COMMUNNICATION_IDS](state: any, communicationIds: number[]) {
            const updatedData = state.loadingActionsCommunicationIds.filter(
                (el: number) => !communicationIds.includes(el)
            )
            state.loadingActionsCommunicationIds = updatedData
        },
        [t.ADD_MISSED_CALL_TO_MANUAL_COMMUNICATION_DATA](
            state: any,
            { data, projectId }: { data: any[]; projectId: string }
        ) {
            const filtredData = data.filter(el => state.taskListFilters.activations.indexOf(el.activationId) >= 0 )
            const updatedData = state.manualCommunicationData
            .filter(el => el.communicationId !== filtredData[0].communicationId)
            .concat(filtredData)
            state.manualCommunicationData = Object.freeze(updatedData)
        },
        [t.ADD_MANUAL_COMMUNICATION_DATA](
            state: any,
            { data, projectId }: { data: any[]; projectId: string }
        ) {
            const updatedData = state.manualCommunicationData
                .filter((el: any) => el.projectId !== projectId)
                .concat(data)
            state.manualCommunicationData = Object.freeze(updatedData)
        },
        [t.ADD_TASK_LIST_FILTERS](
            state: any,
            { filters }
        ) {
            state.taskListFilters = Object.freeze(filters)
        },
        [t.TASKLIST_BATCH_INSERT_ITEMS](state: any, items: any) {
            const updatedData = state.manualCommunicationData.concat(items)
            state.manualCommunicationData = Object.freeze(updatedData)
        },
        [t.REPLACE_SERVICE_BAN_DATA](state: any, { data, projectId }: { data: any[]; projectId: string }) {
            const dataCopy = Object.freeze([...state.manualCommunicationData])
            const updatedData = dataCopy
                .filter(
                    el =>
                        el.projectId !== projectId ||
                        (el.projectId === projectId && el.status !== TASKLIST_STATUSES.DONT_REMIND_SERVICE)
                )
                .concat(data)

            state.manualCommunicationData = updatedData
        },
        [t.TASKLIST_CHANGE_ITEM](
            state: any,
            { communicationId, key, value }: { communicationId: number; key: string; value: any }
        ) {
            const dataCopy = Object.freeze([...state.manualCommunicationData])
            const rowToUpdate = dataCopy.find(row => row.communicationId === communicationId)
            rowToUpdate[key] = value
            state.manualCommunicationData = dataCopy
        },
        [t.TASKLIST_BATCH_CHANGE_ITEM](
            state: any,
            data: { communicationId: number; fields: { [x: string]: any } }[]
        ) {
            const dataCopy = Object.freeze([...state.manualCommunicationData])
            data.forEach(el => {
                let rowToUpdate = dataCopy.find(row => row.communicationId === el.communicationId)
                if (rowToUpdate) rowToUpdate = Object.freeze(Object.assign(rowToUpdate, el.fields))
            })
            state.manualCommunicationData = dataCopy
        },

        [t.SET_MANUAL_COMMUNICATION_CURRENT_ITEMS](state: any, { data }: { data: ManualCommunication[] }) {
            if (!data || data.length === 0) {
                return
            }
            const updatedData = state.manualCommunicationDataCurrentItems
                .filter((el: any) => el.projectId !== data[0].projectId || el.status !== data[0].status)
                .concat(data)
            state.manualCommunicationDataCurrentItems = updatedData
        },
        [t.REMOVE_MANY_MANUAL_COMMUNICATIONS](
            state: any,
            { communicationIds }: { communicationIds: (number | string)[] }
        ) {
            let dataCopy = Object.freeze([...state.manualCommunicationData])
            dataCopy = dataCopy.filter(row => !communicationIds.includes(row.communicationId))
            state.manualCommunicationData = dataCopy
        },
        [t.ADD_NEED_TO_CONTACT_WIDGET_DATA](state: any, { data }: { data: any }) {
            const dataCopy = [...state.needToContactWidgetData].filter(
                el => el.communicationId !== data.communicationId
            )
            dataCopy.push(data)
            state.needToContactWidgetData = dataCopy
        },
        [t.ADD_CLIENT_VISITS_HISTORY_DATA](state: any, { data }: { data: any[] }) {
            state.clientVisitsHistoryData = state.clientVisitsHistoryData.concat(data)
        },
    },
    actions: {
        async [t.FETCH_AND_ADD_MANUAL_COMMUNICATION_DATA](
            { commit, dispatch }: any,
            {
                projectId,
                filters,
            }: {
                projectId: string
                filters: TaskListFilters
            }
        ) {
            const response = await getManualCommunicationData({ projectId, filters })
            if (response.error) {
                dispatch('callNotify', 'При загрузке данных произошла ошибка ', { root: true })
            }

            const data = response.data?.map((el: ManualCommunication) => mapTaskListRow(el))
            commit(t.ADD_TASK_LIST_FILTERS, {filters: filters})
            commit(t.ADD_MANUAL_COMMUNICATION_DATA, { data: Object.freeze(data), projectId })
        },
        async [t.FETCH_ADD_NEED_TO_CONTACT_WIDGET_DATA](
            { commit, dispatch }: any,
            {
                projectId,
                communicationId,
                clientId,
                activationId,
            }: { projectId: string; clientId: string; activationId: number; communicationId: number }
        ) {
            const response = await getNeedToContactWidgetData({
                projectId,
                communicationId: Number(communicationId),
                clientId,
                activationId,
            })
            if (response.error) {
                dispatch('callNotify', 'При загрузке данных произошла ошибка ', { root: true })
            }
            const data = Object.assign(response.data as Object, { communicationId })
            commit(t.ADD_NEED_TO_CONTACT_WIDGET_DATA, { data, projectId })
        },
        async [t.FETCH_AND_ADD_CLIENT_VISITS_HISTORY_DATA](
            { commit, dispatch }: any,
            { projectId, clientId }: { projectId: string; clientId: string; activationId: number }
        ) {
            const response = await getClientVisitsHistoryData({
                projectId,
                clientId,
            })
            if (response.error) {
                dispatch('callNotify', 'При загрузке данных произошла ошибка ', { root: true })
            }
            const data = response.data
            commit(t.ADD_CLIENT_VISITS_HISTORY_DATA, { data, projectId })
        },
        async [t.MAKE_TASKLIST_ACTION](
            { state, commit, dispatch, getters }: any,
            {
                projectId,
                actionType,
                actionData,
                communicationId,
                statusText,
                socketId,
                responseData,
            }: {
                projectId: string
                actionType: string
                actionData: any
                communicationId?: string
                statusText?: string
                socketId?: string
                responseData?: any
            }
        ) {
            const gcb2Store = useGcb2Store()
            const rowsToChangeColor = getCommunicatonIdsToUpdateColor(
                state.manualCommunicationData,
                actionData,
                actionType
            )

            commit(
                t.TASKLIST_BATCH_CHANGE_ITEM,
                rowsToChangeColor.map(el => ({
                    communicationId: el.communicationId,
                    fields: {
                        color: el.color,
                    },
                }))
            )
            const startTimestamp = Date.now()
            const response = responseData
                ? { error: null, data: responseData }
                : await api.gcb2
                      .taskListAction({
                          projectId,
                          actionType,
                          actionData,
                          communicationId,
                          statusText,
                          socketId,
                      })
                      .catch(err => {
                          //Обрабатываем только сетевые ошибки
                          console.error('NETWORK_ERROR')
                          const modalStore = useModalStore()
                          modalStore.open({ text: NETWORK_ERROR_TEXT, color: 'error' })
                          throw err
                      })
            const responseTime = Date.now() - startTimestamp
            const sleepTime =
                TASKLIST_CHANGE_COLOR_TIME - responseTime > 0 ? TASKLIST_CHANGE_COLOR_TIME - responseTime : 0
            await sleep(sleepTime)
            commit(
                t.TASKLIST_BATCH_CHANGE_ITEM,
                rowsToChangeColor.map(el => ({
                    communicationId: el.communicationId,
                    fields: {
                        color: null,
                    },
                })).filter(el => el.communicationId !== communicationId)
            )

            if (response.error) {
                if (response.error?.code === 409) {
                    const modalStore = useModalStore()
                    modalStore.open({ text: OUT_OF_SYNC_ERROR_TEXT, color: 'error' })
                } else {
                    gcb2Store.setTasklistErrorModalIsActive(true)
                }
                commit(
                    t.TASKLIST_BATCH_CHANGE_ITEM,
                    rowsToChangeColor.map(el => ({
                        communicationId: el.communicationId,
                        fields: {
                            color: null,
                        },
                    }))
                )
                throw new Error(JSON.stringify(response.error))
            }
            const data = response.data
            const itemsToChange = [] as any
            const itemsToAdd = [] as any
            const initialIdsToDelete = [] as any
            data?.forEach((el: any) => {
                if (el.type && el.type === 'delete') {
                    const manualCommunicationsToDelete = state.manualCommunicationData.filter((el2: any) =>
                        Object.keys(el.conditions).every(
                            conditionKey => el.conditions[conditionKey] === el2[conditionKey]
                        )
                    )
                    manualCommunicationsToDelete.forEach((el2: any) => {
                        initialIdsToDelete.push(el2.communicationId)
                    })
                } else if (el.type && el.type === 'add') {
                    itemsToAdd.push(el.item)
                } else {
                    const manualCommunicationToChange = getters[t.GET_ONE_MANUAL_COMMUNICATION](
                        el.communicationId
                    )
                    const changedManualCommunication = mapTaskListRow(
                        Object.assign({}, manualCommunicationToChange, el.fields)
                    )
                    itemsToChange.push({
                        communicationId: el.communicationId,
                        fields: _.omit(changedManualCommunication, 'communicationId'),
                    })
                }
            })
            const IdsToChange = _.map(itemsToChange, 'communicationId')
            const itemsToDelete = _.difference(initialIdsToDelete, IdsToChange)

            commit(t.TASKLIST_BATCH_CHANGE_ITEM, itemsToChange)
            commit(t.TASKLIST_BATCH_INSERT_ITEMS, itemsToAdd)
            if (itemsToDelete.length) {
                commit(t.REMOVE_MANY_MANUAL_COMMUNICATIONS, {
                    communicationIds: itemsToDelete,
                })
            }
            if (gcb2Store.sidebar.communicationId === communicationId) {
                gcb2Store.refreshSidebarItem()
            }
        },
    },
    getters: {
        [t.GET_MESSAGE_ERRORS]: (state : any) => {
            return state.messageErrors
        },

        [t.GET_TASK_LIST_FILTERS]: (state: any) => {
            return state.taskListFilters
        },

        [t.GET_MANUAL_COMMUNICATION_DATA]: (state: any) => (projectId: string) => {
            return state.manualCommunicationData.filter((el: any) => el.projectId === projectId)
        },
        [t.GET_ONE_MANUAL_COMMUNICATION]: (state: any) => (communicationId: string) => {
            return state.manualCommunicationData.find(
                (el: ManualCommunication) => el.communicationId === communicationId
            )
        },
        [t.GET_ONE_NEED_TO_CONTACT_WIDGET_DATA]: (state: any) => (communicationId: number) => {
            return state.needToContactWidgetData.find((el: any) => el.communicationId === communicationId)
        },
        [t.GET_CLIENT_VISITS_HISTORY_DATA]: (state: any) => ({
            clientId,
            projectId,
        }: {
            clientId: string
            projectId: string
        }) => {
            return state.clientVisitsHistoryData.filter(
                (el: any) => el.projectId === projectId && el.clientId === clientId
            )
        },
        [t.GET_CURRENT_COMMUNICATION_INDEX]: (state: any) => ({
            communicationId,
            projectId,
            status,
        }: {
            communicationId: string
            projectId: string
            status: string
        }) => {
            const currentItemsInStatus = state.manualCommunicationDataCurrentItems.filter(
                (el: ManualCommunication) => el.projectId === projectId && el.status === status
            )

            const currentManualCommunicatonIndex = currentItemsInStatus.findIndex(
                (el: ManualCommunication) => el.communicationId === communicationId
            )
            return currentManualCommunicatonIndex
        },
        [t.GET_COMMUNICATION_BY_CURRENT_INDEX]: (state: any) => ({
            index,
            projectId,
            status,
        }: {
            index: number
            projectId: string
            status: string
        }) => {
            const currentItemsInStatus = state.manualCommunicationDataCurrentItems.filter(
                (el: ManualCommunication) => el.projectId === projectId && el.status === status
            )

            return currentItemsInStatus[index]
        },
        [t.GET_NEXT_MANUAL_COMMUNICATION]: (state: any) => ({
            communicationId,
            projectId,
            status,
        }: {
            communicationId: string
            projectId: string
            status: string
        }) => {
            const currentItemsInStatus = state.manualCommunicationDataCurrentItems.filter(
                (el: ManualCommunication) => el.projectId === projectId && el.status === status
            )

            const currentManualCommunicatonIndex = currentItemsInStatus.findIndex(
                (el: ManualCommunication) => el.communicationId === communicationId
            )
            const nextManualCommunicatonIndex = currentManualCommunicatonIndex + 1
            const nextManualCommunicaton = currentItemsInStatus[nextManualCommunicatonIndex]
            return nextManualCommunicaton
        },
    },
}
