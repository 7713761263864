import firebase from 'firebase/app'
import 'firebase/database'
import config from '../../config'
if (!config.firebaseCfg) {
    throw new Error('Firebase config is null')
}
const firebaseConfig = config.firebaseCfg
firebase.initializeApp(firebaseConfig)

export default firebase
