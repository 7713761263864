
import Vue from 'vue'
import { PendingConnector } from '@root/src/types/main'
import Breadcrumbs from '../../../components/Breadcrumbs.vue'
import { useAdminStore } from '../../../store/stores/admin'
import ProfSalonResolver from './resolvers/ProfSalonResolver.vue'
import ProfSalonBatResolver from './resolvers/ProfSalonBatResolver.vue'
import YclientsResolver from './resolvers/YclientsResolver.vue'
import AltegioReolver from './resolvers/AltegioResolver.vue'
import { CONNECTOR_TYPES } from '../../../vars/general'
import { mapStores } from 'pinia'
import { getPendingConnector } from '../../../api/pendingConnectors'

export default Vue.extend({
    name: 'AdminResolvePendingConnector',
    components: {
        Breadcrumbs,
        ProfSalonResolver,
        YclientsResolver,
        AltegioReolver,
        ProfSalonBatResolver,
    },
    data() {
        return {
            CONNECTOR_TYPES,
            loading: false,
            pendingConnector: undefined as PendingConnector | undefined,
            breadcrumbs: [
                {
                    text: 'ГЛАВНАЯ',
                    to: '/admin',
                },
                {
                    text: 'ПОДКЛЮЧЕНИЕ КОННЕКТОРОВ',
                    to: '/admin/pendingConnectors',
                },
            ],
        }
    },
    computed: {
        ...mapStores(useAdminStore),
    },
    async created() {
        this.pendingConnector = this.adminStore.getPendingConnectorById(
            Number(this.$router.currentRoute.params.id)
        )
        //Если список коннекторов не загружен, получаем только этот коннектор
        if (!this.pendingConnector) {
            const pendingConnector = await getPendingConnector(Number(this.$router.currentRoute.params.id))
            this.adminStore.setOnePendingConnector(pendingConnector)
            this.pendingConnector = this.adminStore.getPendingConnectorById(
                Number(this.$router.currentRoute.params.id)
            )
        }
    },
})
