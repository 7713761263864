<template>
    <v-snackbar v-model="isNotifyActive" left :timeout="timeout" color="main">
        <span class="white--text">{{ notifyText }}</span>
        <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="closeNotify"> Закрыть </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
//Вызвать snackbar
//this.$store.dispatch('callNotify', 'notifyText')
import { mapState } from 'vuex'
export default {
    name: 'Notify',
    data: () => ({
        timeout: 3000,
        companies: [],
        test: true,
    }),
    computed: {
        ...mapState(['notifyText']),
        isNotifyActive: {
            get() {
                return this.$store.state.isNotifyActive
            },
            set(value) {
                this.$store.commit('setIsNotifyActive', value)
            },
        },
    },
    methods: {
        closeNotify() {
            this.$store.commit('setIsNotifyActive', false)
        },
    },
}
</script>
<style lang="sass" scoped>
</style>
