export const FETCH_AND_ADD_MANUAL_COMMUNICATION_DATA = 'FETCH_AND_ADD_MANUAL_COMMUNICATION_DATA'
export const FETCH_ADD_NEED_TO_CONTACT_WIDGET_DATA = 'FETCH_ADD_NEED_TO_CONTACT_WIDGET_DATA'
export const FETCH_AND_ADD_CLIENT_VISITS_HISTORY_DATA = 'FETCH_AND_ADD_CLIENT_VISITS_HISTORY_DATA'
export const GET_MANUAL_COMMUNICATION_DATA = 'GET_MANUAL_COMMUNICATION_DATA'
export const GET_ONE_NEED_TO_CONTACT_WIDGET_DATA = 'GET_ONE_NEED_TO_CONTACT_WIDGET_DATA'
export const GET_ONE_MANUAL_COMMUNICATION = 'GET_ONE_MANUAL_COMMUNICATION'
export const GET_NEXT_MANUAL_COMMUNICATION = 'GET_NEXT_MANUAL_COMMUNICATION'
export const SET_MANUAL_COMMUNICATION_CURRENT_ITEMS = 'SET_MANUAL_COMMUNICATION_CURRENT_ITEMS'
export const GET_MESSAGE_ERRORS = "GET_MESSAGE_ERRORS"

export const REMOVE_MANY_MANUAL_COMMUNICATIONS = 'REMOVE_MANY_MANUAL_COMMUNICATIONS'
export const REPLACE_SERVICE_BAN_DATA = 'REPLACE_SERVICE_BAN_DATA'
export const ADD_MANUAL_COMMUNICATION_DATA = 'ADD_MANUAL_COMMUNICATION_DATA'
export const ADD_MISSED_CALL_TO_MANUAL_COMMUNICATION_DATA = 'ADD_MISSED_CALL_TO_MANUAL_COMMUNICATION_DATA'
export const MAKE_TASKLIST_ACTION = 'MAKE_TASKLIST_ACTION'
export const TASKLIST_CHANGE_ITEM = 'TASKLIST_CHANGE_ITEM'
export const TASKLIST_BATCH_CHANGE_ITEM = 'TASKLIST_BATCH_CHANGE_ITEM'
export const TASKLIST_BATCH_INSERT_ITEMS = 'TASKLIST_BATCH_INSERT_ITEMS'
export const ADD_NEED_TO_CONTACT_WIDGET_DATA = 'ADD_NEED_TO_CONTACT_WIDGET_DATA'
export const ADD_CLIENT_VISITS_HISTORY_DATA = 'ADD_CLIENT_VISITS_HISTORY_DATA'
export const GET_CLIENT_VISITS_HISTORY_DATA = 'GET_CLIENT_VISITS_HISTORY_DATA'
export const ADD_LOADING_COMMUNNICATION_IDS = 'ADD_LOADING_COMMUNNICATION_IDS'
export const REMOVE_LOADING_COMMUNNICATION_IDS = 'REMOVE_LOADING_COMMUNNICATION_IDS'
export const ADD_DISABLED_CALL_COMMUNNICATION_IDS = 'ADD_DISABLED_CALL_COMMUNNICATION_IDS'
export const REMOVE_DISABLED_CALL_COMMUNNICATION_IDS = 'REMOVE_DISABLED_CALL_COMMUNNICATION_IDS'
export const CLEAR_MANUAL_COMMUNICATION_DATA = 'CLEAR_MANUAL_COMMUNICATION_DATA'
export const GET_CURRENT_COMMUNICATION_INDEX = 'GET_CURRENT_COMMUNICATION_INDEX'
export const GET_COMMUNICATION_BY_CURRENT_INDEX = 'GET_COMMUNICATION_BY_CURRENT_INDEX'
export const ADD_TASK_LIST_FILTERS = 'ADD_TASK_LIST_FILTERS'
export const GET_TASK_LIST_FILTERS = 'GET_TASK_LIST_FILTERS'
export const APPEND_MESSAGE_ERRORS = "APPEND_MESSAGE_ERRORS"
