import cfg from '../../config'
import { getInstance } from '../auth'
import { ApiWrapper } from '../types/main'

export const authApi = {}

export async function login({
    email,
    password,
}: {
    email: string
    password: string
}): Promise<ApiWrapper<any>> {
    const url = new URL(`${cfg.apiEndpoint}/auth/login`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
            email,
            password,
        }),
    }
    const res = await await (await fetch(url.toString(), options)).json()
    return res
}
export async function fastRegister({
    type,
    userData,
    userSign,
}: {
    type: string
    userData: string
    userSign: string
}): Promise<ApiWrapper<any>> {
    const url = new URL(`${cfg.apiEndpoint}/auth/fastRegister`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
            type,
            userData,
            userSign,
        }),
    }
    const res = await await (await fetch(url.toString(), options)).json()
    return res
}
export async function googleLogin({
    code,
    utmSource,
}: {
    code: string
    utmSource: string | null
}): Promise<ApiWrapper<any>> {
    const url = new URL(`${cfg.apiEndpoint}/auth/googleLogin`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
            code,
            utmSource,
        }),
    }
    const res = await await (await fetch(url.toString(), options)).json()
    return res
}
export async function register({
    email,
    password,
    utmSource,
}: {
    email: string
    password: string
    utmSource: string | null
}): Promise<ApiWrapper<any>> {
    const url = new URL(`${cfg.apiEndpoint}/auth/register`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
            email,
            password,
            utmSource,
        }),
    }
    const res = await await (await fetch(url.toString(), options)).json()
    return res
}
export async function changePassword({
    userId,
    password,
    newPassword,
}: {
    userId: string
    password: string
    newPassword: string
}): Promise<ApiWrapper<any>> {
    const url = new URL(`${cfg.apiEndpoint}/auth/changePassword`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
            userId,
            password,
            newPassword,
        }),
    }
    const res = await await (await fetch(url.toString(), options)).json()
    return res
}
export async function resetPassword({
    email,
    password,
}: {
    email: string
    password: string
}): Promise<ApiWrapper<any>> {
    const url = new URL(`${cfg.apiEndpoint}/auth/resetPassword`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
            email,
        }),
    }
    const res = await await (await fetch(url.toString(), options)).json()
    return res
}
export async function getUser(): Promise<ApiWrapper<any>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/auth/me`)
    const options = {
        method: 'GET',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
    }
    const res = await await (await fetch(url.toString(), options)).json()
    return res
}
