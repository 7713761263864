import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import VueRouter from 'vue-router'
import { getInstance } from '../auth/authWrapper'
const Landing = () => import('../views/Landing.vue')
//import Tariff from '../views/Tariff.vue'
const Projects = () => import('../views/Projects.vue')
const Project = () => import('../views/Project.vue')
const ProjectSettings = () => import('@/views/ProjectSettings.vue')
const RolesSettings = () => import('@/views/RolesSettings.vue')
const NotFound = () => import('../views/NotFound.vue')
const OAuthLogin = () => import('../views/OAuthLogin.vue')
const ConnectorSettings = () => import('../views/ConnectorSettings.vue')
const AddConnectorStage1 = () => import('../views/AddConnectorStage1.vue')
const AddConnectorStage2 = () => import('../views/AddConnectorStage2.vue')
const AddConnectorStage3 = () => import('../views/AddConnectorStage3.vue')
const ScheduledReport = () => import('../views/ScheduledReport.vue')
const Chat = () => import('../views/Chat.vue')
const SelectCRMSystemView = () => import('@/components/project/onBoarding/SelectCRMSystemView.vue')
const AuthorizationInCRMSystemView = () =>
    import('@/components/project/onBoarding/AuthorizationInCRMSystemView.vue')
const SettingsCRMView = () => import('@/components/project/onBoarding/SettingsCRMView.vue')
const BotSettingsView = () => import('@/components/project/onBoarding/BotSettingsView.vue')
const SuccessfulView = () => import('@/components/project/onBoarding/SuccessfullView.vue')

import qs from 'query-string'
import GCB2Routes from './routes/GCB2.js'
import adminRoutes from './routes/admin'
import externalMarketplaceModuleRoutes from '@/modules/externalMarketplace/routes'
import adminReportPseudActivationReportRoutes from '@/modules/AdminReportPseudoActivation/routes'
import marketplaceModuleRoutes from '@/modules/marketplace/routes'
import { checkRole } from '../helpers/roles'
import store from '../store'
const { isNavigationFailure, NavigationFailureType } = VueRouter

Vue.use(VueRouter)
const routes = [
    {
        path: '/landing',
        name: 'Landing',
        component: Landing,
        unprotected: true,
    },
    {
        path: '/projects',
        name: 'Projects',
        component: Projects,
    },
    {
        path: '/project/:id',
        name: 'Project',
        component: Project,
    },
    {
        path: '/project/:id/settings',
        name: 'ProjectSettings',
        component: ProjectSettings,
    },
    {
        path: '/project/:id/settings/roles',
        name: 'RolesSettings',
        component: RolesSettings,
    },
    {
        path: '/OAuthLogin',
        name: 'OAuthLogin',
        component: OAuthLogin,
    },
    {
        path: '/project/:id/addConnectorStage1',
        name: 'AddConnectorStage1',
        component: AddConnectorStage1,
    },
    {
        path: '/project/:id/addConnectorStage2',
        name: 'addConnectorStage2',
        component: AddConnectorStage2,
    },
    {
        path: '/project/:id/addConnectorStage3',
        name: 'addConnectorStage3',
        component: AddConnectorStage3,
    },
    {
        path: '/project/:id/connectorSettings/:connectorId',
        name: 'addConnectorStage3',
        component: ConnectorSettings,
    },
    {
        path: '/project/:id/scheduledReport',
        name: 'addScheduledReport',
        component: ScheduledReport,
    },
    {
        path: '/chat/:id',
        name: 'chat',
        component: Chat,
    },
    {
        path: '/project/:id/onBoarding',
        name: 'SelectCRMSystem',
        component: SelectCRMSystemView,
    },
    {
        path: '/project/:id/onBoarding/SelectCRMSystem',
        name: 'SelectCRMSystem',
        component: SelectCRMSystemView,
    },
    {
        path: '/project/:id/onBoarding/SettingsCRMView',
        name: 'SettingsCRM',
        component: SettingsCRMView,
    },
    {
        path: '/project/:id/onBoarding/BotSettingsView',
        name: 'SettoigsBot',
        component: BotSettingsView,
    },
    {
        path: '/project/:id/onBoarding/AuthorizationInCRMSystem',
        name: 'AuthorizationInCRMSystem',
        component: AuthorizationInCRMSystemView,
    },
    {
        path: '/project/:id/onBoarding/Successfull',
        name: 'SuccessfullView',
        component: SuccessfulView,
    },
]
    .concat(GCB2Routes)
    .concat(adminRoutes)
    .concat(externalMarketplaceModuleRoutes)
    .concat(adminReportPseudActivationReportRoutes)
    .concat(marketplaceModuleRoutes)
    .concat([{ path: '*', component: NotFound }])

export const unprotectedRoutesNames = routes.filter(el => el.unprotected).map(el => el.name)

//убирает ошибку Avoided redundant navigation to current location:
const routerPush = VueRouter.prototype.push
const routerReplace = VueRouter.prototype.replace
VueRouter.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => {
        if ((isNavigationFailure, NavigationFailureType.redirected)) {
            return
        }
        console.log('error.name', error.name)
        if (error.name !== 'NavigationDuplicated') {
            console.error(error)
        }
    })
}
VueRouter.prototype.replace = function replace(location) {
    return routerReplace.call(this, location).catch(error => {
        if ((isNavigationFailure, NavigationFailureType.redirected)) {
            return
        }
        console.log('error.name', error.name)
        if (error.name !== 'NavigationDuplicated') {
            console.error(error)
        }
    })
}
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    },
})

router.beforeEach((to, from, next) => {
    if (to.params.id) {
        Sentry.setTag('projectId', to.params.id)
    } else {
        Sentry.setTag('projectId', null)
    }
    const route = routes.find(el => el.name === to.name)
    if (route.requiredRoles) {
        store.watch(
            (state, getters) => state.isProjectsLoading,
            projectsLoading => {
                const projectId = to.params.id
                if (!projectsLoading && !route.requiredRoles.every(role => checkRole(projectId, role))) {
                    next(from)
                }
            },
            { immediate: true }
        )
    }

    //TODO: отрефакторить авторизацию
    const authInstance = getInstance()
    const interval = setInterval(async () => {
        if (!authInstance.loading) {
            clearInterval(interval)
            if (!authInstance.isAuthenticated && !unprotectedRoutesNames.includes(to.name) && !to.query.to) {
                const nextPath = qs.stringifyUrl({
                    url: '/landing',
                    query: to.query,
                })
                next(nextPath)
            } else if (authInstance.isAuthenticated && (to.path === '/landing' || to.path === '/')) {
                const nextPath = qs.stringifyUrl({
                    url: '/projects',
                    query: to.query,
                })
                next(nextPath)
                //redirect from gcb1 to gcb2
            } else if (authInstance.isAuthenticated && to.path.includes('/GetClientsBack')) {
                const projectId = to.params.id
                const nextPath = qs.stringifyUrl({
                    url: `/project/${projectId}/GCB2`,
                })
                next(nextPath)
            } else {
                next()
            }
        }
    }, 50)
})

export default router
