<template>
    <v-dialog width="380" v-model="isAuthDialog" @click:outside="cancel">
        <v-card tile height="490px">
            <v-overlay :absolute="true" :value="loading" opacity="0.6">
                <SpinnerLoader></SpinnerLoader>
            </v-overlay>
            <div class="card-head">
                <button
                    class="card-head-button"
                    :class="activeSection === 'login' ? 'active' : ''"
                    @click="activeSection = 'login'"
                >
                    ВХОД
                </button>
                <button
                    class="card-head-button"
                    :class="activeSection === 'register' ? 'active' : ''"
                    @click="activeSection = 'register'"
                >
                    РЕГИСТРАЦИЯ
                </button>
            </div>
            <v-card-text>
                <div
                    style="
                        height: 412px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                    "
                >
                    <div class="logo">
                        <img width="200" height="56" src="@/assets/Logo.svg" />
                    </div>
                    <div class="login" v-if="activeSection === 'login'">
                        <v-form ref="login" @submit.prevent="submit">
                            <TextField
                                v-model.trim="credentials.email"
                                label="Электронная почта"
                                color="main"
                                prepend-inner-icon="mdi-email"
                                :error="Boolean(error)"
                                name="email"
                            ></TextField>
                            <TextField
                                v-model="credentials.password"
                                label="Пароль"
                                color="main"
                                prepend-inner-icon="mdi-lock"
                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="showPassword ? 'text' : 'password'"
                                @click:append="showPassword = !showPassword"
                                :error="Boolean(error)"
                                :error-messages="error"
                                name="password"
                            ></TextField>
                            <div class="forgot-password-wrapper">
                                <v-row class="ma-0 mb-4" justify="end">
                                    <span @click="activeSection = 'passwordReset'" class="forgot-password"
                                        >Не помню пароль</span
                                    >
                                </v-row>
                            </div>
                            <v-row class="ma-0 mb-4" justify="space-between">
                                <v-btn @click="login" color="main" class="white--text" type="submit"
                                    >ВОЙТИ</v-btn
                                >
                                <v-btn class="outlined-btn" outlined width="228" @click="googleLogin">
                                    <v-icon size="20" class="mr-2"> $vuetify.icons.googleAuth </v-icon>
                                    ВОЙТИ ЧЕРЕЗ GOOGLE
                                </v-btn>
                            </v-row>
                        </v-form>
                    </div>
                    <div class="register" v-if="activeSection === 'register'">
                        <v-form>
                            <TextField
                                :autocomplete="Date.now()"
                                v-model="credentials.email"
                                label="Электронная почта"
                                color="main"
                                prepend-inner-icon="mdi-email"
                                :error="Boolean(error)"
                            ></TextField>
                            <TextField
                                :autocomplete="Date.now()"
                                v-model="credentials.password"
                                label="Пароль"
                                color="main"
                                prepend-inner-icon="mdi-lock"
                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="showPassword ? 'text' : 'password'"
                                @click:append="showPassword = !showPassword"
                                :error="Boolean(error)"
                                :error-messages="error"
                            ></TextField>
                        </v-form>

                        <v-btn @click="register" color="main" class="white--text" width="228" type="submit"
                            >зарегистрироваться</v-btn
                        >
                        <v-btn class="outlined-btn mt-2 mb-4" outlined width="228" @click="googleLogin">
                            <v-icon size="20" class="mr-2"> $vuetify.icons.googleAuth </v-icon>
                            ВОЙТИ ЧЕРЕЗ GOOGLE
                        </v-btn>
                    </div>
                    <div class="password-reset" v-if="activeSection === 'passwordReset'">
                        <div v-if="passwordReseted === false">
                            <v-form>
                                <p>Новый пароль будет отправлен на указанную почту</p>
                                <TextField
                                    :autocomplete="Date.now()"
                                    v-model="credentials.email"
                                    label="Электронная почта"
                                    color="main"
                                    prepend-inner-icon="mdi-email"
                                    :error="Boolean(error)"
                                    :error-messages="error"
                                ></TextField>
                            </v-form>
                            <v-btn
                                @click="resetPassword"
                                color="main"
                                class="white--text"
                                width="228"
                                type="submit"
                                >Восстановить
                            </v-btn>
                        </div>
                        <div v-if="passwordReseted === true">
                            <p>Новый пароль отправлен на указанную почту</p>
                            <v-btn
                                @click="activeSection = 'login'"
                                color="main"
                                class="white--text"
                                width="228"
                                type="submit"
                            >
                                НАЗАД
                            </v-btn>
                        </div>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import SpinnerLoader from '../SpinnerLoader.vue'
import TextField from '../inputs/TextField.vue'

export default {
    name: 'AuthDialog',
    props: {
        startActiveSection: {
            type: String,
            default: undefined,
        },
    },
    components: { TextField, SpinnerLoader },
    data: () => ({
        credentials: {
            email: '',
            password: '',
        },
        loading: false,
        utmSource: null,
        googleAuth: null,
        showPassword: false,
        activeSection: 'login',
        error: null,
        passwordReseted: false,
    }),
    computed: {
        isAuthDialog: {
            get() {
                return this.$store.state.isAuthDialog
            },
            set(value) {
                this.$store.commit('setIsAuthDialog', value)
            },
        },
        authDialogStartSection: {
            get() {
                return this.$store.state.authDialogStartSection
            },
            set(value) {
                this.$store.commit('setAuthDialogStartSection', value)
            },
        },
    },
    watch: {
        isAuthDialog(val) {
            if (val) {
                this.activeSection = this.authDialogStartSection || 'login'
                this.init()
            }
        },
        activeSection(val) {
            if (val) this.init()
        },
    },
    methods: {
        async submit() {
            switch (this.activeSection) {
                case 'login':
                    this.login()
                    break
                case 'register':
                    this.register()
                    break
                case 'password-reset':
                    this.resetPassword()
                    break
                default:
                    break
            }
        },
        async login() {
            this.loading = true
            const { email, password } = this.credentials
            const { data, error } = await this.$auth.login(email, password)
            if (error) {
                this.error = error.message
                this.loading = false
                return
            }
            this.$store.commit('setIsAuthDialog', false)
            this.loading = false
        },
        async googleLogin() {
            try {
                this.loading = true
                const { data, error } = await this.$auth.googleLogin(this.utmSource)

                if (error) {
                    this.error = error.message
                    this.loading = false
                    return
                }
                this.$store.commit('setIsAuthDialog', false)
                this.loading = false
            } catch (err) {
                console.error(err)
                this.loading = false
            }
        },
        async register() {
            this.loading = true
            const { email, password } = this.credentials
            const { data: data1, error: error1 } = await this.$auth.register(email, password, this.utmSource)
            if (error1) {
                this.error = error1.message
                this.loading = false
                return
            }
            const { data2, error2 } = await this.$auth.login(email, password)
            if (error2) {
                this.error = error2.message
                this.loading = false
                return
            }
            this.$store.commit('setIsAuthDialog', false)
            this.loading = false
        },
        async resetPassword() {
            this.loading = true
            const { email } = this.credentials
            const { data, error } = await this.$auth.resetPassword(email)
            if (error) {
                this.error = error.message
                this.loading = false
                return
            }
            this.passwordReseted = true
            this.loading = false
        },
        cancel() {},
        init() {
            this.credentials = { email: '', password: '' }
            this.error = null
            this.passwordReseted = false
        },
    },

    created() {
        this.$router.onReady(() => {
            const utm_source = this.$router.currentRoute.query.utm_source
            if (utm_source) {
                localStorage.setItem('utm_source', this.$router.currentRoute.query.utm_source)
            }
            this.utmSource = localStorage.getItem('utm_source')
        })
    },
}
</script>

<style lang="sass" scoped>
@import '@/vars.sass'
.forgot-password
    color: $main
    cursor: pointer
.forgot-password-wrapper
    height: 44px
.logo
    display: flex
    justify-content: center
    margin-top: 38px
    margin-bottom: 25px
.card-head
    display: flex
    flex-direction: row
.card-head-button
    width: 50%
    height: 48px
    background-color: $main
    color: white
    &.active
        background-color: lighten($main,8%)
        border-bottom: 2px solid black
</style>
