
import { computed, defineComponent, ref, shallowRef, watch, PropType, onMounted } from 'vue'
import * as _ from 'lodash'
import { useProject } from '../../hooks/useProject'
import BaseInputOutlined from '../inputs/BaseInputOutlined.vue'

export default defineComponent({
    components: { BaseInputOutlined },
    props: {
        value: { type: Array as PropType<any[]>, required: true },
        placeholder: { type: String },
        canDeleteFirstItem: { type: Boolean },
        onInputChangeTransform: {
            type: Function as PropType<(v: string) => string>,
        },
        color:{ type: String },
    },
    setup(props, { emit }) {
        const { project } = useProject()
        const updateItem = (index: number, v: string) => {
            const copy = _.clone(props.value)
            copy.splice(index, 1, v)
            emit('input', copy)
        }
        const removeItem = (index: number) => {
            const copy = _.clone(props.value)
            copy.splice(index, 1)
            emit('input', copy)
        }
        const addItem = () => {
            const copy = _.clone(props.value)
            copy.push('')
            emit('input', copy)
        }
        const onChange = (index: number, v: string) => {
            if (!props.onInputChangeTransform) return
            const copy = _.clone(props.value)
            copy.splice(index, 1, props.onInputChangeTransform(v))
            emit('input', copy)
        }
        return { updateItem, removeItem, addItem, onChange }
    },
})
