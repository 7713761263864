import { getInstance } from '@/auth/authWrapper'
import cfg from '../../config'
export async function changeUserName(projectId, userId, name) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/user/changeName`
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            projectId,
            userId,
            name,
        }),
    }
    const res = await fetch(url, options)
    return res
}
