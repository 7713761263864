import { ApiWrapper } from '../types/main'
import { apiCall, http } from './http'

export async function getClientFormExists(): Promise<ApiWrapper<{ value: boolean }>> {
    const url = `clientForm/exists`
    const res = apiCall<any>(() => http.get(url.toString()))
    return res
}
export async function saveAnswers({
    projectId,
    answers,
    mappedAnswers,
    reason,
    extra,
}: {
    projectId: string
    answers: Object
    reason: string
    mappedAnswers?: Object
    extra?: any
}): Promise<ApiWrapper<any | null>> {
    const url = `clientForm/answers
`
    const res = apiCall<any>(() =>
        http.post(url.toString(), {
            json: { answers, reason, mappedAnswers, projectId, extra },
        })
    )
    return res
}
