
import Vue, { PropType, PropOptions } from 'vue'
import { PendingConnector } from '@root/src/types/main'
import { getCredential } from '@api/admin'
import TextField from '../../../../components/inputs/TextField.vue'
import { resolvePendingConnectorProfSalonBat } from '../../../../api/pendingConnectors'
import SpinnerLoader from '../../../../components/SpinnerLoader.vue'
import { CONNECTOR_TYPES } from '../../../../vars/general'
export default Vue.extend({
    name: 'PendingConnectorResolverProfSalon',
    components: { TextField, SpinnerLoader },
    props: {
        pendingConnector: Object as () => PendingConnector,
    },
    data() {
        return {
            loading: true,
            credentials: { login: '', password: '' },
            reqPending: false as boolean,
            valid1: false as boolean,
            token: '' as string,
            salonId: '' as string,
            profSalonLogin: '' as string,
            PHPSESSID: '' as string,
            isProfMed: false as boolean,
        }
    },
    computed: {
        stage(): Number {
            return this.pendingConnector.stage
        },
    },
    methods: {
        async resolve() {
            this.reqPending = true
            const res = await resolvePendingConnectorProfSalonBat({
                id: this.pendingConnector.id,
                token: this.token,
                salonId: this.salonId,
                PHPSESSID: this.PHPSESSID,
                profSalonLogin: this.profSalonLogin,
                isProfMed: this.isProfMed,
                isVersion2: this.pendingConnector.connectorType === CONNECTOR_TYPES.profSalonBatV2,
            })
            this.reqPending = false
            if (res.status > 300) {
                this.$store.dispatch('callNotify', 'Не верные данные')
            } else {
                this.$router.push('/admin/pendingConnectors')
            }
        },
    },
    async created() {
        this.loading = true
        const [login, password] = await Promise.all([
            getCredential({ connectorId: this.pendingConnector.connectorId, name: 'login' }).then(
                res => res.data
            ),
            getCredential({ connectorId: this.pendingConnector.connectorId, name: 'password' }).then(
                res => res.data
            ),
        ])
        this.credentials.login = login
        this.credentials.password = password
        this.loading = false
    },
})
