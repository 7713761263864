
import { computed, ComputedRef, defineComponent, getCurrentInstance, onMounted, reactive, ref, watch } from 'vue'
import { useNotificationStore } from '@/store/stores/notification'
import useRouter from '@/hooks/useRouter'
import _ from 'lodash'
import ConnectorsListView from '../project/ConnectorsListView.vue'
import { getConnector } from '@root/src/api/admin'
import { CONNECTOR_TYPES } from '@root/src/vars/general'
import { useProject } from '@root/src/hooks/useProject'
export default defineComponent({
    props: {
        unreadedHighSeverityNotifications: Array,
    },
    setup(props, context) {
        
        const root = getCurrentInstance()!.proxy
        const router = useRouter(root) 
        const notificationStore = useNotificationStore()
        const {project} = useProject()

        const isDialog: ComputedRef<boolean> = computed(() =>
            Boolean(props.unreadedHighSeverityNotifications?.length)
        )
        const currentNotification = computed(() => props.unreadedHighSeverityNotifications.find(notification => true));

        const currentNotificationType = computed(() => currentNotification.value['extra']?.type)
        const currentNotificationProjectId = currentNotification.value['projectId']

        const redirectToBillingPage = () =>{
            const url = `/project/${currentNotificationProjectId}/billing?section=tariffs`
            router.router.push(url)
            notificationStore.readNotification(currentNotification.value["id"])
        }

        const redirectToWaOldSettengsPage = () =>{
            const connectorId = root.$store.getters.connectorByType({
            connectorType: CONNECTOR_TYPES.whatsapp,
            projectId: project.value.id
                })
            const url = `/project/${currentNotificationProjectId}/connectorSettings/${connectorId.connectorId}`
            router.router.push(url)
            notificationStore.readNotification(currentNotification.value["id"])
        }

        const redirectToWaSettengsPage = () =>{
            const connectorId = root.$store.getters.connectorByType({
            connectorType: CONNECTOR_TYPES.whatsappBasis,
            projectId: project.value.id
                })
            const url = `/project/${currentNotificationProjectId}/connectorSettings/${connectorId.connectorId}`
            router.router.push(url)
            notificationStore.readNotification(currentNotification.value["id"])
        }
        
        return {
            notificationStore,
            isDialog,
            redirectToWaOldSettengsPage,
            currentNotification,
            currentNotificationType,
            currentNotificationProjectId,
            redirectToBillingPage,
            redirectToWaSettengsPage
        }
    },
})
