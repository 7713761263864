import { getInstance } from '@root/src/auth'
import { ApiWrapper } from '../../types/main'
import { apiCall, http } from '../http'
import cfg from '@root/config'
import { getError } from '../../utils'

interface LoginParams {
    connectorId: string
    vpbx_api_key: string
    vpbx_api_salt: string
}
interface UpdateCallHistoryParams {
    connectorId: string
}
export async function login({
    connectorId,
    vpbx_api_key,
    vpbx_api_salt,
}: LoginParams): Promise<ApiWrapper<any>> {
    const url = `ipTelephony/mango/login`
    const res = apiCall<any>(() =>
        http.post(url.toString(), { json: { connectorId, vpbx_api_key, vpbx_api_salt } })
    )
    return res
}
export async function updateCallHistory({ connectorId }: UpdateCallHistoryParams): Promise<ApiWrapper<any>> {
    const url = `ipTelephony/mango/updateHistory`
    const res = apiCall<any>(() => http.post(url.toString(), { json: { connectorId } }))
    return res
}
export async function getUsers(connectorId: string): Promise<ApiWrapper<any>> {
    const url = `ipTelephony/mango/getUsers`
    const res = apiCall<any>(() => http.get(url.toString(), { searchParams: { connectorId } }))
    return res
}

export async function makeCall({
    connectorId,
    from,
    extension,
    toNumber
}: {
    connectorId: string
    from : string
    extension : string
    toNumber : string,
}): Promise<ApiWrapper<any | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/ipTelephony/mango/makeCall`
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            connectorId,
            from,
            extension,
            to_number : toNumber
        }),
    }
    const res = await fetch(url.toString(), options)
    const error = await getError(res)
    const data = await res.json()
    return { data, error: error }
}