
import { computed, defineComponent, onMounted, ref, getCurrentInstance } from 'vue'
import api from '@/api/api'
import { useProject } from '@/hooks/useProject'
import { AvgCheckReportSetting } from '../../../types/avgCheckReport'
import SpinnerLoader from '@/components/SpinnerLoader.vue'
import SettingActionMenu from '../components/SettingActionMenu.vue'
import * as _ from 'lodash'
import useRouter from '../../../hooks/useRouter'
export default defineComponent({
    components: { SpinnerLoader, SettingActionMenu },
    props: {},
    setup(props, { emit }) {
        const root = getCurrentInstance()!.proxy
        const { router } = useRouter()
        const headers = [
            { text: 'Наименование схемы', value: 'name', sortable: false },
            { text: 'Применяется по-умолчанию', value: 'is_default', sortable: false },
            { text: '', value: 'actions', sortable: false, align: 'right' },
        ]
        const { project } = useProject()
        const loading = ref(true)
        const settingsList = ref<AvgCheckReportSetting[]>([])
        const orderedSettingsList = computed(() => _.orderBy(settingsList.value, o => new Date(o.createdAt)))
        const addSetting = () => {
            router.push(`/project/${project.value.id}/GCB2/avgCheckReport/setting?mode=create`)
        }

        const deleteSetting = async ({ item }: { item: AvgCheckReportSetting }) => {
            root.$store.commit('startLoading')
            const { data, error } = await api.avgCheckReport.removeSetting({
                projectId: project.value.id,
                id: Number(item.id),
            })
            if (error) {
                root.$store.dispatch('callNotify', 'Ошибка')
                root.$store.commit('endLoading')
                return
            }
            settingsList.value = settingsList.value?.filter(el => el.id !== item.id) as any[]
            root.$store.commit('endLoading')
        }
        const editSetting = ({ item }: { item: AvgCheckReportSetting }) => {
            router.push(`/project/${project.value.id}/GCB2/avgCheckReport/setting?mode=edit&id=${item.id}`)
        }
        const changeIsDefault = async ({ item, value }: { item: any; value: boolean }) => {
            root.$store.commit('startLoading')
            const { data, error } = await api.avgCheckReport.updateSetting({
                id: item.id,
                projectId: project.value.id,
                data: { isDefault: value },
            })
            if (error) {
                root.$store.dispatch('callNotify', 'Ошибка')
                root.$store.commit('endLoading')
                return
            }
            settingsList.value = settingsList.value
                ?.filter(el => !_.map(data, 'id').includes(el.id))
                .concat(data) as any[]

            root.$store.commit('endLoading')
        }

        onMounted(async () => {
            loading.value = true
            const { data: settings, error } = await api.avgCheckReport.getSettings(project.value.id, false)
            if (error) {
                root.$store.dispatch('callNotify', 'При загрузке данных произошла ошибка')
                loading.value = false
                return
            }
            settingsList.value = settings
            loading.value = false
        })

        return {
            addSetting,
            changeIsDefault,
            headers,
            orderedSettingsList,
            project,
            loading,
            deleteSetting,
            editSetting,
        }
    },
})
