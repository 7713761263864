import { getInstance } from '@/auth/authWrapper'
import { getError } from '@root/src/utils'
import { ApiError, ApiWrapper } from '@/types/main'
import cfg from '../../config'

export async function setSnackBarNotification({
    projectId,
    name,
    text,
    active,
    type,
}: {
    projectId: string
    name: string
    active: boolean
    text?: string
    type: string
}): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/notification/snackBar`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({
            projectId,
            active,
            type,
            name,
            text,
        }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function getNotifications(): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/notification`)
    const options = {
        method: 'GET',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
    }
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function readNotification(notificationId: number): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/notification/read`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({ notificationId }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
