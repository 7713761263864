<template>
    <div>
        <h1>Работа со списками клиентов, которых стоит позвать</h1>
        <p>Коротко о работе со списками:</p>
        <ol>
            <li>Работайте со списком <strong>Нужно связаться</strong></li>
            <li>
                Для изменения статуса нажмите на пиктограмму с тремя точками и выберите одно из доступных
                действий.
            </li>
            <li>Любое действие обратимо, поэтому не бойтесь сделать что-то не так</li>
        </ol>
        <h2>Статусы клиентов</h2>
        <ul>
            <li>
                <strong>Нужно связаться</strong> — список клиентов, с которыми нужно связаться. Основной
                список клиентов, с которым нужно работать.
            </li>
            <li>
                <strong>Связались и пока не пришли</strong> — список клиентов, с которыми связались вы или
                ваши сотрудники. В таблице указано, кто именно связался, и когда. В этом списке только те
                клиенты, которые никак не отреагировали на напоминание: не записались и не пришли.
            </li>
            <li>
                <strong>Связались и пришли</strong> — список клиентов, которые пришли или записались после
                напоминания. Если клиент записался, но до салона не дошёл, то такой клиент будет в списке
                <strong>Связались и пока не пришли</strong>.
            </li>
            <li>
                <strong>Не напоминаем</strong> — список клиентов, которым вы или ваши сотрудники не хотите
                напоминать о приходе. К примеру, это могут быть ваши сотрудники, которые пользуются вашими
                услугами.
            </li>
            <li>
                <strong>Отложенная коммуникация</strong> — список клиентов, по которым вы или ваши сотрудники
                указали, что нужно связаться с ними позже.
            </li>
        </ul>
        <h2>Изменения статусов</h2>
        <p>
            <strong>Важно!</strong> Любое изменение статусов обратимо — можно смело менять статусы. К примеру,
            если по клиенту указать, что с ним связались, то после этого можно указать, что с ним не
            связывались. И так по каждому изменению статуса.
        </p>
        <p>
            Для изменения статуса нажмите на пиктограмму с тремя точками и выберите одно из доступных
            действий.
        </p>
        <p>
            После выбора действия строка несколько секунд будет выделено другим цветом, а после этого
            переместится в соответствующий список.
        </p>
        <h2>Что если у двух клиентов одинаковые имена?</h2>
        <p>
            Если у разных клиентов одинаковые имена, то мы автоматически добавим им номера. Например, если в
            системе есть два клиента, записанных как «Галина», то в отчете они отобразятся так:
        </p>
        <ul>
            <li>Галина 1</li>
            <li>Галина 2</li>
        </ul>
        <h2>Куда ведут ссылки на именах клиентов?</h2>
        <p>Ссылки открывают карточку клиента в CRM</p>
        <p>
            <strong>Примечание!</strong> В yClients нельзя открыть карточку клиента по ссылке, поэтому ссылка
            ведет на страницу поиска клиента.
        </p>
        <p>
            <strong>Важно!</strong> Если ссылка не работает, то обязательно сообщите об этом через чат
            техподдержки. Скорее всего что-то изменилось в CRM, и мы сможем это быстро поправить.
        </p>
        <p>
            <img
                src="https://firebasestorage.googleapis.com/v0/b/dataheroes.appspot.com/o/Help%20Images%2Fimage-20210427095840485.png?alt=media&token=00e53ec6-2a07-4a47-90d9-8ee3753996aa"
                alt="чат техподдержки"
            />
        </p>
        <h2>Можно ли выбрать другой период?</h2>
        <p>Да, можно выбрать любой период из доступных дат:</p>
        <ul>
            <li><strong>Горячие клиенты</strong>: любые даты между сегодня и 14 дней назад</li>
            <li><strong>Забыли прийти</strong>: любые даты между 14 дней назад и 2 месяца назад</li>
            <li><strong>Холодные клиенты</strong>: любые даты между 2 месяца назад и ранее</li>
        </ul>
        <h2>Как выбрать даты в Отчете?</h2>
        <p>Для выбора одной даты нужно нажать на неё и нажать на кнопку <strong>Применить</strong>.</p>
        <p>
            Для выбора периода нужно выбрать дату начала и окончания и нажать на кнопку
            <strong>Применить</strong>
        </p>
        <p>&nbsp;</p>
    </div>
</template>

<script>
//hintId  <dashBoards>
export default {
    name: 'GCBOutflowCardHint',
    computed: {},
    components: {},
    data: () => ({}),
    methods: {},
    mounted() {},
}
</script>

<style lang="sass" scoped>
@import '@/vars.sass'
p
    font-size: 14px
</style>
