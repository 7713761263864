const InitFromMarketPlace = () => import('@/modules/externalMarketplace/views/InitFromMarketPlace.vue')
const Connect = () => import('@/modules/externalMarketplace/views/Connect.vue')
export default [
    {
        path: '/init/:connectorType',
        name: 'Init',
        component: InitFromMarketPlace,
        unprotected: true,
    },
    {
        path: '/init/:connectorType/connect',
        name: 'Connect',
        component: Connect,
        unprotected: false,
    },
]
