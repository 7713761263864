import { defineStore } from 'pinia'

import _ from 'lodash'
import { CommunicationTag, SideBarSection, SidebarItem, SidebarType } from '../../../types/GCB2'
import api from '../../../api/api'

export interface ActionModalData {
    opened: boolean
    communicationId: null | string
    actionType: null | string
}
interface OpenSidebarParams {
    item?: any
    type?: SidebarType
    section: SideBarSection
    communicationId?: string
}

const mapToSidebarItem = ({ item, type }: Omit<OpenSidebarParams, 'section'>): SidebarItem | undefined => {
    if (type === 'tasklist') {
        return {
            id: item.communicationId,
            clientId: item.clientId,
            clientName: item.clientNameWithNum,
            comment: item.comment,
            hrefInfo: item.hrefInfo,
            clientComment: item.clientComment,
        }
    }
    if (type === 'analytics') {
        return {
            id: item.id,
            clientId: item.client_id,
            clientName: item.client_name,
            comment: item.comment,
            hrefInfo: item.href_info,
            clientComment: '',
        }
    }
}

export const useGcb2Store = defineStore('gcb2', {
    state: () => ({
        sidebarIsActive: false as boolean,
        activeSidebarSection: null as null | SideBarSection,
        batchModeStatus: null as null | string,
        activeCommunicationId: null as null | string | number,
        activeSidebarItem: null as null | SidebarItem,
        sidebarType: null as null | SidebarType,
        visitHistoryNeedUpdateClients: [] as { projectId: string; clientId: string }[],
        sidebar: {
            active: false,
            type: null as null | SidebarType,
            item: null as null | SidebarItem,
            rawItem: null as any,
            section: null as null | SideBarSection,
            communicationId: null as string | null,
        },
        tasklistErrorModalIsActive: false as boolean,
        communicationTags: [] as CommunicationTag[],
        actionModal: {
            opened: false,
            communicationId: null,
            actionType: null,
        } as ActionModalData,
    }),
    actions: {
        refreshSidebarItem() {
            if (!this.sidebar.active) return
            this.sidebar = Object.assign({}, this.sidebar, {
                item: mapToSidebarItem({ item: this.sidebar.rawItem, type: this.sidebar.type! })!,
            })
        },
        openSidebar({ type, item, section, communicationId }: OpenSidebarParams) {
            this.sidebar = {
                active: true,
                item: mapToSidebarItem({ item, type })!,
                rawItem: item,
                type: type ?? null,
                section,
                communicationId: communicationId ?? null,
            }
        },
        closeSidebar() {
            this.sidebar = {
                active: false,
                item: null,
                rawItem: null,
                type: null,
                section: null,
                communicationId: null,
            }
            this.setBatchModeStatus(null)
        },
        setTasklistErrorModalIsActive(val: boolean) {
            this.tasklistErrorModalIsActive = val
        },

        setBatchModeStatus(val: string | null) {
            this.batchModeStatus = val
        },

        openActionModal(data: ActionModalData) {
            this.actionModal = {
                opened: data.opened,
                communicationId: data.communicationId,
                actionType: data.actionType,
            }
        },
        closeActionModal() {
            this.actionModal = {
                opened: false,
                communicationId: null,
                actionType: null,
            }
        },
        setVisitHistoryNeedUpdate(projectId: string, clientId: string, visitHistory: any[]) {
            const needUpdate = visitHistory.some(el => el.needUpdate)
            if (!needUpdate) {
                this.visitHistoryNeedUpdateClients = this.visitHistoryNeedUpdateClients.filter(
                    el => el.projectId === projectId && el.clientId !== el.clientId
                )
                return
            }
            this.visitHistoryNeedUpdateClients = this.visitHistoryNeedUpdateClients.concat([
                { projectId, clientId },
            ])
        },
        async fetchCommunicationTags(projectId: string) {
            this.communicationTags = await api.gcb2.getCommunicationTags(projectId).then(res => res.data!)
        },
    },
    getters: {
        getAllCommunicationTags: state => (projectId: string) =>
            state.communicationTags.filter(el => el.projectId === projectId),
        getCommunicationTags: state => (projectId: string) =>
            state.communicationTags.filter(el => el.projectId === projectId && !el.deletedAt),
        needUpdateVisitHistory: state => (projectId: string, clientId: string) =>
            state.visitHistoryNeedUpdateClients.some(
                el => el.projectId === projectId && el.clientId === clientId
            ),
    },
})
