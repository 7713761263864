<template>
    <div
        class="pb-3"
        style="border-bottom: 1px solid #2a3b5199; display: flex; flex-direction: column; width: 100%"
    >
        <span class="mb-1" style="color: #2a3b5199">Текущий тариф:</span>
        <div
            style="display: flex; flex-direction: row; justify-content: space-between; width: 100%"
            v-if="subscriptionPrice || currentTariff.isTrial"
        >
            <h3>{{ currentTariff.name }}</h3>
            <h3>{{ subscriptionPrice }}</h3>
        </div>
        <div
            style="display: flex; flex-direction: row; justify-content: space-between; width: 100%"
            v-if="subscriptionPrice || currentTariff.isTrial"
        >
            <span style="color: #2a3b5199">активен до {{ activeUntil }}</span>
            <span style="color: #2a3b5199">{{ paymentPeriod }}</span>
        </div>
    </div>
</template>

<script>
import Vue, { computed } from 'vue'
import { useBillingStore } from '@root/src/store/stores/billing'
import { useProject } from '@root/src/hooks/useProject'
import moment from 'moment'
export default Vue.extend({
    setup(props, { emit }) {
        const { project } = useProject()
        const billingStore = useBillingStore()
        const currentTariff = billingStore.currentTariff[project.value.id]

        const activeUntil = computed(() => {
            const date = new Date(currentTariff.activeUntil)
            return moment(date).format('DD.MM.YYYY')
        })

        console.log({ currentTariff, activeUntil })

        const paymentPeriod = computed(() => {
            const period = Number(currentTariff.subscriptionPeriod)
            switch (period) {
                case 1:
                    return 'в месяц'
                    break
                case 3:
                    return 'в 3 месяца'
                    break
                case 12:
                    return 'в год'
                    break
                default:
                    return ''
            }
        })

        const subscriptionPrice = computed(() =>
            currentTariff.subscriptionId
                ? billingStore.formatPrice(
                      currentTariff.complexPrice.monthPeriod[currentTariff.subscriptionPeriod].regularPayment
                          .sum
                  )
                : ''
        )

        return {
            currentTariff,
            subscriptionPrice,
            activeUntil,
            paymentPeriod,
        }
    },
})
</script>