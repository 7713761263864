<template>
    <span>
        <v-tooltip bottom color="#000000DE" max-width="300px" v-if="text">
            <template v-slot:activator="{ on, attrs }">
                <v-icon class="hint-icon" v-bind="attrs" v-on="on" :size="size">mdi-help-circle </v-icon>
            </template>
            <span class="white--text">{{ text }}</span>
        </v-tooltip>
    </span>
</template>

<script>
export default {
    name: 'HintIcon',
    props: {
        text: {
            type: String,
            defult: null,
        },
        size: {
            type: Number,
            defult: 24,
        },
    },
    computed: {},
    components: {},
    data: () => ({}),
    methods: {},
    mounted() {},
}
</script>

<style lang="sass" scoped>
@import '@/vars.sass'
.hint-icon
    color: $hint-icon-color
    opacity: 0.38
.hint-icon:hover
    color: black
    cursor: pointer
</style>
