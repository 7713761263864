import { apiCall, http } from './http'

export interface PyrusSaveClientParams {
    payload: any
    type: 'dikidi'
}
export async function saveClient({ payload, type }: PyrusSaveClientParams) {
    const url = `pyrus/saveClient`
    const res = apiCall<any>(() => http.post(url.toString(), { json: { payload, type } }))
    return res
}
