import { ApiWrapper } from '../types/main'
import { apiCall, http } from './http'

export async function createApp({
    crmConnectorId,
    connectorId,
    data,
}: {
    crmConnectorId: string
    connectorId: string
    data: any
}): Promise<ApiWrapper<any | null>> {
    const url = `networkFixer/app/create`
    const res = apiCall<any>(() =>
        http.post(url.toString(), {
            json: {crmConnectorId, connectorId, data },
        })
    )
    return res
}
export async function updateApp({
    connectorId,
    data,
}: {
    connectorId: string
    data: any
}): Promise<ApiWrapper<any | null>> {
    const url = `networkFixer/app/update`
    const res = apiCall<any>(() =>
        http.post(url.toString(), {
            json: { connectorId, data },
        })
    )
    return res
}
export async function getApp({ connectorId }: { connectorId: string }): Promise<ApiWrapper<any | null>> {
    const url = `networkFixer/app?connectorId=${connectorId}`
    const res = apiCall<any>(() => http.get(url.toString()))
    return res
}
export async function getAppQr({ connectorId }: { connectorId: string }): Promise<ApiWrapper<any | null>> {
    const url = `networkFixer/app/qr?connectorId=${connectorId}`
    const res = apiCall<any>(() => http.get(url.toString()))
    return res
}
export async function getAuthStatus({
    connectorId,
}: {
    connectorId: string
}): Promise<ApiWrapper<any | null>> {
    const url = `networkFixer/app/authStatus?connectorId=${connectorId}`
    const res = apiCall<any>(() => http.get(url.toString()))
    return res
}
export async function connectWaInstance({
    connectorId,
}: {
    connectorId: string
}): Promise<ApiWrapper<any | null>> {
    const url = `networkFixer/app/connectWaInstance
`
    const res = apiCall<any>(() =>
        http.post(url.toString(), {
            json: { connectorId },
        })
    )
    return res
}
export async function checkWaInstanceAlive({
    connectorId,
}: {
    connectorId: string
}): Promise<ApiWrapper<{ alive: boolean } | null>> {
    const url = `networkFixer/app/checkWaInstanceAlive?connectorId=${connectorId}`
    const res = apiCall<any>(() => http.get(url.toString(), {}))
    return res
}

export async function unAuthInstance({
    connectorId,
}: {
    connectorId: string
}): Promise<ApiWrapper<{ alive: boolean } | null>> {
    const url = `networkFixer/app/unAuthInstance?connectorId=${connectorId}`
    const res = apiCall<any>(() => http.get(url.toString(), {}))
    return res
}

export async function createInstanceTg({ connectorId }) {
    const url = `networkFixer/app/createTgWApiInstance
`
    const res = apiCall<any>(() =>
        http.post(url.toString(), {
            json: { connectorId },
        })
    )
    return res
}

export async function sendCodeTg({ connectorId, phone }) {
    const url = `networkFixer/app/sendTgWApiInstance
`
    const res = apiCall<any>(() =>
        http.post(url.toString(), {
            json: { connectorId, phone },
        })
    )
    return res
}

export async function inputCodeTg({ connectorId, code }) {
    const url = `networkFixer/app/inputTgWApiInstance
`
    const res = apiCall<any>(() =>
        http.post(url.toString(), {
            json: { connectorId, code },
        })
    )
    return res
}
