import { json } from 'express'
import { AvgCheckReportMappedSetting, AvgCheckReportSetting } from '../types/avgCheckReport'
import { ApiWrapper } from '../types/main'
import { ScheduledReport, ScheduledReportTemplate, ScheduledReportData } from '../types/scheduledReport'
import { apiCall, http } from './http'

export async function getSettings(
    projectId: string,
    full: boolean
): Promise<ApiWrapper<AvgCheckReportSetting[]>> {
    const url = `GCB2/avgCheckReport/settings/manyByProject`
    const params: any = { projectId, full }
    const res = apiCall<any>(() => http.get(url.toString(), { searchParams: params }))
    return res
}
export async function getOneMappedSetting(
    projectId: string,
    id: number
): Promise<ApiWrapper<AvgCheckReportMappedSetting>> {
    const url = `GCB2/avgCheckReport/settings/oneMapped`
    const params: any = { projectId, id }
    const res = apiCall<any>(() => http.get(url.toString(), { searchParams: params }))
    return res
}
export async function getMappedServices(
    projectId: string
): Promise<ApiWrapper<AvgCheckReportMappedSetting['services']>> {
    const url = `GCB2/avgCheckReport/settings/mappedServices`
    const params: any = { projectId }
    const res = apiCall<any>(() => http.get(url.toString(), { searchParams: params }))
    return res
}
export async function updateSetting({
    projectId,
    id,
    data,
}: {
    projectId: string
    id: number
    data: any
}): Promise<ApiWrapper<AvgCheckReportSetting[]>> {
    const url = `GCB2/avgCheckReport/settings/update`
    const res = apiCall<any>(() => http.post(url.toString(), { json: { projectId, id, data } }))
    return res
}
export async function saveMappedSetting({
    projectId,
    setting,
}: {
    projectId: string
    setting: AvgCheckReportMappedSetting | Omit<AvgCheckReportMappedSetting, 'id'>
}): Promise<ApiWrapper<{ message: string }>> {
    const url = `GCB2/avgCheckReport/mappedSetting`
    const res = apiCall<any>(() => http.post(url.toString(), { json: { projectId, setting } }))
    return res
}
export async function removeSetting({
    projectId,
    id,
}: {
    projectId: string
    id: number
}): Promise<ApiWrapper<{ id: string }>> {
    const url = `GCB2/avgCheckReport/settings/remove`
    const res = apiCall<any>(() => http.delete(url.toString(), { searchParams: { projectId, id } }))
    return res
}
export async function getReportData({
    projectId,
    filters,
}: {
    projectId: string
    filters: { dateFrom: string; dateTo: string; schemaId: string }
}): Promise<ApiWrapper<any[]>> {
    const url = `GCB2/avgCheckReport/getData`
    const res = apiCall<any>(() => http.post(url.toString(), { json: { projectId, filters } }))
    return res
}
