import { defineStore } from 'pinia'

import _ from 'lodash'

const DEFAULT_VALUES = {
    title: 'ВНИМАНИЕ!',
    type: 'text',
    text: '',
    color: 'main',
    link: [],
    linkText: [],
}
interface OpenModalParams {
    title?: string
    type?: string
    text?: string
    color?: string
    link?: string[]
    linkText?: string[]
}
export const useModalStore = defineStore('modal', {
    state: () => ({
        active: false as Boolean,
        title: DEFAULT_VALUES.title,
        type: DEFAULT_VALUES.type,
        text: DEFAULT_VALUES.text as string,
        color: DEFAULT_VALUES.color,
        link: DEFAULT_VALUES.link as string[],
        linkText: DEFAULT_VALUES.linkText as string[],
    }),
    actions: {
        clearState() {
            this.active = false
            setTimeout(() => {
                this.title = DEFAULT_VALUES.title
                this.type = DEFAULT_VALUES.type
                this.text = DEFAULT_VALUES.text
                this.color = DEFAULT_VALUES.color
                this.link = DEFAULT_VALUES.link
                this.linkText = DEFAULT_VALUES.linkText
            }, 150)
        },
        open(params: OpenModalParams) {
            this.title = params.title || DEFAULT_VALUES.title
            this.type = params.type || DEFAULT_VALUES.type
            this.text = params.text || DEFAULT_VALUES.text
            this.color = params.color || DEFAULT_VALUES.color
            this.link = params.link || DEFAULT_VALUES.link
            this.linkText = params.linkText || (this.link ?? DEFAULT_VALUES.linkText)
            this.active = true
        },
        close() {
            this.clearState()
        },
    },
})
