
import Vue, { PropType, PropOptions } from 'vue'
import { PendingConnector } from '@root/src/types/main'

import TextField from '../../../../components/inputs/TextField.vue'

import { resolvePendingConnectorYClients } from '../../../../api/pendingConnectors'
import { getAllYcCompanies, getAllAltegioCompanies, getConnector } from '../../../../api/admin'
import SpinnerLoader from '../../../../components/SpinnerLoader.vue'
import { Connector } from '@dataheroes/messages/dist/src/types'
import BaseInputOutlined from '../../../../components/inputs/BaseInputOutlined.vue'
import CustomActivatorSelect from '../../../../components/inputs/CustomActivatorSelect.vue'

export default Vue.extend({
    name: 'PendingConnectorResolverYclients',
    components: {
        TextField,
        SpinnerLoader,
        BaseInputOutlined,
        CustomActivatorSelect,
    },
    props: {
        pendingConnector: Object as () => PendingConnector,
    },
    data() {
        return {
            disableLoading: false as boolean,
            allCompanies: [] as any[],
            loading: true as boolean,
            selectedCompany: null as any,
            connector: undefined as Connector | undefined,
        }
    },
    computed: {},
    methods: {
        async resolve() {
            const res = await resolvePendingConnectorYClients({
                id: this.pendingConnector.id,
                companyIds: [this.selectedCompany],
                disableLoading: this.disableLoading,
            })

            if (res.status > 200) {
                this.$store.dispatch('callNotify', 'Ошибка')
            } else {
                this.$router.push('/admin/pendingConnectors')
            }
        },
    },
    async created() {
        this.loading = true
        this.connector = await getConnector(this.pendingConnector.connectorId)
        const req = this.connector!.isAltegio ? getAllAltegioCompanies : getAllYcCompanies
        this.allCompanies = await req()
        this.loading = false
    },
})
