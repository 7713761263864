import { computed } from 'vue'
import moment from 'moment'

export const useHoliday = () => {
    const holiday = computed(() => {
        const now = moment()
        if (
            now.isBetween(moment(now.year() + '-12-25'), moment(now.year() + '-12-31'), null, '[]') ||
            now.isBetween(moment(now.year() + '-01-01'), moment(now.year() + '-02-05'), null, '[]')
        ) {
            return 'NEW_YEAR'
        }
        if (now.isBetween(moment(now.year() + '-02-05'), moment(now.year() + '-02-18'), null, '[]')) {
            return 'VALENTINES_DAY'
        }
        if (now.isBetween(moment(now.year() + '-02-18'), moment(now.year() + '-03-01'), null, '[]')) {
            return '23_FEBRUARY'
        }
        if (now.isBetween(moment(now.year() + '-03-01'), moment(now.year() + '-03-16'), null, '[]')) {
            return '8_MARCH'
        }
        return null
    })

    return { holiday }
}
