<template>
    <div>
        <h1>Ваши проекты</h1>
        <p>
            Проект — это ваш бизнес, например, Салон красоты «Софья», по которому будет строиться аналитика.
            Чтобы создать проект необходимо нажать на кнопку
            <img
                src="https://firebasestorage.googleapis.com/v0/b/dataheroes.appspot.com/o/Help%20Images%2Fimage-20210323153941242.png?alt=media&token=b98a70e1-957f-444e-935c-f60a2a5a3f8d"
                alt="Создать проект"
                style="zoom: 50%"
            />
            и присвоить ему имя.
        </p>
        <h2>Изображение проекта</h2>
        <p>
            Чтобы изменить стандартное изображение на ваше собственное, нажмите на «карандаш» в верхнем-правом
            углу изображения
        </p>
        <p>
            <img
                src="https://firebasestorage.googleapis.com/v0/b/dataheroes.appspot.com/o/Help%20Images%2Fimage-20210323154248243.png?alt=media&token=df06b41b-8295-486e-8727-21810d89457c"
                alt="Карандаш в углу изображения"
                style="zoom: 50%"
            />
        </p>
        <p>Или нажмите на три точки напротив названия проекта и выберите пункт «Изменить логотип»</p>
        <p>
            <img
                src="https://firebasestorage.googleapis.com/v0/b/dataheroes.appspot.com/o/Help%20Images%2Fimage-20210323154405354.png?alt=media&token=4fa8b291-48a3-4f30-b4e9-365ef4c9a8cd"
                alt="Меню проекта"
                style="zoom: 50%"
            />
        </p>
    </div>
</template>

<script>
//hintId  <project>
export default {
    name: 'ProjectsHint',
    computed: {},
    components: {},
    data: () => ({}),
    methods: {},
    mounted() {},
}
</script>



<style lang="sass" scoped>
@import '@/vars.sass'
p
    font-size: 14px
</style>
