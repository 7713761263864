import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css'

import en from 'vuetify/es5/locale/en'
import ru from 'vuetify/es5/locale/ru'

import yClientsIcon from '@/assets/vuetifyIcons/yClients.vue'
import yClientsActiveIcon from '@/assets/vuetifyIcons/yClientsActive.vue'
import whatsappIcon from '@/assets/vuetifyIcons/whatsapp.vue'
import whatsappActiveIcon from '@/assets/vuetifyIcons/whatsappActive.vue'
import profSalonIcon from '@/assets/vuetifyIcons/profSalon.vue'
import altegioIcon from '@/assets/vuetifyIcons/altegio.vue'
import sonlineIcon from '@/assets/vuetifyIcons/sonline.vue'
import profSalonActiveIcon from '@/assets/vuetifyIcons/profSalonActive.vue'
import dikidiIcon from '@/assets/vuetifyIcons/dikidi.vue'
import radioButtonCheckedIcon from '@/assets/vuetifyIcons/radioButtonChecked.vue'
import radioButtonUncheckedIcon from '@/assets/vuetifyIcons/radioButtonUnchecked.vue'
import googleAuthIcon from '@/assets/vuetifyIcons/googleAuth.vue'
import amoCrmIcon from '@/assets/vuetifyIcons/amoCrm.vue'
import clientsIcon from '@/assets/vuetifyIcons/clients.vue'
import analyticsIcon from '@/assets/vuetifyIcons/analytics.vue'
import moneyIcon from '@/assets/vuetifyIcons/money.vue'
import greenGradientCheckmarkIcon from '@/assets/vuetifyIcons/greenGradientCheckmark.vue'
import yellowGradientWarningIcon from '@/assets/vuetifyIcons/yellowGradientWarning.vue'
import redGradientLightningIcon from '@/assets/vuetifyIcons/redGradientLightning.vue'
import marketplaceIcon from '@/assets/vuetifyIcons/marketplace.vue'
import { Ripple } from 'vuetify/lib/directives'
import ChatIcon from '../assets/vuetifyIcons/chat.vue'
Vue.use(Vuetify, { directives: { Ripple } })
import colors from 'vuetify/lib/util/colors'
export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                main: '#378EFF',
                mainDark: '#2A3B51',
                textColor: '#465468',
                lightGray: '#bfbfbf',
                primary: '#378EFF',
                success: '#00b74f',
                red: '#E20029',
            },
            dark: { main: '#378EFF' },
        },
    },
    iconfont: 'mdiSvg',
    icons: {
        values: {
            yClients: {
                component: yClientsIcon as any,
            },
            yClientsActive: {
                component: yClientsActiveIcon as any,
            },
            whatsapp: {
                component: whatsappIcon as any,
            },
            whatsappActive: {
                component: whatsappActiveIcon as any,
            },
            profSalon: {
                component: profSalonIcon as any,
            },
            altegio: {
                component: altegioIcon as any,
            },
            profSalonActive: {
                component: profSalonActiveIcon as any,
            },
            sonline: {
                component: sonlineIcon as any,
            },
            dikidi: {
                component: dikidiIcon as any,
            },
            amoCrm: {
                component: amoCrmIcon as any,
            },
            googleAuth: {
                component: googleAuthIcon as any,
            },
            radioButtonChecked: {
                component: radioButtonCheckedIcon as any,
            },
            radioButtonUnchecked: {
                component: radioButtonUncheckedIcon as any,
            },
            clients: {
                component: clientsIcon as any,
            },
            analytics: {
                component: analyticsIcon as any,
            },
            money: {
                component: moneyIcon as any,
            },
            greenGradientCheckmark: {
                component: greenGradientCheckmarkIcon as any,
            },
            yellowGradientWarning: {
                component: yellowGradientWarningIcon as any,
            },
            redGradientLightning: {
                component: redGradientLightningIcon as any,
            },
            marketplace: {
                component: marketplaceIcon as any,
            },
            chat: {
                component: ChatIcon as any
            }
        },
    },

    lang: {
        locales: { en, ru },
        current: 'ru',
    },
})
