const GCB2StartPage = () => import('@/views/GCB2/StartPage.vue')
const SchemaSetting = () => import('@/modules/AvgCheckReport/views/SchemaSetting.vue')
export default [
    {
        path: '/project/:id/GCB2/avgCheckReport',
        name: 'GCB2AvgCheckReport',
        props: { dashboardType: 'avgCheckReport' },
        component: GCB2StartPage,
        requiredRoles: ['gcb2:avgCheckReport:view'],
    },
    {
        path: '/project/:id/GCB2/avgCheckReport/setting',
        name: 'GCB2AvgCheckReportSchemaSetting',
        component: SchemaSetting,
        requiredRoles: ['gcb2:avgCheckReport:view'],
    },
]
