const apiUrl = 'https://apis.google.com/js/api.js'
declare global {
    interface Window {
        gapi: any
    }
}
export default {
    installClient: () => {
        return new Promise<void>(resolve => {
            const script: any = document.createElement('script')
            script.src = apiUrl
            script.onreadystatechange = script.onload = () => {
                if (!script.readyState || /loaded|complete/.test(script.readyState)) {
                    setTimeout(() => {
                        resolve()
                    }, 500)
                }
            }
            document.getElementsByTagName('head')[0].appendChild(script)
        })
    },
    initClient: (config: any) => {
        return new Promise((resolve, reject) => {
            window.gapi.load('auth2', () => {
                window.gapi.auth2
                    .init(config)
                    .then(() => {
                        resolve(window.gapi)
                    })
                    .catch((error: any) => {
                        reject(error)
                    })
            })
        })
    },
}
