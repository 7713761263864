const GCB2StartPage = () => import('@/views/GCB2/StartPage.vue')

export default [
    {
        path: '/project/:id/GCB2/doctorReport',
        name: 'GCB2DoctorReport',
        props: { dashboardType: 'doctorReport' },
        requiredRoles: ['gcb2:doctorReport:view'],
        component: GCB2StartPage,
    },
]
