import Vue from 'vue'
import { isSafari } from '../utils'
import store from '@/store/index'
import {
    changePassword,
    getUser,
    googleLogin,
    login,
    register,
    resetPassword,
    fastRegister,
} from '../api/auth'
import googleOauth2 from '@/auth/google'
import * as Sentry from '@sentry/vue'
import { useAuthStore } from '../store/stores/auth'
import { mapStores } from 'pinia'

let instance

export const getInstance = () => instance

export const useAuth0 = ({ redirectUri = window.location.origin, ...options }) => {
    if (instance) return instance

    instance = new Vue({
        data() {
            return {
                loading: true,
                isAuthenticated: false,
                user: {},
                auth0Client: null,
                popupOpen: false,
                error: null,
                googleAuth: null,
            }
        },
        computed: {
            ...mapStores(useAuthStore),
        },
        methods: {
            async login(email, password) {
                const res = await login({ email, password })
                if (!res.error) {
                    window.localStorage.setItem('authToken', res.data.token)
                }
                this.isAuthenticated = this.getIsAuthenticated()
                this.user = await this.getUser()
                return res
            },
            async fastRegister({ type, userData, userSign }) {
                const res = await fastRegister({ type, userData, userSign })
                if (res.data.token) {
                    window.localStorage.setItem('authToken', res.data.token)
                    this.isAuthenticated = this.getIsAuthenticated()
                    this.user = await this.getUser()
                }
                if (res.data.userState === 'alreadyExists') {
                    store.commit('setAuthDialogStartSection', 'login')
                    store.commit('setIsAuthDialog', true)
                }
                if (res.error || res.data.userState === 'error') {
                    store.dispatch('callNotify', 'Ошибка')
                }
                return res
            },
            async register(email, password, utmSource) {
                const res = await register({ email, password, utmSource })
                return res
            },
            async changePassword(userId, password, newPassword) {
                const res = await changePassword({ userId, password, newPassword })
                return res
            },
            async resetPassword(email) {
                const res = await resetPassword({ email })
                return res
            },
            async getUser() {
                if (this.getIsAuthenticated()) {
                    const { error, data: decodedToken } = await getUser()
                    if (error) {
                        this.logout()
                        window.location.assign('/')
                        return
                    }
                    this.user = decodedToken
                    Sentry.setUser({
                        id: this.user.sub,
                        email: this.user.email,
                        userName: this.user.name,
                    })
                    return this.user
                } else {
                    return null
                }
            },
            getTokenSilently(o) {
                return window.localStorage.getItem('authToken') || null
            },
            getIsAuthenticated() {
                return this.getTokenSilently() ? true : false
            },
            async googleLogin(utmSource) {
                await this.installGoogleAuth()
                const { code } = await this.googleAuth.grantOfflineAccess()
                const res = await googleLogin({ code, utmSource })
                if (!res.error) {
                    window.localStorage.setItem('authToken', res.data.token)
                }
                this.isAuthenticated = this.getIsAuthenticated()
                this.user = await this.getUser()
                return res
            },
            async installGoogleAuth() {
                const gapi = await googleOauth2.installClient().then(e =>
                    googleOauth2.initClient({
                        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
                    })
                )
                this.googleAuth = gapi.auth2.getAuthInstance()
            },
            logout(o) {
                window.localStorage.removeItem('authToken')
                this.isAuthenticated = false
                Sentry.setUser(null)
            },
        },
        async created() {
            this.isAuthenticated = await this.getIsAuthenticated()
            this.user = await this.getUser()
            this.loading = false
        },
        watch: {
            isAuthenticated(val) {
                this.authStore.setIsAuthenticated(val)
            },
            user(val) {
                this.authStore.setUser(val)
            },
            loading(val) {
                this.authStore.setLoading(val)
            },
        },
    })

    return instance
}

export const Auth0Plugin = {
    install(Vue, options) {
        Vue.prototype.$auth = useAuth0(options)
    },
}
