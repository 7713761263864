import { getInstance } from '@/auth/authWrapper'
import cfg from '@root/config'
import { ApiWrapper, Connector } from '../../types/main'
import { getError } from '../../utils'
import { apiCall, http } from '../http'

export async function sendMessage(
    connectorId: string,
    clientIds: Array<number>,
    text: string
): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/yClients/sendMessage`
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            connectorId,
            clientIds,
            text,
        }),
    }
    const res = await fetch(url.toString(), options)
    const error = await getError(res)
    const data = await res.json()
    return { data, error: error }
}
export async function getClientByPhone(connectorId: string, phone: string): Promise<ApiWrapper<any | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/yClients/clientByPhone`)
    const params: any = { connectorId, phone }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const options = {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url.toString(), options)
    const error = await getError(res)
    const data = await res.json()
    return { data, error: error }
}
export async function getConnectionStatus(
    connectorId: string
): Promise<ApiWrapper<{ status: 'active' | 'freezed' | 'uninstalled'; paymentEndAt: string | null } | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/yClients/mpConnectionStatus`)
    const params: any = { connectorId }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const options = {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url.toString(), options)
    const error = await getError(res)
    const data = await res.json()
    return { data, error: error }
}
export async function getStaffList(projectId: string): Promise<ApiWrapper<any>> {
    const url = `yClients/staff`
    const res = apiCall<any>(() => http.get(url.toString(), { searchParams: { projectId } }))
    return res
}
export async function getCompany(connectorId: string): Promise<ApiWrapper<any>> {
    const url = `yClients/company`
    const res = apiCall<any>(() => http.get(url.toString(), { searchParams: { connectorId } }))
    return res
}

export async function login(connector: Connector, credentials: { token: string; companyId: string }) {
    const auth = getInstance()
    const authToken = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/yClients/login`
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
            connector,
            credentials,
        }),
    }
    const res = await fetch(url, options)
    if (res.status === 200) {
        return true
    } else {
        return false
    }
}