import { defineStore } from 'pinia'

import _ from 'lodash'

export const useAuthStore = defineStore('auth', {
    state: () => ({
        isAuthenticated: false,
        user: null,
        loading: true,
    }),
    actions: {
        setIsAuthenticated(val: boolean) {
            this.isAuthenticated = val
        },
        setUser(val: any) {
            this.user = val
        },
        setLoading(val: boolean) {
            this.loading = val
        },
    },
    getters: {},
})
