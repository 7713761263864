import { ApiWrapper } from '../../types/main'
import { apiCall, http } from '../http'

interface LoginParams {
    connectorId: string
    auth_key: string
    domain: string
}

interface CallNowParams {
    connectorId: string
    domain: string
    from: string
    to: string
}

export async function login({connectorId, auth_key, domain }: LoginParams): Promise<ApiWrapper<any>> {
    const url = `ipTelephony/pbx/login`
    const res = apiCall<any>(() =>
        http.post(url.toString(), { json: { connectorId, domain, auth_key } })
    )
    return res
}

export async function callNow({ connectorId, domain, from, to }: CallNowParams): Promise<ApiWrapper<any>> {
    const url = `ipTelephony/pbx/callNow`
    const res = apiCall<any>(() => http.post(url.toString(), { json: { connectorId, domain, from, to } }))
    return res
}

export async function getUsers(connectorId: string, domain: string): Promise<ApiWrapper<any>> {
    const url = `ipTelephony/pbx/getUsers`
    const res = await apiCall<any>(() => http.post(url.toString(), { json: { connectorId, domain } }))
    return res
}

