
import { computed, defineComponent, ref } from 'vue'
import { useModalStore } from '@/store/stores/modal'
export default defineComponent({
    setup() {
        const modalStore = useModalStore()

        const isActive = computed({
            get: () => modalStore.active,
            set: v => modalStore.close(),
        })
        return { modalStore, isActive }
    },
})
