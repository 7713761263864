import { MANUAl_COMMUNICATION_DATE_FORMAT, TASKLIST_ACTION_COLORS, TASKLIST_ACTION_TYPES } from '@/vars/GCB2'
import { declOfNum } from '../../../../utils'
import { ManualCommunication } from '@t/GCB2'
import moment from 'moment'
export function getCommunicatonIdsToUpdateColor(data: any[], actionData: any, actionType: string): any[] {
    const color = TASKLIST_ACTION_COLORS[actionType]
    if (actionType === TASKLIST_ACTION_TYPES.CONTACT) {
        let changedRows = null
        if (actionData.clientId) {
            changedRows = data.filter(
                el =>
                    el.projectId === actionData.projectId &&
                    el.clientId === actionData.clientId &&
                    el.communicationId !== actionData.communicationId
            )
            changedRows = changedRows.map(el => ({ communicationId: el.communicationId, color: 'changed' }))
            changedRows = changedRows.concat({ communicationId: actionData.communicationId, color })
        } else {
            changedRows = data.filter(
                el =>
                    el.projectId === actionData.projectId &&
                    el.communicationId === actionData.communicationId
            )
            .map(el => ({ communicationId: el.communicationId, color }))
        }
        
        return changedRows
    } else if (actionType === TASKLIST_ACTION_TYPES.CONTACT_AFTER_SENDING_MESSAGE) {
        const changedRows = data
            .filter(
                el =>
                    el.projectId === actionData.projectId &&
                    el.communicationId === actionData.communicationId
            )
            .map(el => ({ communicationId: el.communicationId, color }))
        return changedRows
    } else if (actionType === TASKLIST_ACTION_TYPES.IN_PROGRESS) {
        actionData = Array.isArray(actionData) ? actionData : [actionData]
        const projectId = actionData[0].projectId
        const actionDataCommunicationIds = actionData.map((el: any) => el.communicationId)
        const actionDataClientIds = actionData.map((el: any) => el.clientId)
        let changedRows = null
        changedRows = data
            .filter(
                el =>
                    el.projectId === projectId &&
                    actionDataClientIds.includes(el.clientId) &&
                    !actionDataCommunicationIds.includes(el.communicationId)
            )
        changedRows = changedRows.map(el => ({ communicationId: el.communicationId, color: 'changed' }))
        if (actionData.length > 1) {
            const newArr = actionData.map((el) => ({ communicationId: el.communicationId, color }))
            newArr.forEach((el) => {
                changedRows = changedRows.concat({communicationId: el.communicationId, color})
            })
        } else {
            changedRows = changedRows.concat({ communicationId: actionData[0].communicationId, color })
        }        
        return changedRows
    } else if (actionType === TASKLIST_ACTION_TYPES.COMMUNICATION_ERROR) {
        actionData = Array.isArray(actionData) ? actionData : [actionData]
        const projectId = actionData[0].projectId
        const actionDataCommunicationIds = actionData.map((el: any) => el.communicationId)
        const actionDataClientIds = actionData.map((el: any) => el.clientId)
        let changedRows = null
        if (actionData.clientId) {
            changedRows = data.filter(
                el =>
                    el.projectId === projectId &&
                    actionDataClientIds.includes(el.clientId) &&
                    !actionDataCommunicationIds.includes(el.communicationId)
            )
            changedRows = changedRows.map(el => ({ communicationId: el.communicationId, color: 'deleted' }))
            changedRows = changedRows.concat({ communicationId: actionData[0].communicationId, color })
        } else {
            changedRows = data.filter(
                el =>
                    el.projectId === projectId &&
                    actionDataCommunicationIds.includes(el.communicationId)
            )
            .map(el => ({ communicationId: el.communicationId, color }))
        }
            
        return changedRows
        
    } else if (actionType === TASKLIST_ACTION_TYPES.BATCH_CHANGE_TEXT_MESSAGE) {
        return actionData.map((el: any) => ({ communicationId: el.communicationId, color }))
    } else if (actionData.communicationId) {
        return [{ communicationId: actionData.communicationId, color }]
    } else if (actionData.clientId) {
        return data
            .filter(el => el.projectId === actionData.projectId && el.clientId === actionData.clientId)
            .map(el => ({ communicationId: el.communicationId, color }))
    } else if (actionData.serviceId) {
        return data
            .filter(el => el.projectId === actionData.projectId && el.serviceId === actionData.serviceId)
            .map(el => ({ communicationId: el.communicationId, color }))
    } else {
        return []
    }
}
/**
 * Добавляет нужные для фронта поля к строчке списка задач
 */

export function mapTaskListRow(el: any) {
    const newEl: ManualCommunication = Object.assign(el, {
        color: null,
        //activationWithGroup: `${el.activationName}/${el.activationGroup}`,
        activation: {
            name: el.activationName,
            group: el.activationGroup,
            //activation,
        },
        loyality: {
            visitCountText: `${el.clientVisitCnt} ${declOfNum(el.clientVisitCnt, [
                'посещение',
                'посещения',
                'посещений',
            ])}`,
            visitCount: el.clientVisitCnt,
            moneySpent: null,
        },
        lastVisitDate: el.clientExtra?.last_visited,
        communication: {
            name: el.contactedByUserName,
            date: moment(el.contactedAt).format(MANUAl_COMMUNICATION_DATE_FORMAT),
        },
        contactedAt: moment(el.contactedAt).format(MANUAl_COMMUNICATION_DATE_FORMAT),
        postponeTo: moment(el.postponeTo).format(MANUAl_COMMUNICATION_DATE_FORMAT),
    })
    return newEl
}
