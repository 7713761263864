
import { defineComponent, ref, PropType, computed, shallowRef, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import BaseInputOutlined from './BaseInputOutlined.vue'

const replaceBadInputs = (val: string) => {
    val = val.replace(/[^\dh:]/, '')
    val = val.replace(/^[^0-2]/, '')
    val = val.replace(/^([2-9])[4-9]/, '$1')
    val = val.replace(/^\d[:h]/, '')
    val = val.replace(/^([01][0-9])[^:h]/, '$1')
    val = val.replace(/^(2[0-3])[^:h]/, '$1')
    val = val.replace(/^(\d{2}[:h])[^0-5]/, '$1')
    val = val.replace(/^(\d{2}h)./, '$1')
    val = val.replace(/^(\d{2}:[0-5])[^0-9]/, '$1')
    val = val.replace(/^(\d{2}:\d[0-9])./, '$1')
    return val
}
export default defineComponent({
    name: 'TimeInput',
    components: { BaseInputOutlined },
    props: {
        value: { type: String, required: true },
        label: { type: String, required: false },
        placeholder: { type: String, required: false },
        hideDetails: { type: Boolean, default: true },
        rules: { type: Array, default: () => [] },
    },
    setup(props, { emit }) {
        const activatorId = computed(() => 'time-input' + '-' + uuidv4().slice(0, 10))
        const menuOpened = ref<boolean>(false)

        const hoursList = computed(() =>
            Array.from({ length: 24 }).map((_, i) => (String(i).length === 1 ? '0' + i : String(i)))
        )
        const minutesList = computed(() =>
            Array.from({ length: 60 }).map((_, i) => (String(i).length === 1 ? '0' + i : String(i)))
        )
        const selectedHour = computed(() =>
            props.value.includes(':') ? props.value.split(':')[0] : props.value
        )
        const selectedMinute = computed(() =>
            props.value.includes(':') ? props.value.split(':')[1] : undefined
        )
        const onCreated = async () => {}
        const update = (val: string | undefined) => {
            emit('input', val)
        }
        const validateInput = (e: any) => {
            let val = props.value
            let lastLength
            do {
                lastLength = val.length
                val = replaceBadInputs(val)
            } while (val.length > 0 && lastLength !== val.length)
            update(val)
        }
        const selectTime = (type: 'hour' | 'minute', val: string) => {
            let newVal
            if (type === 'hour') {
                newVal = props.value.includes(':') ? `${val}:${props.value.split(':')[1]}` : val
            }
            if (type === 'minute') {
                newVal = props.value.length >= 2 ? `${props.value.split(':')[0]}:${val}` : `00:${val}`
            }
            update(newVal)
        }
        onCreated()
        return {
            replaceBadInputs,
            validateInput,
            selectTime,
            menuOpened,
            activatorId,
            update,
            hoursList,
            minutesList,
            selectedHour,
            selectedMinute,
        }
    },
})
