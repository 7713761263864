
import Vue, { PropType } from 'vue'
import TextField from '../inputs/TextField.vue'
import SpinnerLoader from '../SpinnerLoader.vue'

export default Vue.extend({
    components: { TextField, SpinnerLoader },
    props: {
        value: { type: Boolean as PropType<boolean> },
    },
    data: () => ({
        showPassword1: false as boolean,
        showPassword2: false as boolean,
        password: '' as string,
        newPassword: '' as string,
        error: null as null | string,
        loading: false,
    }),
    computed: {
        isDialog: {
            get(): boolean {
                return this.value
            },
            set(value: boolean): void {
                this.$emit('input', value)
            },
        },
    },
    watch: {
        isDialog() {
            this.init()
        },
    },
    methods: {
        init() {
            this.showPassword1 = false
            this.showPassword2 = false
            this.password = ''
            this.newPassword = ''
            this.error = null
        },
        async changePassword() {
            this.loading = true

            const { data, error } = await this.$auth.changePassword(
                this.$auth.user.sub,
                this.password,
                this.newPassword
            )
            if (error) {
                this.error = error.message
                this.loading = false
                return
            }
            this.isDialog = false
            this.loading = false
        },
    },
})
