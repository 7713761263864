import ky from 'ky'
import config from '../../config'
import { getInstance as getAuthInstance } from '../auth'
import { getError, sleep, retry } from '../utils'

interface ApiCallOptions {
    retryCount?: number
    retryInterval?: number
}
const DEFAULT_API_CALL_OPTIONS = {
    retryCount: 0,
    retryInterval: 0,
}
export const http = ky.create({
    throwHttpErrors: false,
    prefixUrl: config.apiEndpoint,
    timeout: false,
    hooks: {
        beforeRequest: [
            async request => {
                const auth = getAuthInstance()
                const token = await auth.getTokenSilently()
                request.headers.set('Authorization', `Bearer ${token}`)
            },
        ],
    },
})

export const apiCall = async <T>(
    fn: () => Promise<Response>,
    options?: ApiCallOptions
): Promise<{ data: null | T; error: null | any }> => {
    const _options = Object.assign(DEFAULT_API_CALL_OPTIONS, options)
    const res = await retry(fn, _options.retryCount, _options.retryInterval)
    if (!res.ok) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res!.json()
    return { error: null, data }
}
