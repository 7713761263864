
import { computed, defineComponent, ref } from 'vue'
import { useHoliday } from '../hooks/useHoliday'
export default defineComponent({
    props: {
        isNewYear: {
            type: Boolean,
            default: false,
        },
    },
    components: {},
    setup(props, context) {
        const { holiday } = useHoliday()

        const imgSrc = computed(() => {
            if (holiday.value === 'NEW_YEAR') return require('@/assets/img/holiday-banner/new-year.png')
            if (holiday.value === 'VALENTINES_DAY')
                return require('@/assets/img/holiday-banner/valentines-day.png')
            if (holiday.value === '23_FEBRUARY') return require('@/assets/img/holiday-banner/23-february.png')
            if (holiday.value === '8_MARCH') return require('@/assets/img/holiday-banner/8-march.png')
            return null
        })

        return { imgSrc }
    },
})
