
import { PropType, computed, defineComponent, onBeforeMount, ref, onMounted } from 'vue'

import * as _ from 'lodash'
import messages from '@dataheroes/messages'
import TextField from '../../../../inputs/TextField.vue'
import BaseInputOutlined from '../../../../inputs/BaseInputOutlined.vue'
import AdvancedMessageEditor from '../../../../ui/AdvancedMessageEditor.vue'
import TextInputGroup from '../../../../ui/TextInputGroup.vue'
import { getCurrentInstance } from 'vue'
import { Connector } from '@root/src/types/main'
import { CONNECTOR_TYPES } from '@root/src/vars/general'
export default defineComponent({
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    components: { TextField, BaseInputOutlined, AdvancedMessageEditor, TextInputGroup },
    setup(props, { emit }) {
        const root = getCurrentInstance()!.proxy
        const yClientsConnector = computed<Connector>(() =>
            root.$store.getters
                .projectById(root.$router.currentRoute.params.id)
                .connectors.find((el: Connector) => el.connectorType === CONNECTOR_TYPES.yClients)
        )
        const answersMessageVars = yClientsConnector.value
            ? [{ id: 'ссылка-на-отзыв', name: 'ссылка-на-отзыв' }]
            : []
        const update = (reaction: string, val: string, key: string) => {
            emit(
                'input',
                Object.assign({}, props.value, {
                    [key]: Object.assign({}, props.value[key], { [reaction]: val }),
                })
            )
        }

        return { update, answersMessageVars, yClientsConnector }
    },
})
