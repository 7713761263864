<template>
    <v-footer color="#fafafa" padless height="76px" class="footer">
        <div class="footer-content" :class="{ 'footer-landing': isLanding }">
            <v-row class="ma-0">
                <v-col class="pa-0">
                    <img src="@/assets/Logo_gray.svg" width="160" height="42" />
                </v-col>
                <v-col class="pa-0">
                    <a href="https://dataheroes.pro/terms">Условия использования сервиса</a><br />
                    <a href="https://dataheroes.pro/policy"
                        >Политика в отношении обработки персональных данных</a
                    ><br />
                </v-col>
                <v-col class="pa-0">
                    <a href="https://dataheroes.pro/cookie-policy">Политика cookie</a><br />

                    <a href="http://www.freepik.com">Images designed by vectorjuice / Freepik</a>
                </v-col>
            </v-row>
        </div>
    </v-footer>
</template>

<script>
export default {
    name: 'Footer',
    components: {},
    data: () => ({
        isLanding: false,
    }),
    mounted() {},
    watch: {
        $route: function () {
            if (this.$router.currentRoute.path === '/landing') {
                this.isLanding = true
            } else {
                this.isLanding = false
            }
        },
    },
}
</script>



<style lang="sass" scoped>
@import '@/vars.sass'
a
    font-size: 11px
    color: $gray!important
    text-decoration: none !important
a:hover
    color: black !important
.footer
    display: flex
    justify-content: center
    align-items: center
    &-content
        width: 1016px
    &-landing
        margin-right: 140px
</style>