import e from 'express'
import * as _ from 'lodash'
import { BOT_TRIGGER_CARDS } from '../vars/GCB2'
import api from '../api/api'
import { Connector } from '../types/main'

interface GetDefaultWhatsappAppSettingsParams {
    connectorId: string
    yClientsConnectorId?: string
    salonId: number
    channel: Object
}

const mapPhoneNumber = (phoneNumber: string) => {
    if (!phoneNumber) return phoneNumber
    return phoneNumber.startsWith('+')
        ? phoneNumber.replace(/[^0-9+]/g, '')
        : `+${phoneNumber.replace(/[^0-9+]/g, '')}`
}

export const getDefaultWhatsappAppSettings = async (params: GetDefaultWhatsappAppSettingsParams) => {
    const company = params.yClientsConnectorId
        ? await api.connectors.yClients.getCompany(params.yClientsConnectorId).then(res => res.data)
        : null
    return {
        connectorId: params.connectorId,
        appType: 'whatsapp',
        salonId: params.salonId,
        channel: params.channel,
        salonName: company?.public_title ?? '',
        salonAddress: company?.address ?? '',
        salonPhone: company?.phone.replace(/[^0-9+]/g, '') ?? '',
        testMode: false,
        defaultName: 'Уважаемый клиент',
        groupByVisit: false,
        smsBySpecialCharacter: true,
        phoneForNotification: '',
        claim: {
            claimPhone: [],
        },
        cancel: {
            cancelPhone: [],
        },
        redirect: {
            needToRedirect: true,
            redirectPhone: '',
        },
        triggers: {
            afterRecord: {
                text:
                    '*{имя-клиента}, вы записаны:*\n{информация-о-записях}\n\n{компания-наименование}\n{компания-адрес}\n☎ {компания-телефон}',
                template:
                    '\n*Дата и время:* {дата-и-время}\n*Услуга:* {названия-услуг}\n*Мастер:* {сотрудник.имя}',
                status: 'on',
            },
            afterRecordNew: {
                text:
                    '*{имя-клиента}*, мы так рады, что скоро увидимся с вами, ведь вы\nзаписались к нам на:\n{информация-о-записях}\n\n{компания-наименование}\n{компания-адрес}\n☎ {компания-телефон}',
                template:
                    '\n*Дата и время:* {дата-и-время}\n*Услуга:* {названия-услуг}\n*Мастер:* {сотрудник.имя}',
                status: 'off',
            },
            afterRecordPrev: {
                text:
                    '*{имя-клиента}, вы записаны:*\n{информация-о-записях}\n\n{компания-наименование}\n{компания-адрес}\n☎ {компания-телефон}',
                template:
                    '\n*Дата и время:* {дата-и-время}\n*Услуга:* {названия-услуг}\n*Мастер:* {сотрудник.имя}',
                status: 'off',
            },
            notificBeforeRecord1Day: {
                text:
                    '*{имя-клиента}*, подтвердите, пожалуйста, вашу запись на завтра:\n{информация-о-записях}\n\nНапишите в ответ:\n— 1 для подтверждения записи\n— 2 для отмены записи\n\n{компания-наименование}\n{компания-адрес}\n☎ {компания-телефон}',
                template:
                    '\n*Дата и время:* {дата-и-время}\n*Услуга:* {названия-услуг}\n*Мастер:* {сотрудник.имя}',
                status: 'on',
                answersList: {
                    '1': ['1', 'ок', 'подтверждаю', 'да', 'хорошо', 'буду'],
                    '2': ['2', 'отмените', 'не приду'],
                },
            },
            notificBeforeRecord2Hours: {
                text:
                    '*{имя-клиента}*, напоминаем, что уже совсем скоро у вас запись:\n{информация-о-записях}\n{компания-наименование}\n{компания-адрес}\n☎ {компания-телефон}',
                template:
                    ' \n*Дата и время:* {дата-и-время}\n*Услуга:* {названия-услуг}\n*Мастер:* {сотрудник.имя}',
                status: 'on',
            },
            afterRecordCancelled: {
                text:
                    '*{имя-клиента}, ваша запись отменена:*\n{информация-о-записях}\n\n{компания-наименование}\n{компания-адрес}\n☎ {компания-телефон}',
                template: '\nДата и время: {дата-и-время}\nУслуга: {названия-услуг}\nМастер: {сотрудник.имя}',
                status: 'on',
            },
            afterRecordConfirmed: {
                text: '*{имя-клиента}, ваша запись подтверждена!*',
                status: 'on',
            },
            afterRecordTimeChanged: {
                text:
                    '*{имя-клиента}, изменилось время вашей записи:*\n{информация-о-записях}\n\n{компания-наименование}\n{компания-адрес}\n☎ {компания-телефон}',
                template: '\nНовое время: {дата-и-время}\nУслуга: {названия-услуг}\nМастер: {сотрудник.имя}',
                status: 'on',
            },
            afterRecordDataChanged: {
                text:
                    '*{имя-клиента}, ваша запись изменена:*\n{информация-о-записях}\n\n{компания-наименование}\n{компания-адрес}\n☎ {компания-телефон}',
                template: '\nДата и время: {дата-и-время}\nУслуга: {названия-услуг}\nМастер: {сотрудник.имя}',
                status: 'on',
            },
            afterRecordClientCome: {
                text: 'Мы рады видеть вас! Оставьте все свои заботы за порогом и посвятите это время себе.',
                template: '',
                status: 'off',
            },
            afterVisit: {
                text:
                    '*{имя-клиента}, благодарим вас за визит:*\n\nОцените, пожалуйста, ваш визит.{информация-о-записях}\n\nНапишите, пожалуйста, в ответном сообщении вашу оценку от 1 (очень плохо) до 5 (очень хорошо) 🙏🏻',
                template: '\nУслуга: {названия-услуг}\nМастер: {сотрудник.имя}',
                reactions: {
                    '1': ['claim', 'message'],
                    '2': ['claim', 'message'],
                    '3': ['claim', 'message'],
                    '4': ['message'],
                    '5': ['message'],
                },
                answers: {
                    '1': 'Очень жаль, что визит вам не понравился. Мы уже сообщили об этом руководству.',
                    '2': 'Очень жаль, что визит вам не понравился. Мы уже сообщили об этом руководству.',
                    '3': 'Очень жаль, что визит вам не понравился. Мы уже сообщили об этом руководству.',
                    '4': params.yClientsConnectorId ?
                        'Очень рады, что вам понравилось! Будем вам благодарны, если вы поделитесь своим отзывом по ссылке: {ссылка-на-отзыв}' : 'Очень рады, что вам понравилось!',
                    '5': params.yClientsConnectorId ?
                        'Очень рады, что вам понравилось! Будем вам благодарны, если вы поделитесь своим отзывом по ссылке: {ссылка-на-отзыв}' : 'Очень рады, что вам понравилось!',
                },
                status: 'on',
                answersList: {
                    '1': ['1', '1-', '1+', '0', '0-', '0+', '1!', '0!', '1.'],
                    '2': ['2', '2+', '2-', '2', '2.'],
                    '3': ['3', '3+', '3-', '3', '3.'],
                    '4': ['4', '4+', '4', '4!', '4.'],
                    '5': ['5', '5+', '5-', '5!', '5.', '6', '10', '10!', '555555', '5!!'],
                },
            },
        },
    }
}

export const mapWhatsappAppSettings = (appSettings: any, mode: 'create' | 'edit') =>
    Object.assign({}, appSettings, {
        claim: Object.assign({}, appSettings.claim, {
            needClaim: Boolean(appSettings?.claim?.claimPhone?.length),
            claimPhone: appSettings?.claim?.claimPhone?.filter((el: string) => el).map(mapPhoneNumber),
        }),
        cancel: Object.assign({}, appSettings.cancel, {
            needCancel: Boolean(appSettings?.cancel?.cancelPhone?.length),
            cancelPhone: appSettings?.cancel?.cancelPhone?.filter((el: string) => el).map(mapPhoneNumber),
        }),
        redirect: Object.assign({}, appSettings.redirect, {
            needToRedirect: appSettings.redirect.needToRedirect,
            redirectPhone: mapPhoneNumber(appSettings.redirect.redirectPhone),
        }),
        salonPhone: mapPhoneNumber(appSettings.salonPhone),
        phone: undefined,
        triggers: appSettings.triggers,
    })
export const canActivateTrigger = (
    triggerId: string,
    activeTriggersIds: string[]
): { canActivate: boolean; errorText?: string } => {
    if (
        triggerId === 'afterRecord' &&
        ['afterRecordNew', 'afterRecordPrev'].some(el => activeTriggersIds.includes(el))
    ) {
        return {
            canActivate: false,
            errorText: `Сперва необходимо выключить триггеры "${BOT_TRIGGER_CARDS.afterRecordNew.title}" и "${BOT_TRIGGER_CARDS.afterRecordPrev.title}"`,
        }
    }
    if (
        ['afterRecordNew', 'afterRecordPrev'].some(el => el === triggerId) &&
        activeTriggersIds.includes('afterRecord')
    ) {
        return {
            canActivate: false,
            errorText: `Сперва необходимо выключить триггер "${BOT_TRIGGER_CARDS.afterRecord.title}"`,
        }
    }
    return { canActivate: true, errorText: '' }
}
