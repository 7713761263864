
import { Project } from '@dataheroes/messages/dist/src/types'
import { useBillingStore } from '@root/src/store/stores/billing'
import Vue from 'vue'
import ChangePasswordDialog from './ChangePasswordDialog.vue'
import TariffItem from './TariffItem.vue'

export default Vue.extend({
    props: {
        tariffContent: {},
    },
    data: () => ({
        isChangePasswordDialog: false,
    }),
    components: { ChangePasswordDialog, TariffItem },
    computed: {
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        billingStore() {
            return useBillingStore()
        },
        currentTariff(): String {
            if (!this.project) return null
            return this.billingStore.getCurrentTariffState(this.project?.id)
        },
        actions(): any[] {
            const actionsArray = [
                {
                    text: 'Сменить пароль',
                    icon: 'mdi-lock-reset',
                    onClick: this.openChangePasswordDialog,
                    hide: String(this.user.sub).startsWith('google-oauth2'),
                    component: null,
                },
            ]
            const billingEnabled = this.$store.getters.projectById(this.project?.id)?.billingEnabled
            if (billingEnabled)
                try {
                    const projectId = this.project?.id
                    const redirectBillingCallback = () => {
                        const url = `/project/${projectId}/billing/?section=tariffs&`
                        this.$router.push(url)
                    }
                    if (projectId && this.currentTariff)
                        actionsArray.push({
                            text: `Ваш тариф (${this.currentTariff})`,
                            icon: 'mdi-wallet',
                            onClick: redirectBillingCallback,
                            hide: false,
                            component: TariffItem,
                        })
                    if (projectId && !this.currentTariff) {
                        actionsArray.push({
                            text: 'Выберите тариф',
                            icon: 'mdi-wallet',
                            onClick: redirectBillingCallback,
                            hide: false,
                            component: null,
                        })
                    }
                } catch (err) {
                    console.error(err)
                }

            console.log({ actionsArray })
            return [...actionsArray]
        },
        user(): any {
            return this.$auth.user || {}
        },
    },
    methods: {
        logout() {
            this.$auth.logout()
        },
        openChangePasswordDialog() {
            this.isChangePasswordDialog = true
        },
        billingPageRedirect() {},
    },
})
