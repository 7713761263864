export const CONNECTOR_TYPES = {
    yClients: 'yClients',
    alfa: 'alfa',
    planfact: 'planfact',
    zenmoney: 'zenmoney',
    universeSoft: 'universeSoft',
    profSalon: 'profSalon',
    GCB: 'GCB',
    GCB2: 'GCB2',
    sycret: 'sycret',
    sonline: 'sonline',
    arnica: 'arnica',
    arnicaV2: 'arnicaV2',
    moiSklad: 'moiSklad',
    helix1C: 'helix1C',
    lenaLenina: 'lenaLenina',
    mango: 'mango',
    chat2desk: 'chat2desk',
    whatsapp: 'whatsapp',
    profSalonBat: 'profSalonBat',
    profSalonBatV2: 'profSalonBatV2',
    altegio: 'altegio',
    dikidi: 'dikidi',
    archimed: 'archimed',
    telephonyMegaphone: 'telephonyMegaphone',
    telephonyProstyiZvonki: 'telephonyProstyiZvonki',
    telephonyBeeline: 'telephonyBeeline',
    telephonyMango: 'telephonyMango',
    sipuni: 'sipuni',
    amoCrm: 'amoCrm',
    amoCrmFull: 'amoCrmFull',
    ruslan1C: 'ruslan1C',
    dentalPro: 'dentalPro',
    vpn1C: 'vpn1C',
    klientix: 'klientix',
    cloud1C: 'cloud1C',
    klatch: 'klatch',
    whatsappBasis: 'whatsappBasis',
    prostoSMS: 'prostoSMS',
    pbxOnline: 'pbxOnline'
}
//Типы коннекторов, которые поддерживают GCB и GCB2
export const GCB_EXTERNAL_CONNECTOR_TYPES = [
    CONNECTOR_TYPES.yClients,
    CONNECTOR_TYPES.universeSoft,
    CONNECTOR_TYPES.profSalon,
    CONNECTOR_TYPES.sycret,
    CONNECTOR_TYPES.sonline,
    CONNECTOR_TYPES.arnica,
    CONNECTOR_TYPES.arnicaV2,
    CONNECTOR_TYPES.moiSklad,
    CONNECTOR_TYPES.helix1C,
    CONNECTOR_TYPES.lenaLenina,
    CONNECTOR_TYPES.mango,
    CONNECTOR_TYPES.chat2desk,
    CONNECTOR_TYPES.altegio,
    CONNECTOR_TYPES.profSalonBat,
    CONNECTOR_TYPES.dikidi,
    CONNECTOR_TYPES.archimed,
    CONNECTOR_TYPES.ruslan1C,
    CONNECTOR_TYPES.dentalPro,
    CONNECTOR_TYPES.vpn1C,
    CONNECTOR_TYPES.amoCrmFull,
    CONNECTOR_TYPES.klientix,
    CONNECTOR_TYPES.cloud1C,
    CONNECTOR_TYPES.klatch,
]
export const TELEPHONY_CONNECTOR_TYPES = [
    CONNECTOR_TYPES.telephonyMegaphone,
    CONNECTOR_TYPES.telephonyProstyiZvonki,
    CONNECTOR_TYPES.sipuni,
    CONNECTOR_TYPES.telephonyBeeline,
    CONNECTOR_TYPES.telephonyMango,
    CONNECTOR_TYPES.pbxOnline
]
export const MESSAGING_CONNECTOR_TYPES = [
    CONNECTOR_TYPES.yClients,
    CONNECTOR_TYPES.whatsapp,
    CONNECTOR_TYPES.whatsappBasis,
]
export const DECLINATION_SHORT_MONTH_NAMES = {
    'янв.': 'январе',
    'февр.': 'феврале',
    март: 'марте',
    'апр.': 'апреле',
    май: 'мае',
    июнь: 'июне',
    июль: 'июле',
    'авг.': 'августе',
    'сент.': 'сентябре',
    'окт.': 'октябре',
    'нояб.': 'ноябре',
    'дек.': 'декабре',
}
export const TAG_COLORS = ['#A5C94B', '#FD9060', '#BA7EC6', '#5EA0E3']

export const OUT_OF_SYNC_ERROR_TEXT = `Данные устарели, перезагрузите страницу и повторите действие`
export const NETWORK_ERROR_TEXT = `Возникла проблема с подключением к серверу. Пожалуйста, проверьте свое интернет-соединение, нажмите ОК, перезагрузите страницу и повторите действие.`
