import { getInstance } from '@/auth/authWrapper'
import cfg from '../../config'
import { ApiWrapper, PendingConnector } from '@root/src/types/main'
import { getError } from '../utils'
interface ICreatePendingConnectorProps {
    userId: string
    userName: string
    userEmail: string
    projectId: string
    projectName: string
    connectorId: string
    connectorType: string
    webhookKey: string
    stage: string
}

export async function createPendingConnectorRecord({
    userId,
    userName,
    userEmail,
    projectId,
    projectName,
    connectorId,
    connectorType,
    webhookKey,
    stage,
}: ICreatePendingConnectorProps) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/pendingConnectors`
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            userId,
            userName,
            userEmail,
            projectId,
            projectName,
            connectorId,
            connectorType,
            webhookKey,
            stage,
        }),
    }

    const res = await fetch(url, options)
    return res
}
export async function getWebhook() {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/pendingConnectors/webhook`
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url, options)
    return res.json()
}
export async function getAllPendingConnectors(): Promise<Array<PendingConnector>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/pendingConnectors`
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url, options)
    return res.json()
}
export async function deletePendingConnector(id: number): Promise<ApiWrapper<{ id: number } | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/pendingConnectors/${id}`
    const options = {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function getPendingConnector(id: Number): Promise<PendingConnector> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/pendingConnectors/${id}`
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url, options)
    return res.json()
}
export async function resolvePendingConnectorProfSalonStage1({
    id,
    token,
    salonId,
    salonLogin,
    webhookKey,
}: any) {
    const auth = getInstance()
    const authToken = await auth.getTokenSilently()
    const url = `${cfg.updaterApiEndpoint}/pendingConnectors/resolveProfSalon`
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
            id,
            token,
            salonId,
            salonLogin,
            webhookKey,
        }),
    }
    const res = await fetch(url, options)
    return res
}
export async function resolvePendingConnectorProfSalonStage2({
    id,
    recordsFile,
    clientsFile,
}: {
    id: number
    recordsFile: File
    clientsFile: File
}) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.updaterApiEndpoint}/pendingConnectors/resolveProfSalon`

    let formData = new FormData()
    formData.append('id', String(id))
    formData.append('recordsFile', recordsFile, 'recordsFile')
    formData.append('clientsFile', clientsFile, 'clientsFile')
    const options = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        body: formData,
    }
    const res = await fetch(url, options)
    return res
}
export async function resolvePendingConnectorYClients({
    id,
    companyIds,
    disableLoading,
}: {
    id: number
    companyIds: any[]
    disableLoading?: boolean
}) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.updaterApiEndpoint}/pendingConnectors/resolveYClients`

    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ id, companyIds, disableLoading }),
    }
    const res = await fetch(url, options)
    return res
}
export async function resolvePendingConnectorAltegio({ id, companyIds }: { id: number; companyIds: any[] }) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.updaterApiEndpoint}/pendingConnectors/resolveAltegio`

    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ id, companyIds }),
    }
    const res = await fetch(url, options)
    return res
}
export async function resolvePendingConnectorProfSalonBat({
    id,
    token,
    salonId,
    PHPSESSID,
    profSalonLogin,
    isProfMed,
    isVersion2,
}: {
    id: number
    token: string
    salonId: string
    PHPSESSID: string
    profSalonLogin: string
    isProfMed: boolean
    isVersion2: boolean
}) {
    const auth = getInstance()
    const authToken = await auth.getTokenSilently()
    const url = `${cfg.updaterApiEndpoint}/pendingConnectors/resolveProfSalonBat`

    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ id, token, salonId, PHPSESSID, profSalonLogin, isProfMed, isVersion2 }),
    }
    const res = await fetch(url, options)
    return res
}
