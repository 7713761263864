import { defineStore } from 'pinia'

import _ from 'lodash'

const DEFAULT_VALUES = {
    title: 'ВНИМАНИЕ!',
    applyText: 'ДА',
    text: '',
}

export const useConfirmStore = defineStore('confirm', {
    state: () => ({
        onConfirm: null as Function | null,
        text: DEFAULT_VALUES.text as string,
        title: DEFAULT_VALUES.title,
        applyText: DEFAULT_VALUES.applyText,
    }),
    actions: {
        confirm({ applyText, text, onConfirm }: any) {
            this.text = text
            this.applyText = applyText || DEFAULT_VALUES.applyText
            this.onConfirm = onConfirm
        },
        close() {
            this.onConfirm = null
            this.text = DEFAULT_VALUES.text
            this.applyText = DEFAULT_VALUES.applyText
        },
    },
    getters: {
        dialogIsActive(): boolean {
            return Boolean(this.onConfirm)
        },
    },
})
