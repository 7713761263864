import BotTrigger from '@root/src/views/GCB2/BotTrigger.vue'
const GCB2StartPage = () => import('@/views/GCB2/StartPage.vue')
const Activation = () => import('@/views/GCB2/Activation.vue')
const KpiSchemeSetting = () => import('@/views/GCB2/KpiSchemeSetting.vue')

import doctorReportRoutes from '@/modules/DoctorReport/routes'
import { AvgCheckReportModule } from '@root/src/modules/AvgCheckReport/AvgCheckReport.module'
export default [
    {
        path: '/project/:id/GCB2',
        name: 'GCB2Main',
        props: { dashboardType: 'main' },
        component: GCB2StartPage,
    },
    {
        path: '/project/:id/GCB2/returnability',
        name: 'GCB2Returnability',
        props: { dashboardType: 'returnability' },
        component: GCB2StartPage,
    },
    {
        path: '/project/:id/GCB2/marketingReport',
        name: 'GCB2MarketingReport',
        props: { dashboardType: 'marketingReport' },
        component: GCB2StartPage,
    },
    {
        path: '/project/:id/GCB2/simpleReports/',
        name: 'GCB2SimpleReports',
        props: { dashboardType: 'simpleReports' },
        component: GCB2StartPage,
    },
    {
        path: '/project/:id/GCB2/kpi/',
        name: 'GCB2SimpleReports',
        props: { dashboardType: 'kpi' },
        component: GCB2StartPage,
    },
    {
        path: '/project/:id/GCB2/churn',
        name: 'GCB2ChurnReport',
        props: { dashboardType: 'churn' },
        requiredRoles: ['gcb2:churnReport:view'],
        component: GCB2StartPage,
    },
    {
        path: '/project/:id/GCB2/staff',
        name: 'GCB2StaffReport',
        props: { dashboardType: 'staff' },
        component: GCB2StartPage,
        requiredRoles: ['gcb2:staffReport:view'],
    },
    {
        path: '/project/:id/GCB2/sameDayRec',
        name: 'GCB2SameDayRecReport',
        props: { dashboardType: 'sameDayRec' },
        component: GCB2StartPage,
        requiredRoles: ['gcb2:sameDayRec:view'],
    },
    {
        path: '/project/:id/GCB2/fourhandsReport',
        name: 'GCB2KpiSchemeSetting',
        props: { dashboardType: 'fourhandsReport' },
        component: GCB2StartPage,
    },

    {
        path: '/project/:id/GCB2/personaReport',
        name: 'GCB2KpiSchemeSetting',
        props: { dashboardType: 'personaReport' },
        component: GCB2StartPage,
    },

    {
        path: '/project/:id/GCB2/kpi/setting',
        name: 'GCB2KpiSchemeSetting',
        component: KpiSchemeSetting,
    },

    {
        path: '/project/:id/GCB2/Activation',
        name: 'GCB2Activation',
        component: Activation,
    },
    {
        path: '/project/:id/GCB2/botTrigger',
        name: 'GCB2BotTrigger',
        component: BotTrigger,
    },
]
    .concat(doctorReportRoutes)
    .concat(AvgCheckReportModule.routes)
