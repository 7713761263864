<template>
    <v-navigation-drawer v-model="isHintSidebar" fixed right temporary width="628px">
        <v-btn icon class="ml-15px my-6" @click="closeSideBar"> <v-icon> mdi-close </v-icon></v-btn>
        <div class="ml-6 hint-content">
            <component :is="activeComponent" :articleId="knowledgeBaseArticleId"></component>
        </div>
    </v-navigation-drawer>
</template>

<script>
import ProjectHint from '@/components/hints/ProjectHint'
import ProjectsHint from '@/components/hints/ProjectsHint'
import ConnectorsHint from '@/components/hints/ConnectorsHint'
import DashboardsHint from '@/components/hints/DashboardsHint'
import UsersHint from '@/components/hints/UsersHint'
import YcAuthKeyHint from '@/components/hints/YcAuthKeyHint'
import GCBOutflowHint from '@/components/hints/GCBOutflowHint'
import GCBOutflowCardHint from '@/components/hints/GCBOutflowCardHint.vue'
import YcSettingsHint from '@/components/hints/YcSettingsHint'

const KnowledgeBaseHint = () => import('@/components/hints/KnowledgeBaseHint.vue')
export default {
    name: 'HintSidebar',
    data: () => ({
        hints: [
            { id: 'project', component: ProjectHint },
            { id: 'projects', component: ProjectsHint },
            { id: 'connectors', component: ConnectorsHint },
            { id: 'widgets', component: DashboardsHint },
            { id: 'users', component: UsersHint },
            { id: 'ycAuthKey', component: YcAuthKeyHint },
            { id: 'GCBOutflow', component: GCBOutflowHint },
            { id: 'GCBOutflowCard', component: GCBOutflowCardHint },
            { id: 'ycSettings', component: YcSettingsHint },
        ],
    }),
    computed: {
        activeComponent() {
            if (this.sidebarHintId && this.sidebarHintId.startsWith('knowledgeBase')) return KnowledgeBaseHint
            return this.hints.find(el => el.id === this.sidebarHintId)?.component
        },
        isHintSidebar: {
            get() {
                return this.$store.state.isHintSidebar
            },
            set(value) {
                this.$store.commit('setIsHintSidebar', value)
            },
        },
        sidebarHintId() {
            return this.$store.state.sidebarHintId
        },
        knowledgeBaseArticleId() {
            if (this.sidebarHintId && this.sidebarHintId.startsWith('knowledgeBase')) {
                const [prefix, articleId] = this.sidebarHintId.split(':')
                return articleId
            }
        },
    },
    components: {
        ProjectHint,
        ConnectorsHint,
        DashboardsHint,
        UsersHint,
        YcAuthKeyHint,
        ProjectsHint,
        GCBOutflowHint,
        GCBOutflowCardHint,
        YcSettingsHint,
    },
    methods: {
        closeSideBar() {
            this.$store.commit('setIsHintSidebar', false)
        },
    },
}
</script>

<style lang="sass" scoped>
@import '@/vars.sass'
//фиксит размытие
.v-navigation-drawer
    will-change: initial
    z-index: 104 !important
.hint-content
    margin-right: 132px
</style>
