
import { computed, defineComponent, ref } from 'vue'
import { useConfirmStore } from '@/store/stores/confirm'
export default defineComponent({
    setup() {
        const confirmStore = useConfirmStore()
        const confirmClick = () => {
            if (confirmStore.onConfirm) {
                confirmStore.onConfirm()
            }
            confirmStore.close()
        }
        const isActive = computed({
            get: () => confirmStore.dialogIsActive,
            set: v => confirmStore.close(),
        })
        return { confirmStore, confirmClick, isActive }
    },
})
