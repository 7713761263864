<template>
    <div>
        <h1>Наименование проекта</h1>
        <p>
            <strong>Наименование проекта</strong> — это наименование вашего бизнеса, например,
            <code>Салон красоты &quot;Софья&quot;</code>. С помощью значка
            <img
                src="https://firebasestorage.googleapis.com/v0/b/dataheroes.appspot.com/o/Help%20Images%2Fimage-20210311125221458.png?alt=media&token=6afa9876-e11f-4e90-93e0-628bbe297342"
                alt="Карандаш"
                style="zoom: 67%"
            />
            вы всегда можете его отредактировать.
        </p>
        <p>
            <img
                src="https://firebasestorage.googleapis.com/v0/b/dataheroes.appspot.com/o/Help%20Images%2F23-03-2021_data_heroes.png?alt=media&token=4fae2e60-9466-478f-9c2c-7e7368501a0b"
                alt="Изменение названия проекта"
                style="width: 100%"
            />
        </p>
    </div>
</template>

<script>
//hintId  <project>
export default {
    name: 'ProjectHint',
    computed: {},
    components: {},
    data: () => ({}),
    methods: {},
    mounted() {},
}
</script>



<style lang="sass" scoped>
@import '@/vars.sass'
p
    font-size: 14px
</style>
