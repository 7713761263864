
import Vue, { PropType, PropOptions } from 'vue'
import { PendingConnector } from '@root/src/types/main'
import { useAdminStore } from '@/store/stores/admin'
import TextField from '../../../../components/inputs/TextField.vue'
import {
    resolvePendingConnectorProfSalonStage1,
    resolvePendingConnectorProfSalonStage2,
} from '../../../../api/pendingConnectors'
import { mapStores } from 'pinia'

export default Vue.extend({
    name: 'PendingConnectorResolverProfSalon',
    components: { TextField },
    props: {
        pendingConnector: Object as () => PendingConnector,
    },
    data() {
        return {
            reqPending: false as boolean,
            valid1: false as boolean,
            clientsFile: null as File | null,
            recordsFile: null as File | null,
            token: null as string | null,
            salonId: null as string | null,
            salonLogin: null as string | null,
        }
    },
    computed: {
        ...mapStores(useAdminStore),
        stage(): Number {
            return this.pendingConnector.stage
        },
        valid2(): boolean {
            if (this.clientsFile && this.recordsFile) {
                return true
            } else {
                return false
            }
        },
    },
    methods: {
        async resolveStage1() {
            this.reqPending = true
            const res = await resolvePendingConnectorProfSalonStage1({
                id: this.pendingConnector.id,
                token: this.token,
                salonId: this.salonId,
                salonLogin: this.salonLogin,
                webhookKey: this.pendingConnector.webhookKey,
            })
            this.reqPending = false
            if (res.status > 200) {
                this.$store.dispatch('callNotify', 'Не верные данные')
            } else {
                this.adminStore.setPendingConnectorStage({ id: this.pendingConnector.id, stage: 2 })
            }
        },
        async resolveStage2() {
            this.reqPending = true
            const res = await resolvePendingConnectorProfSalonStage2({
                id: this.pendingConnector.id,
                clientsFile: this.clientsFile as File,
                recordsFile: this.recordsFile as File,
            })
            this.reqPending = false
            if (res.status > 200) {
                this.$store.dispatch('callNotify', 'Ошибка')
            } else {
                this.adminStore.setPendingConnectorStage({ id: this.pendingConnector.id, stage: 3 })
            }
        },
    },
})
