<template>
    <div>
        <h1>Источники</h1>
        <p>
            В разделе <strong>Источники</strong> вы можете добавлять к вашему проекту сторонние системы, в
            которых вы работаете в рамках вашего бизнеса, например, yClients.
        </p>
        <h2>Добавление источника</h2>
        <p>
            Для того чтобы добавить источник, вам необходимо нажать кнопку <strong>Добавить источник</strong>.
            После нажатия на кнопку откроется окно добавления источника.
        </p>
        <p>
            <img
                src="https://firebasestorage.googleapis.com/v0/b/dataheroes.appspot.com/o/Help%20Images%2F23-03-2021_data_heroes_4.png?alt=media&token=300cbbc3-1a0c-48dd-896c-e6162bdf83d3"
                alt="Добавление источника"
                style="width: 100%"
            />
        </p>
        <p>Источник добавляется в три шага:</p>
        <ul>
            <li>
                1 шаг — <strong>Выбор источника</strong>. Выберите необходимую систему, которую вы хотите
                подключить к вашему проекту.
            </li>
            <li>
                2 шаг — <strong>Авторизируйтесь</strong>. Авторизируйте свой источник в приложении, введя
                необходимые параметры.
            </li>
            <li>
                3 шаг — <strong>Настройки</strong>. Настройте источник по тем данным, которые вам необходимы.
            </li>
        </ul>
        <p>После выбора данных по всем полям нажмите кнопку <strong>Сохранить</strong>.</p>
        <p>
            В верхнем-правом углу возникнет индикация загрузки
            <img
                src="https://firebasestorage.googleapis.com/v0/b/dataheroes.appspot.com/o/Help%20Images%2Fimage-20210311132739133.png?alt=media&token=3f9c4d5f-2062-415a-bf29-deeb799f5a0b"
                alt="индикация загрузки"
                style="zoom: 50%"
            />
        </p>
        <p>
            Когда данные загрузятся, индикатор загрузки изменится на
            <img
                src="https://firebasestorage.googleapis.com/v0/b/dataheroes.appspot.com/o/Help%20Images%2Fimage-20210311132842553.png?alt=media&token=ac71e598-9cbe-4347-93f9-c1e46cc885fa"
                alt="успешная загрузка"
                style="zoom: 50%"
            />
        </p>
        <p>После успешной загрузки будут доступны <strong>Отчеты</strong></p>
        <h2>Удаление источника</h2>
        <p>
            Если вы хотите удалить источник, то вам необходимо нажать на три точки напротив источника и затем
            нажать на кнопку <strong>Удалить</strong>
        </p>
        <p>
            <img
                src="https://firebasestorage.googleapis.com/v0/b/dataheroes.appspot.com/o/Help%20Images%2Fimage-20210311133129376.png?alt=media&token=c48477d0-c61c-4df3-a81c-7343741d0faf"
                alt="Удалить источник"
                style="width: 100%"
            />
        </p>
        <h2>Изменение настроек источника</h2>
        <p>
            Если вы хотите изменить настройки у источника, то вам необходимо нажать на три точки напротив
            источника и затем нажать на кнопку <strong>Настройки</strong>.
        </p>
        <p>
            <img
                src="https://firebasestorage.googleapis.com/v0/b/dataheroes.appspot.com/o/Help%20Images%2Fimage-20210311133308636.png?alt=media&token=5687dab6-8d72-4088-96bf-4a45cc7cfa0d"
                alt="Изменение настроек источника"
                style="width: 100%"
            />
        </p>
        <p>Далее вы перейдете на страницу настройки вашего источника.</p>
        <p>
            <strong><u>Важно!</u></strong> Если вы не можете добавить/удалить/изменить настройки у источника,
            то значит у вас недостаточно прав для этих действий. Ознакомиться с ролями системы можно по
            <a href="https://clck.ru/TStCX">ссылке</a>.
        </p>
        <p>
            <strong><u>Важно!</u></strong> Если вы не увидели необходимого вам источника, то сообщите нам об
            этом на почту info@dataheroes.pro или напишите в форме на сайте. Мы постараемся как можно скорее
            его добавить.
        </p>
    </div>
</template>

<script>
//hintId  <connectors>
export default {
    name: 'ConnectorsHint',
    computed: {},
    components: {},
    data: () => ({}),
    methods: {},
    mounted() {},
}
</script>



<style lang="sass" scoped>
@import '@/vars.sass'
p
    font-size: 14px
</style>
