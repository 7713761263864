import config from '@root/config'
import { getMessages } from '@/api/chisai/GCB2'
import { Message, MessagePart } from '@/types/GCB2'
import * as t from '../actionTypes'
import { maxBy, uniq } from 'lodash'
const projectIdIsMatch = (projectId: string, elProjectId: string) =>
    [projectId, '_SHARED'].includes(elProjectId)
export default {
    state: {
        messageData: [] as Message[],
        isEditMessageNameDialog: false as boolean,
    },
    mutations: {
        [t.SET_IS_MESSAGE_EDIT_NAME_DIALOG](state: any, value: boolean) {
            state.isEditMessageNameDialog = value
        },
        [t.ADD_MESSAGES_DATA](state: any, { data, projectId }: { data: Message[]; projectId: string }) {
            const projectData = data.filter((el: Message) => el.projectId === projectId)
            const sharedData = data.filter((el: Message) => el.projectId === '_SHARED')
            if (!state.messageData.some((el: Message) => el.projectId === projectId)) {
                state.messageData = state.messageData.concat(projectData)
            }
            if (!state.messageData.some((el: Message) => el.projectId === '_SHARED')) {
                state.messageData = state.messageData.concat(sharedData)
            }
        },
        [t.ADD_OR_UPDATE_ONE_MESSAGE](state: any, message: Message) {
            const index = state.messageData.findIndex((el: Message) => el.id === message.id)
            if (index > -1) {
                state.messageData.splice(index, 1, message)
            } else {
                state.messageData.push(message)
            }
        },
        [t.DELETE_ONE_MESSAGE](state: any, id: number) {
            const index = state.messageData.findIndex((el: Message) => el.id === id)
            state.messageData.splice(index, 1)
        },
    },
    actions: {
        async [t.FETCH_AND_ADD_MESSAGE_DATA]({ commit }: any, projectId: string) {
            const data = await getMessages(projectId)
            commit(t.ADD_MESSAGES_DATA, { data, projectId })
        },
    },

    getters: {
        [t.GET_MESSAGE_DATA]: (state: any) => (projectId: string) => {
            return state.messageData.filter((el: Message) => projectIdIsMatch(projectId, el.projectId))
        },
        [t.GET_MESSAGE_GROUPS]: (state: any) => (projectId: string) => {
            return uniq(
                (state.messageData as Message[])
                    .filter((el: Message) => projectIdIsMatch(projectId, el.projectId))
                    .map(el => el.group)
            )
        },
        [t.GET_ONE_MESSAGE]: (state: any) => (id: number) => {
            return state.messageData.find((el: Message) => el.id === id)
        },
        [t.GET_MAX_MESSAGE_PART_NUMBER]: (state: any) => (projectId: string) => {
            return (
                (maxBy(
                    state.messageData
                        .filter((message: Message) => message.projectId === projectId)
                        .map((message: Message) => message.messageParts)
                ) as { partNumber: number })?.partNumber ?? 0
            )
        },
    },
}
