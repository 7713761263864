export default function(phone: string) {
    if (!phone) {
        return 'Поле должно быть заполнено'
    } else if (!/^\+?([1-9]\d{10,12})$/.test(phone)) {
        return 'Некорректный номер телефона'
    }
}

export const isValidPhone = (phoneNumber: string) => {
    if (phoneNumber && /^\+?([1-9]\d{10,12})$/.test(phoneNumber)) {
        return true
    }
}
