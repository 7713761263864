import Home from '@/views/admin/Home.vue'
import PendingConnectors from '@root/src/views/admin/PendingConnectors/PendingConnectors.vue'
import ResolvePendingConnector from '@root/src/views/admin/PendingConnectors/ResolvePendingConnector.vue'
import { getInstance } from '@/auth/authWrapper'
import cfg from '@root/config'

const adminRouteGuard = (to, from, next) => {
    const authInstance = getInstance()
    const userId = authInstance.user.sub
    if (cfg.adminsWhitelist.includes(userId)) {
        next()
    } else {
        next('/projects')
    }
}
export default [
    {
        path: '/admin',
        name: 'AdminHome',
        component: Home,
        beforeEnter: adminRouteGuard,
    },
    {
        path: '/admin/pendingConnectors',
        name: 'AdminPendingConnectors',
        component: PendingConnectors,
        beforeEnter: adminRouteGuard,
    },
    {
        path: '/admin/pendingConnectors/resolve/:id',
        name: 'AdminPendingConnectorsResolve',
        component: ResolvePendingConnector,
        beforeEnter: adminRouteGuard,
    },
]
