<template>
    <div class="settings-validation-item pb-2">
        <div v-if="title" class="settingsTitle mb-2">
            <img
                width="16px"
                class="icon mr-2"
                :src="
                    !warn && value
                        ? require('@/assets/icons/success-status.svg')
                        : require('@/assets/icons/danger-status.svg')
                "
            />
            {{ title }}
            <SmallHint class="ml-2" v-if="titleHintText" :text="titleHintText" :size="16"></SmallHint>
        </div>
        <div class="whatsapp-settings-form__setting">
            <BaseInputOutlined
                hideDetails
                :persistent-label="persistentLabel"
                class="validationInput"
                :label="label"
                :placeholder="placeholder"
                :value="value"
                @input="onInput"
            >
            </BaseInputOutlined>
            <div class="warn error--text" v-if="warn">
                {{ warn }}
            </div>
        </div>
    </div>
</template>

<script>
import { getCurrentInstance, ref, watch } from 'vue'
import SmallHint from '../SmallHint.vue'
import BaseInputOutlined from './BaseInputOutlined.vue'
export default {
    props: {
        title: String,
        titleHintText: String,
        isBotConnected: Boolean,
        rule: Function,
        label: String,
        value: String,
        placeholder: String,
        persistentLabel: Boolean,
    },
    components: {
        SmallHint,
        BaseInputOutlined,
    },
    setup(props, { emit }) {
        const warn = ref('')
        const isWarn = ref(false)

        const onInput = e => {
            emit('input', e)
            if (props.rule) {
                warn.value = props.rule(e)
                isWarn.value = !!warn.value
            }
        }

        watch(isWarn, v => {
            emit('isWarn', v)
        })

        return {
            warn,
            onInput,
        }
    },
}
</script>

<style scoped lang="sass">
@import '@/vars.sass'
.settingsTitle
    display: flex
    flex-direction: row
    align-items: center
    font-size: 14px

.settings-validation-item
    position: relative
    margin-bottom: 10px
    text-decoration: none

.settings-validation-item::marker
    display: none

    .validationInput
        position: relative

.warn
    position: absolute
    font-size: 12px
    bottom: -10px
</style>
