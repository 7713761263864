import { ApiWrapper } from '../types/main'
import { apiCall, http } from './http'

interface GetLimitParams {
    connectorType: string
    projectId: string
}
interface GetLimitSettingsParams {
    connectorType: string
    projectId: string
}
interface ConsumeLimitSettingsParams {
    connectorType: string
    projectId: string
    limit: number
}
interface addDailyLimitParams {
    connectorType: string
    projectId: string
    limit: number
}
interface SaveLimitSettingsParams {
    connectorType: string
    projectId: string
    settings: { timezone_offset: string; default_daily_limit_of_messages_items: string }
}
export async function getLimitSettings({
    projectId,
    connectorType,
}: GetLimitSettingsParams): Promise<ApiWrapper<any | null>> {
    const url = `massender/limits/settings`
    const res = apiCall<any>(() => http.get(url.toString(), { searchParams: { projectId, connectorType } }))
    return res
}
export async function saveLimitSettings({
    projectId,
    connectorType,
    settings,
}: SaveLimitSettingsParams): Promise<ApiWrapper<any | null>> {
    const url = `massender/limits/settings`
    const res = apiCall<any>(() =>
        http.post(url.toString(), { json: { projectId, connectorType, settings } })
    )
    return res
}
export async function getLimit({
    projectId,
    connectorType,
}: GetLimitParams): Promise<ApiWrapper<any | null>> {
    const url = `massender/limits`
    const res = apiCall<any>(() => http.get(url.toString(), { searchParams: { projectId, connectorType } }))
    return res
}
export async function consumeLimit({
    projectId,
    connectorType,
    limit,
}: ConsumeLimitSettingsParams): Promise<ApiWrapper<any | null>> {
    const url = `massender/limits`
    const res = apiCall<any>(() => http.patch(url.toString(), { json: { projectId, connectorType, limit } }))
    return res
}
export async function addDailyLimit({
    projectId,
    connectorType,
    limit,
}: addDailyLimitParams): Promise<ApiWrapper<any | null>> {
    const url = `massender/limits/add`
    const res = apiCall<any>(() => http.post(url.toString(), { json: { projectId, connectorType, limit } }))
    return res
}
