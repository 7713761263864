import { defineStore } from 'pinia'
import { getNotifications, readNotification } from '@/api/notification'
import { Notification } from '../../types/main'
import _ from 'lodash'
export const useNotificationStore = defineStore('notification', {
    state: () => ({
        notifications: [] as Notification[],
        barForcedClosed: false,
    }),
    actions: {
        async fetchNotifications() {
            this.notifications = (await getNotifications()).data as Notification[]
        },
        readNotification(id: number) {
            readNotification(id)
            this.notifications = this.notifications.map(el =>
                el.id === id ? Object.assign({}, el, { readed: true }) : el
            )
        },
        unReadreadNotification(id: number) {
            this.notifications = this.notifications.map(el =>
                el.id === id ? Object.assign({}, el, { readed: false }) : el
            )
        },
        updateNotification(notification: Notification) {
            this.notifications = [
                ...this.notifications.map(el =>
                    el.id !== notification.id ? el : { ...el, ...notification }
                ),
            ]
        },
        addNotification(notification: Notification) {
            this.notifications.push(notification)
        },
        deleteNotification(notificationId: number) {
            this.notifications = [...this.notifications.filter(el => el.id !== notificationId)]
        },
    },
    getters: {
        highSeverityNotifications(): Notification[] {
            return _.filter(this.notifications, { severity: 'high', isActive: true })
        },
        dangeourSeverityNotifications(): Notification[] {
            return _.filter(this.notifications, { severity: 'dangerous', isActive: true }) as Notification[];
        },
        notificationsList(): Notification[]{
            return Array.prototype.concat(this.highSeverityNotifications, this.dangeourSeverityNotifications)
        },
        BarIsActive(): boolean {
            return (
                Boolean(_.filter(this.notificationsList, { readed: true }).length) &&
                !this.barForcedClosed
            )
        },
    },
})
