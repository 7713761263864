<template>
    <div>
        <p>&nbsp;</p>
<h1 >Участники</h1>
<p>В разделе <strong>Участники</strong> вы можете добавлять к вашему проекту ваших сотрудников, партнеров, инвесторов, а также любых других пользователей, с которыми вы хотите поделиться вашим проектом.</p>
<p>&nbsp;</p>
<h2 >Добавление пользователя</h2>
<p>Для того чтобы добавить пользователя, вам необходимо нажать кнопку <strong>Добавить участника</strong>. После нажатия на кнопку откроется окно добавления пользователя.</p>
<p>  <img src="https://firebasestorage.googleapis.com/v0/b/dataheroes.appspot.com/o/Help%20Images%2Fimage-20210311130115429.png?alt=media&token=30ac7836-c8f8-4101-9b5f-12073727ddbb" alt="Добавление пользователя" style="width:100%;" /></p>
<p>В появившемся окне укажите почту пользователя, которого вы хотите добавить, а также выберите роль для этого пользователя. Ознакомиться с ролями системы можно <a href='https://clck.ru/TStCX'>по ссылке</a>. Далее нажмите на кнопку <strong>Добавить</strong>. </p>
<p>После того как вы добавите пользователя, к нему на почту придет системное оповещение со ссылкой на проект.  </p>
<p><img src="https://firebasestorage.googleapis.com/v0/b/dataheroes.appspot.com/o/Help%20Images%2F23-03-2021_info_data_1.png?alt=media&token=f42e028c-d766-45c9-9dd4-100ed4e7efbb" alt="img" style="width:100%;" />  </p>
<p>&nbsp;</p>
<p>Пользователю необходимо будет перейти по ссылке проекта, зарегистрироваться в приложении, подтвердить свою почту и далее перейти в появившейся у него проект в реестре проектов.  </p>
<p><img src="https://firebasestorage.googleapis.com/v0/b/dataheroes.appspot.com/o/Help%20Images%2F23-03-2021_data_heroes_2.png?alt=media&token=174b919d-6643-457e-8ac5-18ae412c2c2e" alt="Список проектов" style="width:100%;" /> </p>
<p>&nbsp;</p>
<h2 >Переименование пользователя</h2>
<p>Рекомендуем переименовывать пользователей. Эти имена в дальнейшем будут использоваться в отчетах по администраторам.</p>
<p>Для изменения имени:</p>
<ol>
<li>Нажать на три точки напротив пользователя</li>
<li>Выбрать пункт <code>Изменить имя</code></li>
<li>Указать новое имя</li>

</ol>
<p><strong>Важно!</strong> Эта функция недоступна для наблюдателей. Владельцы и редакторы могут изменять все имена без исключения.</p>
<p><img src="https://firebasestorage.googleapis.com/v0/b/dataheroes.appspot.com/o/Help%20Images%2Fimage-20210524104023745.png?alt=media&token=f497cde4-20c0-4d3b-b4c6-b2f9f82e572f" alt="image-20210524104023745" style="width:100%;" /> </p>
<p>&nbsp;</p>
<h2 >Удаление пользователя</h2>
<p>Если вы хотите удалить пользователя, то вам необходимо выбрать его в реестре пользователей и нажать на кнопку <strong>Удалить</strong>.  </p>
<p><img src="https://firebasestorage.googleapis.com/v0/b/dataheroes.appspot.com/o/Help%20Images%2Fimage-20210311130209944.png?alt=media&token=3dc0b500-95a0-4a67-84a4-a60731869da9" alt="Удаление пользователя" style="width:100%;" /></p>
<p><strong><u>Важно!</u></strong> Пользователь не может удалить сам себя из проекта.</p>
<p>&nbsp;</p>
<h2 >Изменение роли пользователя</h2>
<p>Если вы хотите изменить роль у пользователя, то вам необходимо выбрать его в реестре пользователей, нажать на кнопку <strong>Назначить права</strong> и выбрать необходимую роль. </p>
<p><img src="https://firebasestorage.googleapis.com/v0/b/dataheroes.appspot.com/o/Help%20Images%2Fimage-20210311130506468.png?alt=media&token=6d7cdb2e-2d73-491f-a7ed-614c30e09a73" alt="Назначение прав доступа" style="width:100%;" />  </p>
<p><strong><u>Важно!</u></strong> Пользователь не может сам себе менять роль.</p>
<p><strong><u>Важно!</u></strong> Если вы не можете добавить/удалить/изменить права у пользователя, то значит у вас недостаточно прав для этих действий. Ознакомиться с ролями системы можно по <a href='https://clck.ru/TStCX'>ссылке</a>. </p>

    </div>
</template>

<script>
//hintId  <users>
export default {
    name: 'UsersHint',
    computed: {},
    components: {},
    data: () => ({}),
    methods: {},
    mounted() {},
}
</script>



<style lang="sass" scoped>
@import '@/vars.sass'
p
    font-size: 14px
</style>
