<template>
    <v-dialog v-model="isRenameProjectDialog" width="500">
        <v-card tile>
            <v-card-title class="headline main white--text"> Переименовать </v-card-title>

            <v-card-text>
                <v-form v-model="valid" class="mt-6" ref="form">
                    <v-text-field
                        color="main"
                        prepend-inner-icon="mdi-folder"
                        filled
                        :rules="rules"
                        v-model="projectName"
                        label="Название проекта"
                        required
                    ></v-text-field>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-btn color="main" class="ml-3 mb-5 white--text" @click="rename" :disabled="!valid">
                    Применить
                </v-btn>
                <v-btn outlined class="ml-4 mb-5" @click="cancel"> Отмена </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'RenameProjectDialog',
    components: {},
    data: () => ({
        valid: false,
        projectName: null,
        max: 40,
    }),
    computed: {
        ...mapState('project', ['currentProjectIdToRename', 'isRenameProjectDialog']),
        isRenameProjectDialog: {
            get() {
                return this.$store.state.project.isRenameProjectDialog
            },
            set(value) {
                this.$store.commit('project/setIsRenameProjectDialog', value)
            },
        },
        project() {
            return this.$store.getters.projectById(this.currentProjectIdToRename)
        },
        rules() {
            const r1 = v => (v || '').length <= this.max || `Максимальная длина: 40 символов`
            const r2 = v => !!v || 'Введите название'
            return [r1, r2]
        },
    },
    watch: {
        isRenameProjectDialog() {
            this.projectName = this.project.name
        },
    },
    methods: {
        async rename() {
            await this.$store.dispatch('project/renameProject', {
                projectId: this.project.id,
                name: this.projectName,
            })
            this.cancel()
        },
        cancel() {
            this.$store.commit('project/setIsRenameProjectDialog', false)
        },
    },

    created() {},
    mounted() {},
}
</script>

<style lang="sass" scoped>
button
    border-color: #0000001F
</style>
