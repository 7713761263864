import config from '@root/config'
import { getActivations } from '@root/src/api/chisai/GCB2'
import { Activation } from '@/types/GCB2'
import { Connector } from '@/types/main'
import { uniq } from 'lodash'

import * as t from '../actionTypes'
import { DEFAULT_ACTIVATIONS_LIMIT } from '../../../../vars/GCB2'
import { CONNECTOR_TYPES } from '../../../../vars/general'
//_SHARED - projectId для общих аудиторий
const projectIdIsMatch = (projectId: string, elProjectId: string) =>
    [projectId, '_SHARED'].includes(elProjectId)
export default {
    state: {
        isActivationEditDialog: false,
        activationData: [],
    },
    mutations: {
        [t.ADD_ACTIVATION_DATA](state: any, { data, projectId }: { data: Activation[]; projectId: string }) {
            const projectData = data.filter((el: Activation) => el.projectId === projectId)
            const sharedData = data.filter((el: Activation) => el.projectId === '_SHARED')
            if (!state.activationData.some((el: Activation) => el.projectId === projectId)) {
                state.activationData = state.activationData.concat(projectData)
            }
            if (!state.activationData.some((el: Activation) => el.projectId === '_SHARED')) {
                state.activationData = state.activationData.concat(sharedData)
            }
        },
        [t.UPDATE_ONE_ACTIVATION](state: any, activation: Activation) {
            state.activationData = [
                ...state.activationData.map((item: Activation) =>
                    item.id !== activation.id ? item : { ...item, ...activation }
                ),
            ]
        },
        [t.ADD_ONE_ACTIVATION](state: any, activation: Activation[]) {
            state.activationData.push(activation)
        },
        [t.SET_IS_ACTIVATION_EDIT_DIALOG](state: any, value: boolean) {
            state.isActivationEditDialog = value
        },
        [t.DELETE_ONE_ACTIVATION](state: any, id: number) {
            const indexToDelete = state.activationData.findIndex((el: Activation) => el.id === id)
            if (indexToDelete > -1) {
                state.activationData.splice(indexToDelete, 1)
            }
        },
    },
    actions: {
        async [t.FETCH_AND_ADD_ACTIVATION_DATA]({ commit }: any, projectId: string) {
            const response = await getActivations(projectId)
            if (response.error) {
                console.error(response.error)
                return
            }
            const data = response.data
            console.log('data', data)

            commit(t.ADD_ACTIVATION_DATA, { data, projectId })
        },
    },

    getters: {
        [t.GET_ACTIVATION_DATA]: (state: any) => (projectId: string) => {
            return state.activationData.filter((el: Activation) => projectIdIsMatch(projectId, el.projectId))
        },
        [t.GET_ACTIVATION_GROUPS]: (state: any) => (projectId: string) => {
            return uniq(
                (state.activationData as Activation[])
                    .filter((el: Activation) => projectIdIsMatch(projectId, el.projectId))
                    .map(el => el.group)
            )
        },
        [t.GET_ONE_ACTIVATION]: (state: any) => (id: number) => {
            return state.activationData.find((el: Activation) => el.id === id)
        },
        [t.GET_RUNNING_ACTIVATIONS_COUNT]: (state: any) => (projectId: string) => {
            return state.activationData.filter((el: Activation) => el.projectId === projectId && el.isRunning)
                .length
        },
        [t.GET_ACTIVATIONS_COUNT_LIMIT]: (state: any, getters: any, rootState: any, rootGetters: any) => (
            projectId: string
        ) => {
            const project = rootGetters.projectById(projectId)
            const GCB2Connector = project.connectors.find(
                (el: Connector) => el.connectorType === CONNECTOR_TYPES.GCB2
            )
            const limit = GCB2Connector.maxActivationsCount ?? DEFAULT_ACTIVATIONS_LIMIT
            return limit
        },
    },
}
